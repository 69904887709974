import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Symbols } from "./Info";

class EmojisSymbols extends React.Component {
	componentWillMount = () => {
		this.setState({ Emojis: Symbols });
	};

	Message = (Msg) => {
		this.Animate(Msg);
	};

	updateArray = (Letter) => {
		console.log(`in smileys: ${Letter}`);
		this.props.Update(Letter);
	};

	constructor(props) {
		super();
		this.state = { Emojis: [] };
	}

	render() {
		return (
			<div className="row">
				<div className="col-12 mb-3">
					<div className="d-flex">
						<div>
							<h3>Symbols</h3>
						</div>
					</div>
				</div>

				<div id="EmojisSymbols" className="col-12 mb-5">
					{this.state.Emojis.map((Letter) => {
						return (
							<div className="wrapper" id={Letter} key={Math.random()}>
								{Letter !== "" && (
									<CopyToClipboard text={Letter}>
										<span
											id={Letter + "_Para"}
											// onClick={() => {
											// 	this.Message(".copied");
											// 	this.updateArray(Letter);
											// }}
										>
											{Letter}
										</span>
									</CopyToClipboard>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default EmojisSymbols;
