import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faTimes } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonCopyUrlShare from "./ButtonCopyUrlShare";
import "./Navigation.css";
import { TagJson } from "./Info";
import SidebarMobile from "./SidebarMobile";

const logoFavemoji = require("../img/logoFavemoji.svg");

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			close: true,
			show: true,
			Val: "",
			Input: "",
			searchResults: [],
			navMoji: [],
			customModal: "visible",
			mobileView: false,
		};
		this.emojiRef = null;
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({ navMoji: nextProps.FavEmojis });
	};


	componentDidMount = () => {
		if (this.props.Params !== undefined) {
			this.setState({ Input: this.props.Params });
		}
	};

	

	Search = (Value) => {
		// this.PascalCase("cat")
		if (Value === "") this.setState({ showTag: false });
		this.setState({ Input: Value });
		var resp = this.getSearchResults(Value);
		//alert(resp)
		if (resp.length > 0) this.searchProceed(resp, Value);
	};

	searchProceed = (searchRes, routeParam) => {
		this.props.Navigate(routeParam, searchRes);
	};

	getSearchResults = (Query) => {
		var resArray = [];
		TagJson.forEach((Emoji) => {
			if (Emoji.Tags.includes(Query)) resArray.push(Emoji.Letter);
		});

		return resArray;
	};

	// PascalCase = (letter) => {
	// 	var res = [];
	// 	if (letter.includes(" ")) {
	// 		var Letters = letter.split(" ");
	// 		var Up1 = this.changeCase(Letters[0], "Upper");
	// 		var Up2 = this.changeCase(Letters[1], "Upper");
	// 		var Small1 = this.changeCase(Letters[0], "Lower");
	// 		var Small2 = this.changeCase(Letters[1], "Lower");
	// 		res.push(Up1 + " " + Up2);
	// 		res.push(Up1 + " " + Small2);
	// 		res.push(Small1 + " " + Up2);
	// 	} else {
	// 		res.push(this.changeCase(letter, "Lower"));
	// 	}

	// 	console.log(res);

	// 	return res;
	// };

	// changeCase = (letter, Case) => {
	// 	var FirstCased;
	// 	if (Case === "Upper") FirstCased = letter.substring(0, 1).toUpperCase();
	// 	if (Case === "Lower") FirstCased = letter.substring(0, 1).toLowerCase();

	// 	var spliced = letter.substring(1);
	// 	var Join = FirstCased + spliced;
	// 	//alert(Join)
	// 	return Join;
	// };

	setCookie(name, value, days) {
		var expires = "",
			date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = " expires=" + date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + "; path=/";
	}


	render() {
		return (
			<div>
				<Navbar className="customNavbar fixed-top" expand="lg">
					<div className="container">
						<Navbar.Brand href="/">
							<span className="wave" role="img" aria-label="wave">
								👋
							</span>
							<img src={logoFavemoji} alt="FavEmoji" className="ml-2" />
						</Navbar.Brand>
						<Navbar.Toggle
							className="ml-auto"
							aria-controls="basic-navbar-nav"
						/>
						<div className="ml-3 d-block d-lg-none">
							<Button
								variant="primary"
								onClick={() => {
									this.setState({ mobileView: !this.state.mobileView });
								}}
							>
								<FontAwesomeIcon icon={faBorderAll} size="1x" />
							</Button>

							{this.state.mobileView && (
								<Modal
									show={true}
									// onHide={hideModal}
								>
									<Modal.Header className="d-flex">
										<div>
											<h3>FavEmojis</h3>
										</div>
										<div>
											<div className="subline">
												are useful{" "}
												<span role="img" aria-label="rocknroll">
													🤘
												</span>
											</div>
										</div>
										<div className="ml-auto">
											<Button
												variant="primary"
												onClick={() => {
													this.setState({ mobileView: false });
												}}
											>
												<FontAwesomeIcon icon={faTimes} size="1x" />
											</Button>
										</div>
									</Modal.Header>
									<Modal.Body>
										<SidebarMobile
											FavEmojis={this.props.FavEmojis}
											UpdateOrder={this.props.UpdateOrder}
											Move={this.props.Move}
											updateRemove={this.props.updateRemove}
										/>
									</Modal.Body>
									<Modal.Footer>
										<Button
											variant="primary"
											onClick={() => {
												this.setState({ mobileView: false });
											}}
										>
											Close
										</Button>
									</Modal.Footer>
								</Modal>
							)}
						</div>

						<Navbar.Collapse id="basic-navbar-nav">
							<Form inline className="mr-auto">
								<FormControl
									type="text"
									id="deskSearch"
									value={this.state.Input}
									placeholder="Search"
									autoComplete="off"
									size="lg"
									onChange={(e) => {
										this.Search(e.target.value);
									}}
								/>
							</Form>
							<div className="ml-auto d-none d-lg-block">
								<ButtonCopyUrlShare />
							</div>
						</Navbar.Collapse>
					</div>
				</Navbar>
			</div>
		);

	}
}

export default Navigation;
