export const Smileys = [
	"😀",
	"😃",
	"😄",
	"😁",
	"😆",
	"😅",
	"😂",
	"🤣",
	"🥲",
	"😊",
	"😇",
	"🙂",
	"🙃",
	"😉",
	"😌",
	"😍",
	"🥰",
	"😘",
	"😗",
	"😙",
	"😚",
	"😋",
	"😛",
	"😝",
	"😜",
	"🤪",
	"🤨",
	"🧐",
	"🤓",
	"😎",
	"🥸",
	"🤩",
	"🥳",
	"😏",
	"😒",
	"😞",
	"😔",
	"😟",
	"😕",
	"🙁",
	"☹️",
	"😣",
	"😖",
	"😫",
	"😩",
	"🥺",
	"😢",
	"😭",
	"😤",
	"😠",
	"😡",
	"🤬",
	"🤯",
	"😳",
	"🥵",
	"🥶",
	"😱",
	"😨",
	"😰",
	"😥",
	"😓",
	"🤗",
	"🤔",
	"🤭",
	"🤫",
	"🤥",
	"😶",
	"😐",
	"😑",
	"😬",
	"🙄",
	"😯",
	"😦",
	"😧",
	"😮",
	"😲",
	"🥱",
	"😴",
	"🤤",
	"😪",
	"😵",
	"🤐",
	"🥴",
	"🤢",
	"🤮",
	"🤧",
	"😷",
	"🤒",
	"🤕",
	"🤑",
	"🤠",
	"😈",
	"👿",
	"👹",
	"👺",
	"🤡",
	"💩",
	"👻",
	"💀",
	"☠️",
	"👽",
	"👾",
	"🤖",
	"🎃",
	"😺",
	"😸",
	"😹",
	"😻",
	"😼",
	"😽",
	"🙀",
	"😿",
	"😾",
];

export const GesturesAndBodyparts = [
	"👋",
	"🤚",
	"🖐",
	"✋",
	"🖖",
	"👌",
	"🤌",
	"🤏",
	"✌️",
	"🤞",
	"🤟",
	"🤘",
	"🤙",
	"👈",
	"👉",
	"👆",
	"🖕",
	"👇",
	"☝️",
	"👍",
	"👎",
	"✊",
	"👊",
	"🤛",
	"🤜",
	"👏",
	"🙌",
	"👐",
	"🤲",
	"🤝",
	"🙏",
	"✍️",
	"💅",
	"🤳",
	"💪",
	"🦾",
	"🦵",
	"🦿",
	"🦶",
	"👣",
	"👂",
	"🦻",
	"👃",
	"🫀",
	"🫁",
	"🧠",
	"🦷",
	"🦴",
	"👀",
	"👁",
	"👅",
	"👄",
	"💋",
	"🩸",
];

export const PeopleAndFantasy = [
	"👶",
	"👧",
	"🧒",
	"👦",
	"👩",
	"🧑",
	"👨",
	"👩‍🦱",
	"🧑‍🦱",
	"👨‍🦱",
	"👩‍🦰",
	"🧑‍🦰",
	"👨‍🦰",
	"👱‍♀️",
	"👱",
	"👱‍♂️",
	"👩‍🦳",
	"🧑‍🦳",
	"👨‍🦳",
	"👩‍🦲",
	"🧑‍🦲",
	"👨‍🦲",
	"🧔",
	"👵",
	"🧓",
	"👴",
	"👲",
	"👳‍♀️",
	"👳",
	"👳‍♂️",
	"🧕",
	"👮‍♀️",
	"👮",
	"👮‍♂️",
	"👷‍♀️",
	"👷",
	"👷‍♂️",
	"💂‍♀️",
	"💂",
	"💂‍♂️",
	"🕵️‍♀️",
	"🕵️",
	"🕵️‍♂️",
	"👩‍⚕️",
	"🧑‍⚕️",
	"👨‍⚕️",
	"👩‍🌾",
	"🧑‍🌾",
	"👨‍🌾",
	"👩‍🍳",
	"🧑‍🍳",
	"👨‍🍳",
	"👩‍🎓",
	"🧑‍🎓",
	"👨‍🎓",
	"👩‍🎤",
	"🧑‍🎤",
	"👨‍🎤",
	"👩‍🏫",
	"🧑‍🏫",
	"👨‍🏫",
	"👩‍🏭",
	"🧑‍🏭",
	"👨‍🏭",
	"👩‍💻",
	"🧑‍💻",
	"👨‍💻",
	"👩‍💼",
	"🧑‍💼",
	"👨‍💼",
	"👩‍🔧",
	"🧑‍🔧",
	"👨‍🔧",
	"👩‍🔬",
	"🧑‍🔬",
	"👨‍🔬",
	"👩‍🎨",
	"🧑‍🎨",
	"👨‍🎨",
	"👩‍🚒",
	"🧑‍🚒",
	"👨‍🚒",
	"👩‍✈️",
	"🧑‍✈️",
	"👨‍✈️",
	"👩‍🚀",
	"🧑‍🚀",
	"👨‍🚀",
	"👩‍⚖️",
	"🧑‍⚖️",
	"👨‍⚖️",
	"👰‍♀️",
	"👰",
	"👰‍♂️",
	"🤵‍♀️",
	"🤵",
	"🤵‍♂️",
	"👸",
	"🤴",
	"🥷",
	"🦸‍♀️",
	"🦸",
	"🦸‍♂️",
	"🦹‍♀️",
	"🦹",
	"🦹‍♂️",
	"🤶",
	"🧑‍🎄",
	"🎅",
	"🧙‍♀️",
	"🧙",
	"🧙‍♂️",
	"🧝‍♀️",
	"🧝",
	"🧝‍♂️",
	"🧛‍♀️",
	"🧛",
	"🧛‍♂️",
	"🧟‍♀️",
	"🧟",
	"🧟‍♂️",
	"🧞‍♀️",
	"🧞",
	"🧞‍♂️",
	"🧜‍♀️",
	"🧜",
	"🧜‍♂️",
	"🧚‍♀️",
	"🧚",
	"🧚‍♂️",
	"👼",
	"🤰",
	"🤱",
	"👩‍🍼",
	"🧑‍🍼",
	"👨‍🍼",
	"🙇‍♀️",
	"🙇",
	"🙇‍♂️",
	"💁‍♀️",
	"💁",
	"💁‍♂️",
	"🙅‍♀️",
	"🙅",
	"🙅‍♂️",
	"🙆‍♀️",
	"🙆",
	"🙆‍♂️",
	"🙋‍♀️",
	"🙋",
	"🙋‍♂️",
	"🧏‍♀️",
	"🧏",
	"🧏‍♂️",
	"🤦‍♀️",
	"🤦",
	"🤦‍♂️",
	"🤷‍♀️",
	"🤷",
	"🤷‍♂️",
	"🙎‍♀️",
	"🙎",
	"🙎‍♂️",
	"🙍‍♀️",
	"🙍",
	"🙍‍♂️",
	"💇‍♀️",
	"💇",
	"💇‍♂️",
	"💆‍♀️",
	"💆",
	"💆‍♂️",
	"🧖‍♀️",
	"🧖",
	"🧖‍♂️",
	"💅",
	"🤳",
	"💃",
	"🕺",
	"👯‍♀️",
	"👯",
	"👯‍♂️",
	"🕴",
	"👩‍🦽",
	"🧑‍🦽",
	"👨‍🦽",
	"👩‍🦼",
	"🧑‍🦼",
	"👨‍🦼",
	"🚶‍♀️",
	"🚶",
	"🚶‍♂️",
	"👩‍🦯",
	"🧑‍🦯",
	"👨‍🦯",
	"🧎‍♀️",
	"🧎",
	"🧎‍♂️",
	"🏃‍♀️",
	"🏃",
	"🏃‍♂️",
	"🧍‍♀️",
	"🧍",
	"🧍‍♂️",
	"👭",
	"🧑‍🤝‍🧑",
	"👬",
	"👫",
	"👩‍❤️‍👩",
	"💑",
	"👨‍❤️‍👨",
	"👩‍❤️‍👨",
	"👩‍❤️‍💋‍👩",
	"💏",
	"👨‍❤️‍💋‍👨",
	"👩‍❤️‍💋‍👨",
	"👪",
	"👨‍👩‍👦",
	"👨‍👩‍👧",
	"👨‍👩‍👧‍👦",
	"👨‍👩‍👦‍👦",
	"👨‍👩‍👧‍👧",
	"👨‍👨‍👦",
	"👨‍👨‍👧",
	"👨‍👨‍👧‍👦",
	"👨‍👨‍👦‍👦",
	"👨‍👨‍👧‍👧",
	"👩‍👩‍👦",
	"👩‍👩‍👧",
	"👩‍👩‍👧‍👦",
	"👩‍👩‍👦‍👦",
	"👩‍👩‍👧‍👧",
	"👨‍👦",
	"👨‍👦‍👦",
	"👨‍👧",
	"👨‍👧‍👦",
	"👨‍👧‍👧",
	"👩‍👦",
	"👩‍👦‍👦",
	"👩‍👧",
	"👩‍👧‍👦",
	"👩‍👧‍👧",
	"🗣",
	"👤",
	"👥",
	"🫂",
];

export const ClothingAndAccessories = [
	"🧳",
	"🌂",
	"☂️",
	"🧵",
	"🪡",
	"🪢",
	"🧶",
	"👓",
	"🕶",
	"🥽",
	"🥼",
	"🦺",
	"👔",
	"👕",
	"👖",
	"🧣",
	"🧤",
	"🧥",
	"🧦",
	"👗",
	"👘",
	"🥻",
	"🩴",
	"🩱",
	"🩲",
	"🩳",
	"👙",
	"👚",
	"👛",
	"👜",
	"👝",
	"🎒",
	"👞",
	"👟",
	"🥾",
	"🥿",
	"👠",
	"👡",
	"🩰",
	"👢",
	"👑",
	"👒",
	"🎩",
	"🎓",
	"🧢",
	"⛑",
	"🪖",
	"💄",
	"💍",
	"💼",
];

export const AnimalsAndNature = [
	"🐶",
	"🐱",
	"🐭",
	"🐹",
	"🐰",
	"🦊",
	"🐻",
	"🐼",
	"🐻‍❄️",
	"🐨",
	"🐯",
	"🦁",
	"🐮",
	"🐷",
	"🐽",
	"🐸",
	"🐵",
	"🙈",
	"🙉",
	"🙊",
	"🐒",
	"🐔",
	"🐧",
	"🐦",
	"🐤",
	"🐣",
	"🐥",
	"🦆",
	"🦅",
	"🦉",
	"🦇",
	"🐺",
	"🐗",
	"🐴",
	"🦄",
	"🐝",
	"🪱",
	"🐛",
	"🦋",
	"🐌",
	"🐞",
	"🐜",
	"🪰",
	"🪲",
	"🪳",
	"🦟",
	"🦗",
	"🕷",
	"🕸",
	"🦂",
	"🐢",
	"🐍",
	"🦎",
	"🦖",
	"🦕",
	"🐙",
	"🦑",
	"🦐",
	"🦞",
	"🦀",
	"🐡",
	"🐠",
	"🐟",
	"🐬",
	"🐳",
	"🐋",
	"🦈",
	"🐊",
	"🐅",
	"🐆",
	"🦓",
	"🦍",
	"🦧",
	"🦣",
	"🐘",
	"🦛",
	"🦏",
	"🐪",
	"🐫",
	"🦒",
	"🦘",
	"🦬",
	"🐃",
	"🐂",
	"🐄",
	"🐎",
	"🐖",
	"🐏",
	"🐑",
	"🦙",
	"🐐",
	"🦌",
	"🐕",
	"🐩",
	"🦮",
	"🐕‍🦺",
	"🐈",
	"🐈‍⬛",
	"🪶",
	"🐓",
	"🦃",
	"🦤",
	"🦚",
	"🦜",
	"🦢",
	"🦩",
	"🕊",
	"🐇",
	"🦝",
	"🦨",
	"🦡",
	"🦫",
	"🦦",
	"🦥",
	"🐁",
	"🐀",
	"🐿",
	"🦔",
	"🐾",
	"🐉",
	"🐲",
	"🌵",
	"🎄",
	"🌲",
	"🌳",
	"🌴",
	"🪵",
	"🌱",
	"🌿",
	"☘️",
	"🍀",
	"🎍",
	"🪴",
	"🎋",
	"🍃",
	"🍂",
	"🍁",
	"🍄",
	"🐚",
	"🪨",
	"🌾",
	"💐",
	"🌷",
	"🌹",
	"🥀",
	"🌺",
	"🌸",
	"🌼",
	"🌻",
	"🌞",
	"🌝",
	"🌛",
	"🌜",
	"🌚",
	"🌕",
	"🌖",
	"🌗",
	"🌘",
	"🌑",
	"🌒",
	"🌓",
	"🌔",
	"🌙",
	"🌎",
	"🌍",
	"🌏",
	"🪐",
	"💫",
	"⭐️",
	"🌟",
	"✨",
	"⚡️",
	"☄️",
	"💥",
	"🔥",
	"🌪",
	"🌈",
	"☀️",
	"🌤",
	"⛅️",
	"🌥",
	"☁️",
	"🌦",
	"🌧",
	"⛈",
	"🌩",
	"🌨",
	"❄️",
	"☃️",
	"⛄️",
	"🌬",
	"💨",
	"💧",
	"💦",
	"☔️",
	"☂️",
	"🌊",
	"🌫",
];
export const FoodAndDrink = [
	"🍏",
	"🍎",
	"🍐",
	"🍊",
	"🍋",
	"🍌",
	"🍉",
	"🍇",
	"🍓",
	"🫐",
	"🍈",
	"🍒",
	"🍑",
	"🥭",
	"🍍",
	"🥥",
	"🥝",
	"🍅",
	"🍆",
	"🥑",
	"🥦",
	"🥬",
	"🥒",
	"🌶",
	"🫑",
	"🌽",
	"🥕",
	"🫒",
	"🧄",
	"🧅",
	"🥔",
	"🍠",
	"🥐",
	"🥯",
	"🍞",
	"🥖",
	"🥨",
	"🧀",
	"🥚",
	"🍳",
	"🧈",
	"🥞",
	"🧇",
	"🥓",
	"🥩",
	"🍗",
	"🍖",
	"🦴",
	"🌭",
	"🍔",
	"🍟",
	"🍕",
	"🫓",
	"🥪",
	"🥙",
	"🧆",
	"🌮",
	"🌯",
	"🫔",
	"🥗",
	"🥘",
	"🫕",
	"🥫",
	"🍝",
	"🍜",
	"🍲",
	"🍛",
	"🍣",
	"🍱",
	"🥟",
	"🦪",
	"🍤",
	"🍙",
	"🍚",
	"🍘",
	"🍥",
	"🥠",
	"🥮",
	"🍢",
	"🍡",
	"🍧",
	"🍨",
	"🍦",
	"🥧",
	"🧁",
	"🍰",
	"🎂",
	"🍮",
	"🍭",
	"🍬",
	"🍫",
	"🍿",
	"🍩",
	"🍪",
	"🌰",
	"🥜",
	"🍯",
	"🥛",
	"🍼",
	"🫖",
	"☕️",
	"🍵",
	"🧃",
	"🥤",
	"🧋",
	"🍶",
	"🍺",
	"🍻",
	"🥂",
	"🍷",
	"🥃",
	"🍸",
	"🍹",
	"🧉",
	"🍾",
	"🧊",
	"🥄",
	"🍴",
	"🍽",
	"🥣",
	"🥡",
	"🥢",
	"🧂",
];
export const ActivityAndSports = [
	"⚽️",
	"🏀",
	"🏈",
	"⚾️",
	"🥎",
	"🎾",
	"🏐",
	"🏉",
	"🥏",
	"🎱",
	"🪀",
	"🏓",
	"🏸",
	"🏒",
	"🏑",
	"🥍",
	"🏏",
	"🪃",
	"🥅",
	"⛳️",
	"🪁",
	"🏹",
	"🎣",
	"🤿",
	"🥊",
	"🥋",
	"🎽",
	"🛹",
	"🛼",
	"🛷",
	"⛸",
	"🥌",
	"🎿",
	"⛷",
	"🏂",
	"🪂",
	"🏋️‍♀️",
	"🏋️",
	"🏋️‍♂️",
	"🤼‍♀️",
	"🤼",
	"🤼‍♂️",
	"🤸‍♀️",
	"🤸",
	"🤸‍♂️",
	"⛹️‍♀️",
	"⛹️",
	"⛹️‍♂️",
	"🤺",
	"🤾‍♀️",
	"🤾",
	"🤾‍♂️",
	"🏌️‍♀️",
	"🏌️",
	"🏌️‍♂️",
	"🏇",
	"🧘‍♀️",
	"🧘",
	"🧘‍♂️",
	"🏄‍♀️",
	"🏄",
	"🏄‍♂️",
	"🏊‍♀️",
	"🏊",
	"🏊‍♂️",
	"🤽‍♀️",
	"🤽",
	"🤽‍♂️",
	"🚣‍♀️",
	"🚣",
	"🚣‍♂️",
	"🧗‍♀️",
	"🧗",
	"🧗‍♂️",
	"🚵‍♀️",
	"🚵",
	"🚵‍♂️",
	"🚴‍♀️",
	"🚴",
	"🚴‍♂️",
	"🏆",
	"🥇",
	"🥈",
	"🥉",
	"🏅",
	"🎖",
	"🏵",
	"🎗",
	"🎫",
	"🎟",
	"🎪",
	"🤹",
	"🤹‍♂️",
	"🤹‍♀️",
	"🎭",
	"🩰",
	"🎨",
	"🎬",
	"🎤",
	"🎧",
	"🎼",
	"🎹",
	"🥁",
	"🪘",
	"🎷",
	"🎺",
	"🪗",
	"🎸",
	"🪕",
	"🎻",
	"🎲",
	"♟",
	"🎯",
	"🎳",
	"🎮",
	"🎰",
	"🧩",
];
export const TravelAndPlaces = [
	"🚗",
	"🚕",
	"🚙",
	"🚌",
	"🚎",
	"🏎",
	"🚓",
	"🚑",
	"🚒",
	"🚐",
	"🛻",
	"🚚",
	"🚛",
	"🚜",
	"🦯",
	"🦽",
	"🦼",
	"🛴",
	"🚲",
	"🛵",
	"🏍",
	"🛺",
	"🚨",
	"🚔",
	"🚍",
	"🚘",
	"🚖",
	"🚡",
	"🚠",
	"🚟",
	"🚃",
	"🚋",
	"🚞",
	"🚝",
	"🚄",
	"🚅",
	"🚈",
	"🚂",
	"🚆",
	"🚇",
	"🚊",
	"🚉",
	"✈️",
	"🛫",
	"🛬",
	"🛩",
	"💺",
	"🛰",
	"🚀",
	"🛸",
	"🚁",
	"🛶",
	"⛵️",
	"🚤",
	"🛥",
	"🛳",
	"⛴",
	"🚢",
	"⚓️",
	"🪝",
	"⛽️",
	"🚧",
	"🚦",
	"🚥",
	"🚏",
	"🗺",
	"🗿",
	"🗽",
	"🗼",
	"🏰",
	"🏯",
	"🏟",
	"🎡",
	"🎢",
	"🎠",
	"⛲️",
	"⛱",
	"🏖",
	"🏝",
	"🏜",
	"🌋",
	"⛰",
	"🏔",
	"🗻",
	"🏕",
	"⛺️",
	"🛖",
	"🏠",
	"🏡",
	"🏘",
	"🏚",
	"🏗",
	"🏭",
	"🏢",
	"🏬",
	"🏣",
	"🏤",
	"🏥",
	"🏦",
	"🏨",
	"🏪",
	"🏫",
	"🏩",
	"💒",
	"🏛",
	"⛪️",
	"🕌",
	"🕍",
	"🛕",
	"🕋",
	"⛩",
	"🛤",
	"🛣",
	"🗾",
	"🎑",
	"🏞",
	"🌅",
	"🌄",
	"🌠",
	"🎇",
	"🎆",
	"🌇",
	"🌆",
	"🏙",
	"🌃",
	"🌌",
	"🌉",
	"🌁",
];
export const Objects = [
	"⌚️",
	"📱",
	"📲",
	"💻",
	"⌨️",
	"🖥",
	"🖨",
	"🖱",
	"🖲",
	"🕹",
	"🗜",
	"💽",
	"💾",
	"💿",
	"📀",
	"📼",
	"📷",
	"📸",
	"📹",
	"🎥",
	"📽",
	"🎞",
	"📞",
	"☎️",
	"📟",
	"📠",
	"📺",
	"📻",
	"🎙",
	"🎚",
	"🎛",
	"🧭",
	"⏱",
	"⏲",
	"⏰",
	"🕰",
	"⌛️",
	"⏳",
	"📡",
	"🔋",
	"🔌",
	"💡",
	"🔦",
	"🕯",
	"🪔",
	"🧯",
	"🛢",
	"💸",
	"💵",
	"💴",
	"💶",
	"💷",
	"🪙",
	"💰",
	"💳",
	"💎",
	"⚖️",
	"🪜",
	"🧰",
	"🪛",
	"🔧",
	"🔨",
	"⚒",
	"🛠",
	"⛏",
	"🪚",
	"🔩",
	"⚙️",
	"🪤",
	"🧱",
	"⛓",
	"🧲",
	"🔫",
	"💣",
	"🧨",
	"🪓",
	"🔪",
	"🗡",
	"⚔️",
	"🛡",
	"🚬",
	"⚰️",
	"🪦",
	"⚱️",
	"🏺",
	"🔮",
	"📿",
	"🧿",
	"💈",
	"⚗️",
	"🔭",
	"🔬",
	"🕳",
	"🩹",
	"🩺",
	"💊",
	"💉",
	"🩸",
	"🧬",
	"🦠",
	"🧫",
	"🧪",
	"🌡",
	"🧹",
	"🪠",
	"🧺",
	"🧻",
	"🚽",
	"🚰",
	"🚿",
	"🛁",
	"🛀",
	"🧼",
	"🪥",
	"🪒",
	"🧽",
	"🪣",
	"🧴",
	"🛎",
	"🔑",
	"🗝",
	"🚪",
	"🪑",
	"🛋",
	"🛏",
	"🛌",
	"🧸",
	"🪆",
	"🖼",
	"🪞",
	"🪟",
	"🛍",
	"🛒",
	"🎁",
	"🎈",
	"🎏",
	"🎀",
	"🪄",
	"🪅",
	"🎊",
	"🎉",
	"🎎",
	"🏮",
	"🎐",
	"🧧",
	"✉️",
	"📩",
	"📨",
	"📧",
	"💌",
	"📥",
	"📤",
	"📦",
	"🏷",
	"🪧",
	"📪",
	"📫",
	"📬",
	"📭",
	"📮",
	"📯",
	"📜",
	"📃",
	"📄",
	"📑",
	"🧾",
	"📊",
	"📈",
	"📉",
	"🗒",
	"🗓",
	"📆",
	"📅",
	"🗑",
	"📇",
	"🗃",
	"🗳",
	"🗄",
	"📋",
	"📁",
	"📂",
	"🗂",
	"🗞",
	"📰",
	"📓",
	"📔",
	"📒",
	"📕",
	"📗",
	"📘",
	"📙",
	"📚",
	"📖",
	"🔖",
	"🧷",
	"🔗",
	"📎",
	"🖇",
	"📐",
	"📏",
	"🧮",
	"📌",
	"📍",
	"✂️",
	"🖊",
	"🖋",
	"✒️",
	"🖌",
	"🖍",
	"📝",
	"✏️",
	"🔍",
	"🔎",
	"🔏",
	"🔐",
	"🔒",
	"🔓",
];
export const Symbols = [
	"❤️",
	"🧡",
	"💛",
	"💚",
	"💙",
	"💜",
	"🖤",
	"🤍",
	"🤎",
	"💔",
	"❣️",
	"💕",
	"💞",
	"💓",
	"💗",
	"💖",
	"💘",
	"💝",
	"💟",
	"☮️",
	"✝️",
	"☪️",
	"🕉",
	"☸️",
	"✡️",
	"🔯",
	"🕎",
	"☯️",
	"☦️",
	"🛐",
	"⛎",
	"♈️",
	"♉️",
	"♊️",
	"♋️",
	"♌️",
	"♍️",
	"♎️",
	"♏️",
	"♐️",
	"♑️",
	"♒️",
	"♓️",
	"🆔",
	"⚛️",
	"🉑",
	"☢️",
	"☣️",
	"📴",
	"📳",
	"🈶",
	"🈚️",
	"🈸",
	"🈺",
	"🈷️",
	"✴️",
	"🆚",
	"💮",
	"🉐",
	"㊙️",
	"㊗️",
	"🈴",
	"🈵",
	"🈹",
	"🈲",
	"🅰️",
	"🅱️",
	"🆎",
	"🆑",
	"🅾️",
	"🆘",
	"❌",
	"⭕️",
	"🛑",
	"⛔️",
	"📛",
	"🚫",
	"💯",
	"💢",
	"♨️",
	"🚷",
	"🚯",
	"🚳",
	"🚱",
	"🔞",
	"📵",
	"🚭",
	"❗️",
	"❕",
	"❓",
	"❔",
	"‼️",
	"⁉️",
	"🔅",
	"🔆",
	"〽️",
	"⚠️",
	"🚸",
	"🔱",
	"⚜️",
	"🔰",
	"♻️",
	"✅",
	"🈯️",
	"💹",
	"❇️",
	"✳️",
	"❎",
	"🌐",
	"💠",
	"Ⓜ️",
	"🌀",
	"💤",
	"🏧",
	"🚾",
	"♿️",
	"🅿️",
	"🛗",
	"🈳",
	"🈂️",
	"🛂",
	"🛃",
	"🛄",
	"🛅",
	"🚹",
	"🚺",
	"🚼",
	"⚧",
	"🚻",
	"🚮",
	"🎦",
	"📶",
	"🈁",
	"🔣",
	"ℹ️",
	"🔤",
	"🔡",
	"🔠",
	"🆖",
	"🆗",
	"🆙",
	"🆒",
	"🆕",
	"🆓",
	"0️⃣",
	"1️⃣",
	"2️⃣",
	"3️⃣",
	"4️⃣",
	"5️⃣",
	"6️⃣",
	"7️⃣",
	"8️⃣",
	"9️⃣",
	"🔟",
	"🔢",
	//"#️⃣",
	//"*️⃣",
	"⏏️",
	"▶️",
	"⏸",
	"⏯",
	"⏹",
	"⏺",
	"⏭",
	"⏮",
	"⏩",
	"⏪",
	"⏫",
	"⏬",
	"◀️",
	"🔼",
	"🔽",
	"➡️",
	"⬅️",
	"⬆️",
	"⬇️",
	"↗️",
	"↘️",
	"↙️",
	"↖️",
	"↕️",
	"↔️",
	"↪️",
	"↩️",
	"⤴️",
	"⤵️",
	"🔀",
	"🔁",
	"🔂",
	"🔄",
	"🔃",
	"🎵",
	"🎶",
	"➕",
	"➖",
	"➗",
	"✖️",
	"♾",
	"💲",
	"💱",
	"™️",
	"©️",
	"®️",
	"〰️",
	"➰",
	"➿",
	"🔚",
	"🔙",
	"🔛",
	"🔝",
	"🔜",
	"✔️",
	"☑️",
	"🔘",
	"🔴",
	"🟠",
	"🟡",
	"🟢",
	"🔵",
	"🟣",
	"⚫️",
	"⚪️",
	"🟤",
	"🔺",
	"🔻",
	"🔸",
	"🔹",
	"🔶",
	"🔷",
	"🔳",
	"🔲",
	"▪️",
	"▫️",
	"◾️",
	"◽️",
	"◼️",
	"◻️",
	"🟥",
	"🟧",
	"🟨",
	"🟩",
	"🟦",
	"🟪",
	"⬛️",
	"⬜️",
	"🟫",
	"🔈",
	"🔇",
	"🔉",
	"🔊",
	"🔔",
	"🔕",
	"📣",
	"📢",
	"👁‍🗨",
	"💬",
	"💭",
	"🗯",
	"♠️",
	"♣️",
	"♥️",
	"♦️",
	"🃏",
	"🎴",
	"🀄️",
	"🕐",
	"🕑",
	"🕒",
	"🕓",
	"🕔",
	"🕕",
	"🕖",
	"🕗",
	"🕘",
	"🕙",
	"🕚",
	"🕛",
	"🕜",
	"🕝",
	"🕞",
	"🕟",
	"🕠",
	"🕡",
	"🕢",
	"🕣",
	"🕤",
	"🕥",
	"🕦",
	"🕧",
];
export const Flags = [
	"🏁",
	"🚩",
	"🎌",
	"🏴",
	"🏳️",
	"🏳",
	"🏳️‍🌈",
	"🏳‍🌈",
	"🏳️‍⚧️",
	"🏳‍⚧️",
	"🏳️‍⚧",
	"🏳‍⚧",
	"🏴‍☠️",
	"🏴‍☠",
	"🇦🇨",
	"🇦🇩",
	"🇦🇪",
	"🇦🇫",
	"🇦🇬",
	"🇦🇮",
	"🇦🇱",
	"🇦🇲",
	"🇦🇴",
	"🇦🇶",
	"🇦🇷",
	"🇦🇸",
	"🇦🇹",
	"🇦🇺",
	"🇦🇼",
	"🇦🇽",
	"🇦🇿",
	"🇧🇦",
	"🇧🇧",
	"🇧🇩",
	"🇧🇪",
	"🇧🇫",
	"🇧🇬",
	"🇧🇭",
	"🇧🇮",
	"🇧🇯",
	"🇧🇱",
	"🇧🇲",
	"🇧🇳",
	"🇧🇴",
	"🇧🇶",
	"🇧🇷",
	"🇧🇸",
	"🇧🇹",
	"🇧🇻",
	"🇧🇼",
	"🇧🇾",
	"🇧🇿",
	"🇨🇦",
	"🇨🇨",
	"🇨🇩",
	"🇨🇫",
	"🇨🇬",
	"🇨🇭",
	"🇨🇮",
	"🇨🇰",
	"🇨🇱",
	"🇨🇲",
	"🇨🇳",
	"🇨🇴",
	"🇨🇵",
	"🇨🇷",
	"🇨🇺",
	"🇨🇻",
	"🇨🇼",
	"🇨🇽",
	"🇨🇾",
	"🇨🇿",
	"🇩🇪",
	"🇩🇬",
	"🇩🇯",
	"🇩🇰",
	"🇩🇲",
	"🇩🇴",
	"🇩🇿",
	"🇪🇦",
	"🇪🇨",
	"🇪🇪",
	"🇪🇬",
	"🇪🇭",
	"🇪🇷",
	"🇪🇸",
	"🇪🇹",
	"🇪🇺",
	"🇫🇮",
	"🇫🇯",
	"🇫🇰",
	"🇫🇲",
	"🇫🇴",
	"🇫🇷",
	"🇬🇦",
	"🇬🇧",
	"🇬🇩",
	"🇬🇪",
	"🇬🇫",
	"🇬🇬",
	"🇬🇭",
	"🇬🇮",
	"🇬🇱",
	"🇬🇲",
	"🇬🇳",
	"🇬🇵",
	"🇬🇶",
	"🇬🇷",
	"🇬🇸",
	"🇬🇹",
	"🇬🇺",
	"🇬🇼",
	"🇬🇾",
	"🇭🇰",
	"🇭🇲",
	"🇭🇳",
	"🇭🇷",
	"🇭🇹",
	"🇭🇺",
	"🇮🇨",
	"🇮🇩",
	"🇮🇪",
	"🇮🇱",
	"🇮🇲",
	"🇮🇳",
	"🇮🇴",
	"🇮🇶",
	"🇮🇷",
	"🇮🇸",
	"🇮🇹",
	"🇯🇪",
	"🇯🇲",
	"🇯🇴",
	"🇯🇵",
	"🇰🇪",
	"🇰🇬",
	"🇰🇭",
	"🇰🇮",
	"🇰🇲",
	"🇰🇳",
	"🇰🇵",
	"🇰🇷",
	"🇰🇼",
	"🇰🇾",
	"🇰🇿",
	"🇱🇦",
	"🇱🇧",
	"🇱🇨",
	"🇱🇮",
	"🇱🇰",
	"🇱🇷",
	"🇱🇸",
	"🇱🇹",
	"🇱🇺",
	"🇱🇻",
	"🇱🇾",
	"🇲🇦",
	"🇲🇨",
	"🇲🇩",
	"🇲🇪",
	"🇲🇫",
	"🇲🇬",
	"🇲🇭",
	"🇲🇰",
	"🇲🇱",
	"🇲🇲",
	"🇲🇳",
	"🇲🇴",
	"🇲🇵",
	"🇲🇶",
	"🇲🇷",
	"🇲🇸",
	"🇲🇹",
	"🇲🇺",
	"🇲🇻",
	"🇲🇼",
	"🇲🇽",
	"🇲🇾",
	"🇲🇿",
	"🇳🇦",
	"🇳🇨",
	"🇳🇪",
	"🇳🇫",
	"🇳🇬",
	"🇳🇮",
	"🇳🇱",
	"🇳🇴",
	"🇳🇵",
	"🇳🇷",
	"🇳🇺",
	"🇳🇿",
	"🇴🇲",
	"🇵🇦",
	"🇵🇪",
	"🇵🇫",
	"🇵🇬",
	"🇵🇭",
	"🇵🇰",
	"🇵🇱",
	"🇵🇲",
	"🇵🇳",
	"🇵🇷",
	"🇵🇸",
	"🇵🇹",
	"🇵🇼",
	"🇵🇾",
	"🇶🇦",
	"🇷🇪",
	"🇷🇴",
	"🇷🇸",
	"🇷🇺",
	"🇷🇼",
	"🇸🇦",
	"🇸🇧",
	"🇸🇨",
	"🇸🇩",
	"🇸🇪",
	"🇸🇬",
	"🇸🇭",
	"🇸🇮",
	"🇸🇯",
	"🇸🇰",
	"🇸🇱",
	"🇸🇲",
	"🇸🇳",
	"🇸🇴",
	"🇸🇷",
	"🇸🇸",
	"🇸🇹",
	"🇸🇻",
	"🇸🇽",
	"🇸🇾",
	"🇸🇿",
	"🇹🇦",
	"🇹🇨",
	"🇹🇩",
	"🇹🇫",
	"🇹🇬",
	"🇹🇭",
	"🇹🇯",
	"🇹🇰",
	"🇹🇱",
	"🇹🇲",
	"🇹🇳",
	"🇹🇴",
	"🇹🇷",
	"🇹🇹",
	"🇹🇻",
	"🇹🇼",
	"🇹🇿",
	"🇺🇦",
	"🇺🇬",
	"🇺🇲",
	"🇺🇳",
	"🇺🇸",
	"🇺🇾",
	"🇺🇿",
	"🇻🇦",
	"🇻🇨",
	"🇻🇪",
	"🇻🇬",
	"🇻🇮",
	"🇻🇳",
	"🇻🇺",
	"🇼🇫",
	"🇼🇸",
	"🇽🇰",
	"🇾🇪",
	"🇾🇹",
	"🇿🇦",
	"🇿🇲",
	"🇿🇼",
	"🏴󠁧󠁢󠁥󠁮󠁧󠁿",
	"🏴󠁧󠁢󠁳󠁣󠁴󠁿",
	"🏴󠁧󠁢󠁷󠁬󠁳󠁿",
];

export const TagJson = [
	{Letter:'😀',Tags:['😀','grinning face','Grinning Face','grinning','Grinning','face','Face','laugh','Laugh','happy','Happy','laughing','Laughing','smile','Smile','smiley','Smiley']},
	{Letter:'😃',Tags:['😃','grinning face with big eyes','Grinning Face With Big Eyes','grinning','Grinning','face','Face','laugh','Laugh','happy','Happy','laughing','Laughing','smile','Smile','smiley','Smiley']},
	{Letter:'😄',Tags:['😄','grinning face with smiling eyes','Grinning Face With Smiling Eyes','grinning','Grinning','face','Face','laugh','Laugh','laughing','Laughing','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'😁',Tags:['😁','beaming face with smiling eyes','Beaming Face With Smiling Eyes','beaming','Beaming','face','Face','laugh','Laugh','laughing','Laughing','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'😆',Tags:['😆','grinning squinting face','Grinning Squinting Face','grinning','Grinning','squinting','Squinting','face','Face','laugh','Laugh','happy','Happy','smile','Smile','smiley','Smiley','laughing','Laughing']},
	{Letter:'😅',Tags:['😅','grinning face with sweat','Grinning Face With Sweat','grinning','Grinning','face','Face','laugh','Laugh','sweat','Sweat','happy','Happy','smile','Smile','smiley','Smiley','laughing','Laughing']},
	{Letter:'😂',Tags:['😂','face with tears of joy','Face With Tears Of Joy','face','Face','laugh','Laugh','tears','Tears','laughing','Laughing','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'🤣',Tags:['🤣','rolling on the floor laughing','Rolling On The Floor Laughing','rolling','Rolling','laugh','Laugh','rofl','Rofl','happy','Happy','laughing','Laughing','smile','Smile','smiley','Smiley']},
	{Letter:'🥲',Tags:['🥲','smiling face with tear','Smiling Face With Tear','smiling','Smiling','face','Face','tear','Tear','smile','Smile','smiley','Smiley']},
	{Letter:'😊',Tags:['😊','smiling face with smiling eyes','Smiling Face With Smiling Eyes','smiling','Smiling','face','Face','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'😇',Tags:['😇','smiling face with halo','Smiling Face With Halo','smiling','Smiling','face','Face','angel','Angel','halo','Halo','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'🙂',Tags:['🙂','slightly smiling face','Slightly Smiling Face','slightly','Slightly','smiling','Smiling','face','Face','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'🙃',Tags:['🙃','upside-down face','Upside-Down Face','upside down','Upside Down','face','Face','happy','Happy','smile','Smile','smiley','Smiley']},
	{Letter:'😉',Tags:['😉','winking face','Winking Face','winking','Winking','face','Face','smile','Smile','smiley','Smiley']},
	{Letter:'😌',Tags:['😌','relieved face','Relieved Face','relieved','Relieved','face','Face','smile','Smile','smiley','Smiley']},
	{Letter:'😍',Tags:['😍','smiling face with heart-eyes','Smiling Face With Heart-Eyes','smiling','Smiling','face','Face','heart','Heart','heart eyes','Heart Eyes','love','Love','smile','Smile','smiley','Smiley']},
	{Letter:'🥰',Tags:['🥰','smiling face with hearts','Smiling Face With Hearts','smiling','Smiling','face','Face','heart','Heart','hearts','Hearts','love','Love','smile','Smile','smiley','Smiley']},
	{Letter:'😘',Tags:['😘','face blowing a kiss','Face Blowing A Kiss','face','Face','blowing','Blowing','heart','Heart','kiss','Kiss','love','Love','smile','Smile','smiley','Smiley']},
	{Letter:'😗',Tags:['😗','kissing face','Kissing Face','kissing','Kissing','face','Face','kiss','Kiss','love','Love','smile','Smile','smiley','Smiley']},
	{Letter:'😙',Tags:['😙','kissing face with smiling eyes','Kissing Face With Smiling Eyes','kissing','Kissing','face','Face','kiss','Kiss','love','Love','smile','Smile','smiley','Smiley']},
	{Letter:'😚',Tags:['😚','kissing face with closed eyes','Kissing Face With Closed Eyes','kissing','Kissing','face','Face','kiss','Kiss','love','Love','smile','Smile','smiley','Smiley']},
	{Letter:'😋',Tags:['😋','face savoring food','Face Savoring Food','face','Face','savoring','Savoring','food','Food','smile','Smile','smiley','Smiley']},
	{Letter:'😛',Tags:['😛','face with tongue','Face With Tongue','face','Face','tongue','Tongue','smile','Smile','smiley','Smiley']},
	{Letter:'😝',Tags:['😝','squinting face with tongue','Squinting Face With Tongue','squinting','Squinting','face','Face','tongue','Tongue','smile','Smile','smiley','Smiley']},
	{Letter:'😜',Tags:['😜','winking face with tongue','Winking Face With Tongue','winking','Winking','face','Face','tongue','Tongue','smile','Smile','smiley','Smiley']},
	{Letter:'🤪',Tags:['🤪','zany face','Zany Face','zany','Zany','face','Face','tongue','Tongue','smile','Smile','smiley','Smiley']},
	{Letter:'🤨',Tags:['🤨','face with raised eyebrow','Face With Raised Eyebrow','face','Face','raised','Raised','eyebrow','Eyebrow']},
	{Letter:'🧐',Tags:['🧐','face with monocle','Face With Monocle','face','Face','monocle','Monocle']},
	{Letter:'🤓',Tags:['🤓','nerd face','Nerd Face','nerd','Nerd','face','Face']},
	{Letter:'😎',Tags:['😎','smiling face with sunglasses','Smiling Face With Sunglasses','smiling','Smiling','face','Face','sunglasses','Sunglasses']},
	{Letter:'🥸',Tags:['🥸','disguised face','Disguised Face','disguised','Disguised','face','Face']},
	{Letter:'🤩',Tags:['🤩','star-struck','Star-Struck','star struck','Star Struck']},
	{Letter:'🥳',Tags:['🥳','partying face','Partying Face','partying','Partying','face','Face']},
	{Letter:'😏',Tags:['😏','smirking face','Smirking Face','smirking','Smirking','face','Face']},
	{Letter:'😒',Tags:['😒','unamused face','Unamused Face','unamused','Unamused','face','Face']},
	{Letter:'😞',Tags:['😞','disappointed face','Disappointed Face','disappointed','Disappointed','face','Face','sad','Sad']},
	{Letter:'😔',Tags:['😔','pensive face','Pensive Face','pensive','Pensive','face','Face','sad','Sad']},
	{Letter:'😟',Tags:['😟','worried face','Worried Face','worried','Worried','face','Face','sad','Sad']},
	{Letter:'😕',Tags:['😕','confused face','Confused Face','confused','Confused','face','Face','sad','Sad']},
	{Letter:'🙁',Tags:['🙁','slightly frowning face','Slightly Frowning Face','slightly','Slightly','frowning','Frowning','face','Face','sad','Sad']},
	{Letter:'☹️',Tags:['☹️','frowning face','Frowning Face','frowning','Frowning','face','Face','sad','Sad']},
	{Letter:'😣',Tags:['😣','persevering face','Persevering Face','persevering','Persevering','face','Face','sad','Sad']},
	{Letter:'😖',Tags:['😖','confounded face','Confounded Face','confounded','Confounded','face','Face','sad','Sad']},
	{Letter:'😫',Tags:['😫','tired face','Tired Face','tired','Tired','face','Face','sad','Sad']},
	{Letter:'😩',Tags:['😩','weary face','Weary Face','weary','Weary','face','Face','sad','Sad']},
	{Letter:'🥺',Tags:['🥺','pleading face','Pleading Face','pleading','Pleading','face','Face','sad','Sad']},
	{Letter:'😢',Tags:['😢','crying face','Crying Face','crying','Crying','face','Face','sad','Sad']},
	{Letter:'😭',Tags:['😭','loudly crying face','Loudly Crying Face','loudly','Loudly','crying','Crying','face','Face','sad','Sad']},
	{Letter:'😤',Tags:['😤','face with steam from nose','Face With Steam From Nose','face','Face','angry','Angry','steam','Steam','from','From','nose','Nose']},
	{Letter:'😠',Tags:['😠','angry face','Angry Face','angry','Angry','face','Face']},
	{Letter:'😡',Tags:['😡','pouting face','Pouting Face','pouting','Pouting','face','Face','angry','Angry']},
	{Letter:'🤬',Tags:['🤬','face with symbols on mouth','Face With Symbols On Mouth','face','Face','angry','Angry','symbols','Symbols','mouth','Mouth']},
	{Letter:'🤯',Tags:['🤯','exploding head','Exploding Head','exploding','Exploding','head','Head']},
	{Letter:'😳',Tags:['😳','flushed face','Flushed Face','flushed','Flushed','face','Face']},
	{Letter:'🥵',Tags:['🥵','hot face','Hot Face','hot','Hot','face','Face']},
	{Letter:'🥶',Tags:['🥶','cold face','Cold Face','cold','Cold','face','Face']},
	{Letter:'😱',Tags:['😱','face screaming in fear','Face Screaming In Fear','face','Face','screaming','Screaming','fear','Fear']},
	{Letter:'😨',Tags:['😨','fearful face','Fearful Face','fearful','Fearful','face','Face']},
	{Letter:'😰',Tags:['😰','anxious face with sweat','Anxious Face With Sweat','anxious','Anxious','face','Face','sad','Sad','sweat','Sweat']},
	{Letter:'😥',Tags:['😥','sad but relieved face','Sad But Relieved Face','sad','Sad','but','But','relieved','Relieved','face','Face']},
	{Letter:'😓',Tags:['😓','downcast face with sweat','Downcast Face With Sweat','downcast','Downcast','face','Face','sweat','Sweat']},
	{Letter:'🤗',Tags:['🤗','hugging face','Hugging Face','hugging','Hugging','face','Face','hug','Hug']},
	{Letter:'🤔',Tags:['🤔','thinking face','Thinking Face','thinking','Thinking','face','Face']},
	{Letter:'🤭',Tags:['🤭','face with hand over mouth','Face With Hand Over Mouth','face','Face','hand','Hand','over','Over','mouth','Mouth']},
	{Letter:'🤫',Tags:['🤫','shushing face','Shushing Face','shushing','Shushing','face','Face']},
	{Letter:'🤥',Tags:['🤥','lying face','Lying Face','lying','Lying','face','Face']},
	{Letter:'😶',Tags:['😶','face without mouth','Face Without Mouth','face','Face','without','Without','mouth','Mouth']},
	{Letter:'😐',Tags:['😐','neutral face','Neutral Face','neutral','Neutral','face','Face']},
	{Letter:'😑',Tags:['😑','expressionless face','Expressionless Face','expressionless','Expressionless','face','Face']},
	{Letter:'😬',Tags:['😬','grimacing face','Grimacing Face','grimacing','Grimacing','face','Face']},
	{Letter:'🙄',Tags:['🙄','face with rolling eyes','Face With Rolling Eyes','face','Face','rolling','Rolling','eyes','Eyes','think','Think']},
	{Letter:'😯',Tags:['😯','hushed face','Hushed Face','hushed','Hushed','face','Face']},
	{Letter:'😦',Tags:['😦','frowning face with open mouth','Frowning Face With Open Mouth','frowning','Frowning','face','Face','open','Open','mouth','Mouth']},
	{Letter:'😧',Tags:['😧','anguished face','Anguished Face','anguished','Anguished','face','Face']},
	{Letter:'😮',Tags:['😮','face with open mouth','Face With Open Mouth','face','Face','open','Open','mouth','Mouth']},
	{Letter:'😲',Tags:['😲','astonished face','Astonished Face','astonished','Astonished','face','Face']},
	{Letter:'🥱',Tags:['🥱','yawning face','Yawning Face','yawning','Yawning','face','Face']},
	{Letter:'😴',Tags:['😴','sleeping face','Sleeping Face','sleeping','Sleeping','face','Face','tired','Tired','zzz','Zzz','sleep','Sleep']},
	{Letter:'🤤',Tags:['🤤','drooling face','Drooling Face','drooling','Drooling','face','Face']},
	{Letter:'😪',Tags:['😪','sleepy face','Sleepy Face','sleepy','Sleepy','face','Face']},
	{Letter:'😵',Tags:['😵','dizzy face','Dizzy Face','dizzy','Dizzy','face','Face']},
	{Letter:'🤐',Tags:['🤐','zipper-mouth face','Zipper-Mouth Face','zipper mouth','Zipper Mouth','face','Face']},
	{Letter:'🥴',Tags:['🥴','woozy face','Woozy Face','woozy','Woozy','face','Face','sick','Sick','ill','Ill']},
	{Letter:'🤢',Tags:['🤢','nauseated face','Nauseated Face','nauseated','Nauseated','face','Face','sick','Sick','ill','Ill']},
	{Letter:'🤮',Tags:['🤮','face vomiting','Face Vomiting','face','Face','vomiting','Vomiting','sick','Sick','ill','Ill']},
	{Letter:'🤧',Tags:['🤧','sneezing face','Sneezing Face','sneezing','Sneezing','face','Face','sick','Sick','ill','Ill']},
	{Letter:'😷',Tags:['😷','face with medical mask','Face With Medical Mask','face','Face','medical','Medical','mask','Mask']},
	{Letter:'🤒',Tags:['🤒','face with thermometer','Face With Thermometer','face','Face','thermometer','Thermometer','sick','Sick','ill','Ill']},
	{Letter:'🤕',Tags:['🤕','face with head-bandage','Face With Head-Bandage','face','Face','head bandage','Head Bandage','sick','Sick','ill','Ill']},
	{Letter:'🤑',Tags:['🤑','money-mouth face','Money-Mouth Face','money mouth','Money Mouth','face','Face','dollar','Dollar','money','Money']},
	{Letter:'🤠',Tags:['🤠','cowboy hat face','Cowboy Hat Face','cowboy','Cowboy','hat','Hat','face','Face']},
	{Letter:'😈',Tags:['😈','smiling face with horns','Smiling Face With Horns','smiling','Smiling','face','Face','devil','Devil','horns','Horns']},
	{Letter:'👿',Tags:['👿','angry face with horns','Angry Face With Horns','angry','Angry','face','Face','devil','Devil','horns','Horns']},
	{Letter:'👹',Tags:['👹','ogre','Ogre','ogre','Ogre','devil','Devil']},
	{Letter:'👺',Tags:['👺','goblin','Goblin','goblin','Goblin','devil','Devil']},
	{Letter:'🤡',Tags:['🤡','clown face','Clown Face','clown','Clown','face','Face']},
	{Letter:'💩',Tags:['💩','pile of poo','Pile Of Poo','pile','Pile','poo','Poo']},
	{Letter:'👻',Tags:['👻','ghost','Ghost','ghost','Ghost']},
	{Letter:'💀',Tags:['💀','skull','Skull','skull','Skull']},
	{Letter:'☠️',Tags:['☠️','skull and crossbones','Skull And Crossbones','skull','Skull','and','And','crossbones','Crossbones']},
	{Letter:'👽',Tags:['👽','alien','Alien']},
	{Letter:'👾',Tags:['👾','alien monster','Alien Monster','alien','Alien','monster','Monster']},
	{Letter:'🤖',Tags:['🤖','robot','Robot','robot','Robot']},
	{Letter:'🎃',Tags:['🎃','jack-o-lantern','Jack-O-Lantern','jack o lantern','Jack O Lantern','pumpkin','Pumpkin','halloween','Halloween']},
	{Letter:'😺',Tags:['😺','grinning cat','Grinning Cat','grinning','Grinning','cat','Cat']},
	{Letter:'😸',Tags:['😸','grinning cat with smiling eyes','Grinning Cat With Smiling Eyes','grinning','Grinning','cat','Cat','smiling','Smiling','eyes','Eyes']},
	{Letter:'😹',Tags:['😹','cat with tears of joy','Cat With Tears Of Joy','cat','Cat','laugh','Laugh','tears','Tears','laughing','Laughing','joy','Joy']},
	{Letter:'😻',Tags:['😻','smiling cat with heart-eyes','Smiling Cat With Heart-Eyes','smiling','Smiling','cat','Cat','heart','Heart','heart eyes','Heart Eyes']},
	{Letter:'😼',Tags:['😼','cat with wry smile','Cat With Wry Smile','cat','Cat','wry','Wry','smile','Smile']},
	{Letter:'😽',Tags:['😽','kissing cat','Kissing Cat','kissing','Kissing','cat','Cat']},
	{Letter:'🙀',Tags:['🙀','weary cat','Weary Cat','weary','Weary','cat','Cat']},
	{Letter:'😿',Tags:['😿','crying cat','Crying Cat','crying','Crying','cat','Cat','sad','Sad','cry','Cry']},
	{Letter:'😾',Tags:['😾','pouting cat','Pouting Cat','pouting','Pouting','cat','Cat','angry','Angry']},
	{Letter:'👋',Tags:['👋','waving hand','Waving Hand','waving','Waving','hand','Hand','wave','Wave','hello','Hello','hi','Hi']},
	{Letter:'🤚',Tags:['🤚','raised back of hand','Raised Back Of Hand','raised','Raised','back','Back','hand','Hand']},
	{Letter:'🖐',Tags:['🖐','hand with fingers splayed','Hand With Fingers Splayed','hand','Hand','fingers','Fingers','splayed','Splayed']},
	{Letter:'✋',Tags:['✋','raised hand','Raised Hand','raised','Raised','hand','Hand']},
	{Letter:'🖖',Tags:['🖖','vulcan salute','Vulcan Salute','vulcan','Vulcan','salute','Salute','hand','Hand']},
	{Letter:'👌',Tags:['👌','OK hand','Ok Hand','OK','Ok','hand','Hand']},
	{Letter:'🤌',Tags:['🤌','pinched fingers','Pinched Fingers','pinched','Pinched','fingers','Fingers','hand','Hand']},
	{Letter:'🤏',Tags:['🤏','pinching hand','Pinching Hand','pinching','Pinching','hand','Hand']},
	{Letter:'✌️',Tags:['✌️','victory hand','Victory Hand','victory','Victory','hand','Hand']},
	{Letter:'🤞',Tags:['🤞','crossed fingers','Crossed Fingers','crossed','Crossed','fingers','Fingers','hand','Hand']},
	{Letter:'🤟',Tags:['🤟','love-you gesture','Love-You Gesture','love you','Love You','gesture','Gesture','hand','Hand']},
	{Letter:'🤘',Tags:['🤘','sign of the horns','Sign Of The Horns','sign','Sign','horns','Horns','rock n roll','Rock N Roll','rock','Rock','roll','Roll','rock and roll','Rock And Roll','hand','Hand']},
	{Letter:'🤙',Tags:['🤙','call me hand','Call Me Hand','call','Call','me','Me','hand','Hand']},
	{Letter:'👈',Tags:['👈','backhand index pointing left','Backhand Index Pointing Left','backhand','Backhand','index','Index','pointing','Pointing','left','Left','hand','Hand']},
	{Letter:'👉',Tags:['👉','backhand index pointing right','Backhand Index Pointing Right','backhand','Backhand','index','Index','pointing','Pointing','right','Right','hand','Hand']},
	{Letter:'👆',Tags:['👆','backhand index pointing up','Backhand Index Pointing Up','backhand','Backhand','index','Index','pointing','Pointing','up','Up','hand','Hand']},
	{Letter:'🖕',Tags:['🖕','middle finger','Middle Finger','middle','Middle','finger','Finger','hand','Hand']},
	{Letter:'👇',Tags:['👇','backhand index pointing down','Backhand Index Pointing Down','backhand','Backhand','index','Index','pointing','Pointing','down','Down','hand','Hand']},
	{Letter:'☝️',Tags:['☝️','index pointing up','Index Pointing Up','index','Index','pointing','Pointing','up','Up','hand','Hand']},
	{Letter:'👍',Tags:['👍','thumbs up','Thumbs Up','thumbs','Thumbs','up','Up','thumb','Thumb','hand','Hand']},
	{Letter:'👎',Tags:['👎','thumbs down','Thumbs Down','thumbs','Thumbs','down','Down','thumb','Thumb','hand','Hand']},
	{Letter:'✊',Tags:['✊','raised fist','Raised Fist','raised','Raised','fist','Fist','hand','Hand']},
	{Letter:'👊',Tags:['👊','oncoming fist','Oncoming Fist','oncoming','Oncoming','fist','Fist','hand','Hand']},
	{Letter:'🤛',Tags:['🤛','left-facing fist','Left-Facing Fist','left facing','Left Facing','fist','Fist','hand','Hand']},
	{Letter:'🤜',Tags:['🤜','right-facing fist','Right-Facing Fist','right facing','Right Facing','fist','Fist','hand','Hand']},
	{Letter:'👏',Tags:['👏','clapping hands','Clapping Hands','clapping','Clapping','hands','Hands','hand','Hand']},
	{Letter:'🙌',Tags:['🙌','raising hands','Raising Hands','raising','Raising','hands','Hands','hand','Hand']},
	{Letter:'👐',Tags:['👐','open hands','Open Hands','open','Open','hands','Hands','hand','Hand']},
	{Letter:'🤲',Tags:['🤲','palms up together','Palms Up Together','palms','Palms','up','Up','together','Together','hand','Hand','hands','Hands','','']},
	{Letter:'🤝',Tags:['🤝','handshake','Handshake','handshake','Handshake','hand','Hand']},
	{Letter:'🙏',Tags:['🙏','folded hands','Folded Hands','folded','Folded','hands','Hands','hand','Hand']},
	{Letter:'✍️',Tags:['✍️','writing hand','Writing Hand','writing','Writing','hand','Hand']},
	{Letter:'💅',Tags:['💅','nail polish','Nail Polish','nail','Nail','polish','Polish','hand','Hand']},
	{Letter:'🤳',Tags:['🤳','selfie','Selfie','selfie','Selfie']},
	{Letter:'💪',Tags:['💪','flexed biceps','Flexed Biceps','flexed','Flexed','biceps','Biceps']},
	{Letter:'🦾',Tags:['🦾','mechanical arm','Mechanical Arm','mechanical','Mechanical','arm','Arm']},
	{Letter:'🦵',Tags:['🦵','leg','Leg','leg','Leg']},
	{Letter:'🦿',Tags:['🦿','mechanical leg','Mechanical Leg','mechanical','Mechanical','leg','Leg']},
	{Letter:'🦶',Tags:['🦶','foot','Foot','foot','Foot']},
	{Letter:'👣',Tags:['👣','footprints','Footprints','footprints','Footprints']},
	{Letter:'👂',Tags:['👂','ear','Ear','ear','Ear']},
	{Letter:'🦻',Tags:['🦻','ear with hearing aid','Ear With Hearing Aid','ear','Ear','hearing','Hearing','aid','Aid']},
	{Letter:'👃',Tags:['👃','nose','Nose','nose','Nose']},
	{Letter:'🫀',Tags:['🫀','anatomical heart','Anatomical Heart','anatomical','Anatomical','heart','Heart']},
	{Letter:'🫁',Tags:['🫁','lungs','Lungs','lungs','Lungs']},
	{Letter:'🧠',Tags:['🧠','brain','Brain','brain','Brain']},
	{Letter:'🦷',Tags:['🦷','tooth','Tooth','tooth','Tooth']},
	{Letter:'🦴',Tags:['🦴','bone','Bone','bone','Bone']},
	{Letter:'👀',Tags:['👀','eyes','Eyes','eyes','Eyes']},
	{Letter:'👁',Tags:['👁','eye','Eye','eye','Eye']},
	{Letter:'👅',Tags:['👅','tongue','Tongue','tongue','Tongue']},
	{Letter:'👄',Tags:['👄','mouth','Mouth','mouth','Mouth']},
	{Letter:'💋',Tags:['💋','kiss mark','Kiss Mark','kiss','Kiss','mark','Mark']},
	{Letter:'🩸',Tags:['🩸','drop of blood','Drop Of Blood','drop','Drop','blood','Blood']},
	{Letter:'👶',Tags:['👶','baby','Baby','baby','Baby','face','Face']},
	{Letter:'👧',Tags:['👧','girl','Girl','girl','Girl','face','Face']},
	{Letter:'🧒',Tags:['🧒','child','Child','child','Child','face','Face']},
	{Letter:'👦',Tags:['👦','boy','Boy','boy','Boy','face','Face']},
	{Letter:'👩',Tags:['👩','woman','Woman','woman','Woman','face','Face']},
	{Letter:'🧑',Tags:['🧑','person','Person','person','Person','face','Face']},
	{Letter:'👨',Tags:['👨','man','Man','man','Man','face','Face']},
	{Letter:'👩‍🦱',Tags:['👩‍🦱','woman: curly hair','Woman: Curly Hair','woman','Woman','curly','Curly','hair','Hair','face','Face']},
	{Letter:'🧑‍🦱',Tags:['🧑‍🦱','person: curly hair','Person: Curly Hair','person','Person','curly','Curly','hair','Hair','face','Face']},
	{Letter:'👨‍🦱',Tags:['👨‍🦱','man: curly hair','Man: Curly Hair','man','Man','curly','Curly','hair','Hair','face','Face']},
	{Letter:'👩‍🦰',Tags:['👩‍🦰','woman: red hair','Woman: Red Hair','woman','Woman','red','Red','hair','Hair','face','Face']},
	{Letter:'🧑‍🦰',Tags:['🧑‍🦰','person: red hair','Person: Red Hair','person','Person','red','Red','hair','Hair','face','Face']},
	{Letter:'👨‍🦰',Tags:['👨‍🦰','man: red hair','Man: Red Hair','man','Man','red','Red','hair','Hair','face','Face']},
	{Letter:'👱‍♀️',Tags:['👱‍♀️','woman: blond hair','Woman: Blond Hair','woman','Woman','blond','Blond','hair','Hair','face','Face']},
	{Letter:'👱',Tags:['👱','person: blond hair','Person: Blond Hair','person','Person','blond','Blond','hair','Hair','face','Face']},
	{Letter:'👱‍♂️',Tags:['👱‍♂️','man: blond hair','Man: Blond Hair','man','Man','blond','Blond','hair','Hair','face','Face']},
	{Letter:'👩‍🦳',Tags:['👩‍🦳','woman: white hair','Woman: White Hair','woman','Woman','white','White','hair','Hair','face','Face']},
	{Letter:'🧑‍🦳',Tags:['🧑‍🦳','person: white hair','Person: White Hair','person','Person','white','White','hair','Hair','face','Face']},
	{Letter:'👨‍🦳',Tags:['👨‍🦳','man: white hair','Man: White Hair','man','Man','white','White','hair','Hair','face','Face']},
	{Letter:'👩‍🦲',Tags:['👩‍🦲','woman: bald','Woman: Bald','woman','Woman','bald','Bald','face','Face']},
	{Letter:'🧑‍🦲',Tags:['🧑‍🦲','person: bald','Person: Bald','person','Person','bald','Bald','face','Face']},
	{Letter:'👨‍🦲',Tags:['👨‍🦲','man: bald','Man: Bald','man','Man','bald','Bald','face','Face']},
	{Letter:'🧔',Tags:['🧔','man: beard','Man: Beard','man','Man','beard','Beard','face','Face']},
	{Letter:'👵',Tags:['👵','old woman','Old Woman','old','Old','woman','Woman','face','Face']},
	{Letter:'🧓',Tags:['🧓','older person','Older Person','older','Older','person','Person','face','Face']},
	{Letter:'👴',Tags:['👴','old man','Old Man','old','Old','man','Man','face','Face']},
	{Letter:'👲',Tags:['👲','person with skullcap','Person With Skullcap','person','Person','skullcap','Skullcap','face','Face']},
	{Letter:'👳‍♀️',Tags:['👳‍♀️','woman wearing turban','Woman Wearing Turban','woman','Woman','wearing','Wearing','turban','Turban','face','Face']},
	{Letter:'👳',Tags:['👳','person wearing turban','Person Wearing Turban','person','Person','wearing','Wearing','turban','Turban','face','Face']},
	{Letter:'👳‍♂️',Tags:['👳‍♂️','man wearing turban','Man Wearing Turban','man','Man','wearing','Wearing','turban','Turban','face','Face']},
	{Letter:'🧕',Tags:['🧕','woman with headscarf','Woman With Headscarf','woman','Woman','headscarf','Headscarf','face','Face']},
	{Letter:'👮‍♀️',Tags:['👮‍♀️','woman police officer','Woman Police Officer','woman','Woman','police','Police','officer','Officer','face','Face']},
	{Letter:'👮',Tags:['👮','police officer','Police Officer','police','Police','officer','Officer','face','Face']},
	{Letter:'👮‍♂️',Tags:['👮‍♂️','man police officer','Man Police Officer','man','Man','police','Police','officer','Officer','face','Face']},
	{Letter:'👷‍♀️',Tags:['👷‍♀️','woman construction worker','Woman Construction Worker','woman','Woman','construction','Construction','worker','Worker','face','Face']},
	{Letter:'👷',Tags:['👷','construction worker','Construction Worker','construction','Construction','worker','Worker','face','Face']},
	{Letter:'👷‍♂️',Tags:['👷‍♂️','man construction worker','Man Construction Worker','man','Man','construction','Construction','worker','Worker','face','Face']},
	{Letter:'💂‍♀️',Tags:['💂‍♀️','woman guard','Woman Guard','woman','Woman','guard','Guard','face','Face']},
	{Letter:'💂',Tags:['💂','guard','Guard','guard','Guard','face','Face']},
	{Letter:'💂‍♂️',Tags:['💂‍♂️','man guard','Man Guard','man','Man','guard','Guard','face','Face']},
	{Letter:'🕵️‍♀️',Tags:['🕵️‍♀️','woman detective','Woman Detective','woman','Woman','detective','Detective','face','Face']},
	{Letter:'🕵️',Tags:['🕵️','detective','Detective','detective','Detective','face','Face']},
	{Letter:'🕵️‍♂️',Tags:['🕵️‍♂️','man detective','Man Detective','man','Man','detective','Detective','face','Face']},
	{Letter:'👩‍⚕️',Tags:['👩‍⚕️','woman health worker','Woman Health Worker','woman','Woman','health','Health','worker','Worker','face','Face']},
	{Letter:'🧑‍⚕️',Tags:['🧑‍⚕️','health worker','Health Worker','health','Health','worker','Worker','face','Face']},
	{Letter:'👨‍⚕️',Tags:['👨‍⚕️','man health worker','Man Health Worker','man','Man','health','Health','worker','Worker','face','Face']},
	{Letter:'👩‍🌾',Tags:['👩‍🌾','woman farmer','Woman Farmer','woman','Woman','farmer','Farmer','face','Face']},
	{Letter:'🧑‍🌾',Tags:['🧑‍🌾','farmer','Farmer','farmer','Farmer','face','Face']},
	{Letter:'👨‍🌾',Tags:['👨‍🌾','man farmer','Man Farmer','man','Man','farmer','Farmer','face','Face']},
	{Letter:'👩‍🍳',Tags:['👩‍🍳','woman cook','Woman Cook','woman','Woman','cook','Cook','face','Face']},
	{Letter:'🧑‍🍳',Tags:['🧑‍🍳','cook','Cook','cook','Cook','face','Face']},
	{Letter:'👨‍🍳',Tags:['👨‍🍳','man cook','Man Cook','man','Man','cook','Cook','face','Face']},
	{Letter:'👩‍🎓',Tags:['👩‍🎓','woman student','Woman Student','woman','Woman','student','Student','face','Face']},
	{Letter:'🧑‍🎓',Tags:['🧑‍🎓','student','Student','student','Student','face','Face']},
	{Letter:'👨‍🎓',Tags:['👨‍🎓','man student','Man Student','man','Man','student','Student','face','Face']},
	{Letter:'👩‍🎤',Tags:['👩‍🎤','woman singer','Woman Singer','woman','Woman','singer','Singer','face','Face']},
	{Letter:'🧑‍🎤',Tags:['🧑‍🎤','singer','Singer','singer','Singer','face','Face']},
	{Letter:'👨‍🎤',Tags:['👨‍🎤','man singer','Man Singer','man','Man','singer','Singer','face','Face']},
	{Letter:'👩‍🏫',Tags:['👩‍🏫','woman teacher','Woman Teacher','woman','Woman','teacher','Teacher','face','Face']},
	{Letter:'🧑‍🏫',Tags:['🧑‍🏫','teacher','Teacher','teacher','Teacher','face','Face']},
	{Letter:'👨‍🏫',Tags:['👨‍🏫','man teacher','Man Teacher','man','Man','teacher','Teacher','face','Face']},
	{Letter:'👩‍🏭',Tags:['👩‍🏭','woman factory worker','Woman Factory Worker','woman','Woman','factory','Factory','worker','Worker','face','Face']},
	{Letter:'🧑‍🏭',Tags:['🧑‍🏭','factory worker','Factory Worker','factory','Factory','worker','Worker','face','Face']},
	{Letter:'👨‍🏭',Tags:['👨‍🏭','man factory worker','Man Factory Worker','man','Man','factory','Factory','worker','Worker','face','Face']},
	{Letter:'👩‍💻',Tags:['👩‍💻','woman technologist','Woman Technologist','woman','Woman','technologist','Technologist','face','Face']},
	{Letter:'🧑‍💻',Tags:['🧑‍💻','technologist','Technologist','technologist','Technologist','face','Face']},
	{Letter:'👨‍💻',Tags:['👨‍💻','man technologist','Man Technologist','man','Man','technologist','Technologist','face','Face']},
	{Letter:'👩‍💼',Tags:['👩‍💼','woman office worker','Woman Office Worker','woman','Woman','office','Office','worker','Worker','face','Face']},
	{Letter:'🧑‍💼',Tags:['🧑‍💼','office worker','Office Worker','office','Office','worker','Worker','face','Face']},
	{Letter:'👨‍💼',Tags:['👨‍💼','man office worker','Man Office Worker','man','Man','office','Office','worker','Worker','face','Face']},
	{Letter:'👩‍🔧',Tags:['👩‍🔧','woman mechanic','Woman Mechanic','woman','Woman','mechanic','Mechanic','face','Face']},
	{Letter:'🧑‍🔧',Tags:['🧑‍🔧','mechanic','Mechanic','mechanic','Mechanic','face','Face']},
	{Letter:'👨‍🔧',Tags:['👨‍🔧','man mechanic','Man Mechanic','man','Man','mechanic','Mechanic','face','Face']},
	{Letter:'👩‍🔬',Tags:['👩‍🔬','woman scientist','Woman Scientist','woman','Woman','scientist','Scientist','face','Face']},
	{Letter:'🧑‍🔬',Tags:['🧑‍🔬','scientist','Scientist','scientist','Scientist','face','Face']},
	{Letter:'👨‍🔬',Tags:['👨‍🔬','man scientist','Man Scientist','man','Man','scientist','Scientist','face','Face']},
	{Letter:'👩‍🎨',Tags:['👩‍🎨','woman artist','Woman Artist','woman','Woman','artist','Artist','face','Face']},
	{Letter:'🧑‍🎨',Tags:['🧑‍🎨','artist','Artist','artist','Artist','face','Face']},
	{Letter:'👨‍🎨',Tags:['👨‍🎨','man artist','Man Artist','man','Man','artist','Artist','face','Face']},
	{Letter:'👩‍🚒',Tags:['👩‍🚒','woman firefighter','Woman Firefighter','woman','Woman','firefighter','Firefighter','face','Face']},
	{Letter:'🧑‍🚒',Tags:['🧑‍🚒','firefighter','Firefighter','firefighter','Firefighter','face','Face']},
	{Letter:'👨‍🚒',Tags:['👨‍🚒','man firefighter','Man Firefighter','man','Man','firefighter','Firefighter','face','Face']},
	{Letter:'👩‍✈️',Tags:['👩‍✈️','woman pilot','Woman Pilot','woman','Woman','pilot','Pilot','face','Face']},
	{Letter:'🧑‍✈️',Tags:['🧑‍✈️','pilot','Pilot','pilot','Pilot','face','Face']},
	{Letter:'👨‍✈️',Tags:['👨‍✈️','man pilot','Man Pilot','man','Man','pilot','Pilot','face','Face']},
	{Letter:'👩‍🚀',Tags:['👩‍🚀','woman astronaut','Woman Astronaut','woman','Woman','astronaut','Astronaut','face','Face']},
	{Letter:'🧑‍🚀',Tags:['🧑‍🚀','astronaut','Astronaut','astronaut','Astronaut','face','Face']},
	{Letter:'👨‍🚀',Tags:['👨‍🚀','man astronaut','Man Astronaut','man','Man','astronaut','Astronaut','face','Face']},
	{Letter:'👩‍⚖️',Tags:['👩‍⚖️','woman judge','Woman Judge','woman','Woman','judge','Judge','face','Face']},
	{Letter:'🧑‍⚖️',Tags:['🧑‍⚖️','judge','Judge','judge','Judge','face','Face']},
	{Letter:'👨‍⚖️',Tags:['👨‍⚖️','man judge','Man Judge','man','Man','judge','Judge','face','Face']},
	{Letter:'👰‍♀️',Tags:['👰‍♀️','woman with veil','Woman With Veil','woman','Woman','veil','Veil','face','Face']},
	{Letter:'👰',Tags:['👰','person with veil','Person With Veil','person','Person','veil','Veil','face','Face']},
	{Letter:'👰‍♂️',Tags:['👰‍♂️','man with veil','Man With Veil','man','Man','veil','Veil','face','Face']},
	{Letter:'🤵‍♀️',Tags:['🤵‍♀️','woman in tuxedo','Woman In Tuxedo','woman','Woman','tuxedo','Tuxedo','face','Face']},
	{Letter:'🤵',Tags:['🤵','person in tuxedo','Person In Tuxedo','person','Person','tuxedo','Tuxedo','face','Face']},
	{Letter:'🤵‍♂️',Tags:['🤵‍♂️','man in tuxedo','Man In Tuxedo','man','Man','tuxedo','Tuxedo','face','Face']},
	{Letter:'👸',Tags:['👸','princess','Princess','princess','Princess','face','Face']},
	{Letter:'🤴',Tags:['🤴','prince','Prince','prince','Prince','face','Face']},
	{Letter:'🥷',Tags:['🥷','ninja','Ninja','ninja','Ninja','face','Face']},
	{Letter:'🦸‍♀️',Tags:['🦸‍♀️','woman superhero','Woman Superhero','woman','Woman','superhero','Superhero','face','Face']},
	{Letter:'🦸',Tags:['🦸','superhero','Superhero','superhero','Superhero','face','Face']},
	{Letter:'🦸‍♂️',Tags:['🦸‍♂️','man superhero','Man Superhero','man','Man','superhero','Superhero','face','Face']},
	{Letter:'🦹‍♀️',Tags:['🦹‍♀️','woman supervillain','Woman Supervillain','woman','Woman','supervillain','Supervillain','face','Face']},
	{Letter:'🦹',Tags:['🦹','supervillain','Supervillain','supervillain','Supervillain','face','Face']},
	{Letter:'🦹‍♂️',Tags:['🦹‍♂️','man supervillain','Man Supervillain','man','Man','supervillain','Supervillain','face','Face']},
	{Letter:'🤶',Tags:['🤶','Mrs. Claus','Mrs. Claus','Mrs.','Mrs.','Claus','Claus','face','Face']},
	{Letter:'🧑‍🎄',Tags:['🧑‍🎄','mx claus','Mx Claus','mx','Mx','claus','Claus','face','Face']},
	{Letter:'🎅',Tags:['🎅','Santa Claus','Santa Claus','Santa','Santa','Claus','Claus','face','Face']},
	{Letter:'🧙‍♀️',Tags:['🧙‍♀️','woman mage','Woman Mage','woman','Woman','mage','Mage','face','Face']},
	{Letter:'🧙',Tags:['🧙','mage','Mage','mage','Mage','face','Face']},
	{Letter:'🧙‍♂️',Tags:['🧙‍♂️','man mage','Man Mage','man','Man','mage','Mage','face','Face']},
	{Letter:'🧝‍♀️',Tags:['🧝‍♀️','woman elf','Woman Elf','woman','Woman','elf','Elf','face','Face']},
	{Letter:'🧝',Tags:['🧝','elf','Elf','elf','Elf','face','Face']},
	{Letter:'🧝‍♂️',Tags:['🧝‍♂️','man elf','Man Elf','man','Man','elf','Elf','face','Face']},
	{Letter:'🧛‍♀️',Tags:['🧛‍♀️','woman vampire','Woman Vampire','woman','Woman','vampire','Vampire','face','Face']},
	{Letter:'🧛',Tags:['🧛','vampire','Vampire','vampire','Vampire','face','Face']},
	{Letter:'🧛‍♂️',Tags:['🧛‍♂️','man vampire','Man Vampire','man','Man','vampire','Vampire','face','Face']},
	{Letter:'🧟‍♀️',Tags:['🧟‍♀️','woman zombie','Woman Zombie','woman','Woman','zombie','Zombie','face','Face']},
	{Letter:'🧟',Tags:['🧟','zombie','Zombie','zombie','Zombie','face','Face']},
	{Letter:'🧟‍♂️',Tags:['🧟‍♂️','man zombie','Man Zombie','man','Man','zombie','Zombie','face','Face']},
	{Letter:'🧞‍♀️',Tags:['🧞‍♀️','woman genie','Woman Genie','woman','Woman','genie','Genie']},
	{Letter:'🧞',Tags:['🧞','genie','Genie','genie','Genie']},
	{Letter:'🧞‍♂️',Tags:['🧞‍♂️','man genie','Man Genie','man','Man','genie','Genie']},
	{Letter:'🧜‍♀️',Tags:['🧜‍♀️','mermaid','Mermaid','mermaid','Mermaid']},
	{Letter:'🧜',Tags:['🧜','merperson','Merperson','merperson','Merperson']},
	{Letter:'🧜‍♂️',Tags:['🧜‍♂️','merman','Merman','merman','Merman']},
	{Letter:'🧚‍♀️',Tags:['🧚‍♀️','woman fairy','Woman Fairy','woman','Woman','fairy','Fairy']},
	{Letter:'🧚',Tags:['🧚','fairy','Fairy','fairy','Fairy']},
	{Letter:'🧚‍♂️',Tags:['🧚‍♂️','man fairy','Man Fairy','man','Man','fairy','Fairy']},
	{Letter:'👼',Tags:['👼','baby angel','Baby Angel','baby','Baby','angel','Angel']},
	{Letter:'🤰',Tags:['🤰','pregnant woman','Pregnant Woman','pregnant','Pregnant','woman','Woman']},
	{Letter:'🤱',Tags:['🤱','breast-feeding','Breast-Feeding','breast feeding','Breast Feeding']},
	{Letter:'👩‍🍼',Tags:['👩‍🍼','woman feeding baby','Woman Feeding Baby','woman','Woman','feeding','Feeding','baby','Baby']},
	{Letter:'🧑‍🍼',Tags:['🧑‍🍼','person feeding baby','Person Feeding Baby','person','Person','feeding','Feeding','baby','Baby']},
	{Letter:'👨‍🍼',Tags:['👨‍🍼','man feeding baby','Man Feeding Baby','man','Man','feeding','Feeding','baby','Baby']},
	{Letter:'🙇‍♀️',Tags:['🙇‍♀️','woman bowing','Woman Bowing','woman','Woman','bowing','Bowing']},
	{Letter:'🙇',Tags:['🙇','person bowing','Person Bowing','person','Person','bowing','Bowing']},
	{Letter:'🙇‍♂️',Tags:['🙇‍♂️','man bowing','Man Bowing','man','Man','bowing','Bowing']},
	{Letter:'💁‍♀️',Tags:['💁‍♀️','woman tipping hand','Woman Tipping Hand','woman','Woman','tipping','Tipping','hand','Hand']},
	{Letter:'💁',Tags:['💁','person tipping hand','Person Tipping Hand','person','Person','tipping','Tipping','hand','Hand']},
	{Letter:'💁‍♂️',Tags:['💁‍♂️','man tipping hand','Man Tipping Hand','man','Man','tipping','Tipping','hand','Hand']},
	{Letter:'🙅‍♀️',Tags:['🙅‍♀️','woman gesturing NO','Woman Gesturing No','woman','Woman','gesturing','Gesturing','NO','No']},
	{Letter:'🙅',Tags:['🙅','person gesturing NO','Person Gesturing No','person','Person','gesturing','Gesturing','NO','No']},
	{Letter:'🙅‍♂️',Tags:['🙅‍♂️','man gesturing NO','Man Gesturing No','man','Man','gesturing','Gesturing','NO','No']},
	{Letter:'🙆‍♀️',Tags:['🙆‍♀️','woman gesturing OK','Woman Gesturing Ok','woman','Woman','gesturing','Gesturing','OK','Ok']},
	{Letter:'🙆',Tags:['🙆','person gesturing OK','Person Gesturing Ok','person','Person','gesturing','Gesturing','OK','Ok']},
	{Letter:'🙆‍♂️',Tags:['🙆‍♂️','man gesturing OK','Man Gesturing Ok','man','Man','gesturing','Gesturing','OK','Ok']},
	{Letter:'🙋‍♀️',Tags:['🙋‍♀️','woman raising hand','Woman Raising Hand','woman','Woman','raising','Raising','hand','Hand']},
	{Letter:'🙋',Tags:['🙋','person raising hand','Person Raising Hand','person','Person','raising','Raising','hand','Hand']},
	{Letter:'🙋‍♂️',Tags:['🙋‍♂️','man raising hand','Man Raising Hand','man','Man','raising','Raising','hand','Hand']},
	{Letter:'🧏‍♀️',Tags:['🧏‍♀️','deaf woman','Deaf Woman','deaf','Deaf','woman','Woman']},
	{Letter:'🧏',Tags:['🧏','deaf person','Deaf Person','deaf','Deaf','person','Person']},
	{Letter:'🧏‍♂️',Tags:['🧏‍♂️','deaf man','Deaf Man','deaf','Deaf','man','Man']},
	{Letter:'🤦‍♀️',Tags:['🤦‍♀️','woman facepalming','Woman Facepalming','woman','Woman','facepalming','Facepalming']},
	{Letter:'🤦',Tags:['🤦','person facepalming','Person Facepalming','person','Person','facepalming','Facepalming']},
	{Letter:'🤦‍♂️',Tags:['🤦‍♂️','man facepalming','Man Facepalming','man','Man','facepalming','Facepalming']},
	{Letter:'🤷‍♀️',Tags:['🤷‍♀️','woman shrugging','Woman Shrugging','woman','Woman','shrugging','Shrugging']},
	{Letter:'🤷',Tags:['🤷','person shrugging','Person Shrugging','person','Person','shrugging','Shrugging']},
	{Letter:'🤷‍♂️',Tags:['🤷‍♂️','man shrugging','Man Shrugging','man','Man','shrugging','Shrugging']},
	{Letter:'🙎‍♀️',Tags:['🙎‍♀️','woman pouting','Woman Pouting','woman','Woman','pouting','Pouting']},
	{Letter:'🙎',Tags:['🙎','person pouting','Person Pouting','person','Person','pouting','Pouting']},
	{Letter:'🙎‍♂️',Tags:['🙎‍♂️','man pouting','Man Pouting','man','Man','pouting','Pouting']},
	{Letter:'🙍‍♀️',Tags:['🙍‍♀️','woman frowning','Woman Frowning','woman','Woman','frowning','Frowning']},
	{Letter:'🙍',Tags:['🙍','person frowning','Person Frowning','person','Person','frowning','Frowning']},
	{Letter:'🙍‍♂️',Tags:['🙍‍♂️','man frowning','Man Frowning','man','Man','frowning','Frowning']},
	{Letter:'💇‍♀️',Tags:['💇‍♀️','woman getting haircut','Woman Getting Haircut','woman','Woman','getting','Getting','haircut','Haircut']},
	{Letter:'💇',Tags:['💇','person getting haircut','Person Getting Haircut','person','Person','getting','Getting','haircut','Haircut']},
	{Letter:'💇‍♂️',Tags:['💇‍♂️','man getting haircut','Man Getting Haircut','man','Man','getting','Getting','haircut','Haircut']},
	{Letter:'💆‍♀️',Tags:['💆‍♀️','woman getting massage','Woman Getting Massage','woman','Woman','getting','Getting','massage','Massage']},
	{Letter:'💆',Tags:['💆','person getting massage','Person Getting Massage','person','Person','getting','Getting','massage','Massage']},
	{Letter:'💆‍♂️',Tags:['💆‍♂️','man getting massage','Man Getting Massage','man','Man','getting','Getting','massage','Massage']},
	{Letter:'🧖‍♀️',Tags:['🧖‍♀️','woman in steamy room','Woman In Steamy Room','woman','Woman','steamy','Steamy','room','Room']},
	{Letter:'🧖',Tags:['🧖','person in steamy room','Person In Steamy Room','person','Person','steamy','Steamy','room','Room']},
	{Letter:'🧖‍♂️',Tags:['🧖‍♂️','man in steamy room','Man In Steamy Room','man','Man','steamy','Steamy','room','Room']},
	{Letter:'💅',Tags:['💅','nail polish','Nail Polish','nail','Nail','polish','Polish']},
	{Letter:'🤳',Tags:['🤳','selfie','Selfie','selfie','Selfie']},
	{Letter:'💃',Tags:['💃','woman dancing','Woman Dancing','woman','Woman','dancing','Dancing']},
	{Letter:'🕺',Tags:['🕺','man dancing','Man Dancing','man','Man','dancing','Dancing']},
	{Letter:'👯‍♀️',Tags:['👯‍♀️','women with bunny ears','Women With Bunny Ears','women','Women','bunny','Bunny','ears','Ears']},
	{Letter:'👯',Tags:['👯','people with bunny ears','People With Bunny Ears','people','People','bunny','Bunny','ears','Ears']},
	{Letter:'👯‍♂️',Tags:['👯‍♂️','men with bunny ears','Men With Bunny Ears','men','Men','bunny','Bunny','ears','Ears']},
	{Letter:'🕴',Tags:['🕴','person in suit levitating','Person In Suit Levitating','person','Person','suit','Suit','levitating','Levitating']},
	{Letter:'👩‍🦽',Tags:['👩‍🦽','woman in manual wheelchair','Woman In Manual Wheelchair','woman','Woman','manual','Manual','wheelchair','Wheelchair']},
	{Letter:'🧑‍🦽',Tags:['🧑‍🦽','person in manual wheelchair','Person In Manual Wheelchair','person','Person','manual','Manual','wheelchair','Wheelchair']},
	{Letter:'👨‍🦽',Tags:['👨‍🦽','man in manual wheelchair','Man In Manual Wheelchair','man','Man','manual','Manual','wheelchair','Wheelchair']},
	{Letter:'👩‍🦼',Tags:['👩‍🦼','woman in motorized wheelchair','Woman In Motorized Wheelchair','woman','Woman','motorized','Motorized','wheelchair','Wheelchair']},
	{Letter:'🧑‍🦼',Tags:['🧑‍🦼','person in motorized wheelchair','Person In Motorized Wheelchair','person','Person','motorized','Motorized','wheelchair','Wheelchair']},
	{Letter:'👨‍🦼',Tags:['👨‍🦼','man in motorized wheelchair','Man In Motorized Wheelchair','man','Man','motorized','Motorized','wheelchair','Wheelchair']},
	{Letter:'🚶‍♀️',Tags:['🚶‍♀️','woman walking','Woman Walking','woman','Woman','walking','Walking','walk','Walk']},
	{Letter:'🚶',Tags:['🚶','person walking','Person Walking','person','Person','walking','Walking','walk','Walk']},
	{Letter:'🚶‍♂️',Tags:['🚶‍♂️','man walking','Man Walking','man','Man','walking','Walking','walk','Walk']},
	{Letter:'👩‍🦯',Tags:['👩‍🦯','woman with white cane','Woman With White Cane','woman','Woman','cane','Cane']},
	{Letter:'🧑‍🦯',Tags:['🧑‍🦯','person with white cane','Person With White Cane','person','Person','cane','Cane']},
	{Letter:'👨‍🦯',Tags:['👨‍🦯','man with white cane','Man With White Cane','man','Man','cane','Cane']},
	{Letter:'🧎‍♀️',Tags:['🧎‍♀️','woman kneeling','Woman Kneeling','woman','Woman','kneeling','Kneeling']},
	{Letter:'🧎',Tags:['🧎','person kneeling','Person Kneeling','person','Person','kneeling','Kneeling']},
	{Letter:'🧎‍♂️',Tags:['🧎‍♂️','man kneeling','Man Kneeling','man','Man','kneeling','Kneeling']},
	{Letter:'🏃‍♀️',Tags:['🏃‍♀️','woman running','Woman Running','woman','Woman','running','Running','run','Run']},
	{Letter:'🏃',Tags:['🏃','person running','Person Running','person','Person','running','Running','run','Run']},
	{Letter:'🏃‍♂️',Tags:['🏃‍♂️','man running','Man Running','man','Man','running','Running','run','Run']},
	{Letter:'🧍‍♀️',Tags:['🧍‍♀️','woman standing','Woman Standing','woman','Woman','standing','Standing']},
	{Letter:'🧍',Tags:['🧍','person standing','Person Standing','person','Person','standing','Standing']},
	{Letter:'🧍‍♂️',Tags:['🧍‍♂️','man standing','Man Standing','man','Man','standing','Standing']},
	{Letter:'👭',Tags:['👭','women holding hands','Women Holding Hands','women','Women','holding','Holding','hands','Hands','friends','Friends','couple','Couple']},
	{Letter:'🧑‍🤝‍🧑',Tags:['🧑‍🤝‍🧑','people holding hands','People Holding Hands','people','People','holding','Holding','hands','Hands','friends','Friends','couple','Couple']},
	{Letter:'👬',Tags:['👬','men holding hands','Men Holding Hands','men','Men','holding','Holding','hands','Hands','friends','Friends','couple','Couple']},
	{Letter:'👫',Tags:['👫','woman and man holding hands','Woman And Man Holding Hands','woman','Woman','and','And','man','Man','holding','Holding','hands','Hands','friends','Friends','couple','Couple']},
	{Letter:'👩‍❤️‍👩',Tags:['👩‍❤️‍👩','couple with heart: woman, woman','Couple With Heart: Woman, Woman','couple','Couple','love','Love','heart','Heart','woman','Woman','woman','Woman']},
	{Letter:'💑',Tags:['💑','couple with heart','Couple With Heart','couple','Couple','love','Love','heart','Heart']},
	{Letter:'👨‍❤️‍👨',Tags:['👨‍❤️‍👨','couple with heart: man, man','Couple With Heart: Man, Man','couple','Couple','love','Love','heart','Heart','man','Man','man','Man']},
	{Letter:'👩‍❤️‍👨',Tags:['👩‍❤️‍👨','couple with heart: woman, man','Couple With Heart: Woman, Man','couple','Couple','love','Love','heart','Heart','woman','Woman','man','Man']},
	{Letter:'👩‍❤️‍💋‍👩',Tags:['👩‍❤️‍💋‍👩','kiss: woman, woman','Kiss: Woman, Woman','kiss','Kiss','woman','Woman','woman','Woman','love','Love','couple','Couple','heart','Heart','','']},
	{Letter:'💏',Tags:['💏','kiss','Kiss','kiss','Kiss','love','Love','couple','Couple','heart','Heart','','']},
	{Letter:'👨‍❤️‍💋‍👨',Tags:['👨‍❤️‍💋‍👨','kiss: man, man','Kiss: Man, Man','kiss','Kiss','man','Man','man','Man','love','Love','couple','Couple','heart','Heart','','']},
	{Letter:'👩‍❤️‍💋‍👨',Tags:['👩‍❤️‍💋‍👨','kiss: woman, man','Kiss: Woman, Man','kiss','Kiss','woman','Woman','man','Man','love','Love','couple','Couple','heart','Heart','','']},
	{Letter:'👪',Tags:['👪','family','Family','family','Family']},
	{Letter:'👨‍👩‍👦',Tags:['👨‍👩‍👦','family: man, woman, boy','Family: Man, Woman, Boy','family','Family','man','Man','woman','Woman','boy','Boy']},
	{Letter:'👨‍👩‍👧',Tags:['👨‍👩‍👧','family: man, woman, girl','Family: Man, Woman, Girl','family','Family','man','Man','woman','Woman','girl','Girl']},
	{Letter:'👨‍👩‍👧‍👦',Tags:['👨‍👩‍👧‍👦','family: man, woman, girl, boy','Family: Man, Woman, Girl, Boy','family','Family','man','Man','woman','Woman','girl','Girl','boy','Boy']},
	{Letter:'👨‍👩‍👦‍👦',Tags:['👨‍👩‍👦‍👦','family: man, woman, boy, boy','Family: Man, Woman, Boy, Boy','family','Family','man','Man','woman','Woman','boy','Boy','boy','Boy']},
	{Letter:'👨‍👩‍👧‍👧',Tags:['👨‍👩‍👧‍👧','family: man, woman, girl, girl','Family: Man, Woman, Girl, Girl','family','Family','man','Man','woman','Woman','girl','Girl','girl','Girl']},
	{Letter:'👨‍👨‍👦',Tags:['👨‍👨‍👦','family: man, man, boy','Family: Man, Man, Boy','family','Family','man','Man','man','Man','boy','Boy']},
	{Letter:'👨‍👨‍👧',Tags:['👨‍👨‍👧','family: man, man, girl','Family: Man, Man, Girl','family','Family','man','Man','man','Man','girl','Girl']},
	{Letter:'👨‍👨‍👧‍👦',Tags:['👨‍👨‍👧‍👦','family: man, man, girl, boy','Family: Man, Man, Girl, Boy','family','Family','man','Man','man','Man','girl','Girl','boy','Boy']},
	{Letter:'👨‍👨‍👦‍👦',Tags:['👨‍👨‍👦‍👦','family: man, man, boy, boy','Family: Man, Man, Boy, Boy','family','Family','man','Man','man','Man','boy','Boy','boy','Boy']},
	{Letter:'👨‍👨‍👧‍👧',Tags:['👨‍👨‍👧‍👧','family: man, man, girl, girl','Family: Man, Man, Girl, Girl','family','Family','man','Man','man','Man','girl','Girl','girl','Girl']},
	{Letter:'👩‍👩‍👦',Tags:['👩‍👩‍👦','family: woman, woman, boy','Family: Woman, Woman, Boy','family','Family','woman','Woman','woman','Woman','boy','Boy']},
	{Letter:'👩‍👩‍👧',Tags:['👩‍👩‍👧','family: woman, woman, girl','Family: Woman, Woman, Girl','family','Family','woman','Woman','woman','Woman','girl','Girl']},
	{Letter:'👩‍👩‍👧‍👦',Tags:['👩‍👩‍👧‍👦','family: woman, woman, girl, boy','Family: Woman, Woman, Girl, Boy','family','Family','woman','Woman','woman','Woman','girl','Girl','boy','Boy']},
	{Letter:'👩‍👩‍👦‍👦',Tags:['👩‍👩‍👦‍👦','family: woman, woman, boy, boy','Family: Woman, Woman, Boy, Boy','family','Family','woman','Woman','woman','Woman','boy','Boy','boy','Boy']},
	{Letter:'👩‍👩‍👧‍👧',Tags:['👩‍👩‍👧‍👧','family: woman, woman, girl, girl','Family: Woman, Woman, Girl, Girl','family','Family','woman','Woman','woman','Woman','girl','Girl','girl','Girl']},
	{Letter:'👨‍👦',Tags:['👨‍👦','family: man, boy','Family: Man, Boy','family','Family','man','Man','boy','Boy']},
	{Letter:'👨‍👦‍👦',Tags:['👨‍👦‍👦','family: man, boy, boy','Family: Man, Boy, Boy','family','Family','man','Man','boy','Boy','boy','Boy']},
	{Letter:'👨‍👧',Tags:['👨‍👧','family: man, girl','Family: Man, Girl','family','Family','man','Man','girl','Girl']},
	{Letter:'👨‍👧‍👦',Tags:['👨‍👧‍👦','family: man, girl, boy','Family: Man, Girl, Boy','family','Family','man','Man','girl','Girl','boy','Boy']},
	{Letter:'👨‍👧‍👧',Tags:['👨‍👧‍👧','family: man, girl, girl','Family: Man, Girl, Girl','family','Family','man','Man','girl','Girl','girl','Girl']},
	{Letter:'👩‍👦',Tags:['👩‍👦','family: woman, boy','Family: Woman, Boy','family','Family','woman','Woman','boy','Boy']},
	{Letter:'👩‍👦‍👦',Tags:['👩‍👦‍👦','family: woman, boy, boy','Family: Woman, Boy, Boy','family','Family','woman','Woman','boy','Boy','boy','Boy']},
	{Letter:'👩‍👧',Tags:['👩‍👧','family: woman, girl','Family: Woman, Girl','family','Family','woman','Woman','girl','Girl']},
	{Letter:'👩‍👧‍👦',Tags:['👩‍👧‍👦','family: woman, girl, boy','Family: Woman, Girl, Boy','family','Family','woman','Woman','girl','Girl','boy','Boy']},
	{Letter:'👩‍👧‍👧',Tags:['👩‍👧‍👧','family: woman, girl, girl','Family: Woman, Girl, Girl','family','Family','woman','Woman','girl','Girl','girl','Girl']},
	{Letter:'🗣',Tags:['🗣','speaking head','Speaking Head','speaking','Speaking','head','Head']},
	{Letter:'👤',Tags:['👤','bust in silhouette','Bust In Silhouette','bust','Bust','silhouette','Silhouette']},
	{Letter:'👥',Tags:['👥','busts in silhouette','Busts In Silhouette','busts','Busts','silhouette','Silhouette']},
	{Letter:'🫂',Tags:['🫂','people hugging','People Hugging','people','People','hugging','Hugging']},
	{Letter:'🧳',Tags:['🧳','luggage','Luggage','luggage','Luggage']},
	{Letter:'🌂',Tags:['🌂','closed umbrella','Closed Umbrella','closed','Closed','umbrella','Umbrella']},
	{Letter:'☂️',Tags:['☂️','umbrella','Umbrella','umbrella','Umbrella']},
	{Letter:'🧵',Tags:['🧵','thread','Thread','thread','Thread']},
	{Letter:'🪡',Tags:['🪡','sewing needle','Sewing Needle','sewing','Sewing','needle','Needle']},
	{Letter:'🪢',Tags:['🪢','knot','Knot','knot','Knot']},
	{Letter:'🧶',Tags:['🧶','yarn','Yarn','yarn','Yarn']},
	{Letter:'👓',Tags:['👓','glasses','Glasses','glasses','Glasses']},
	{Letter:'🕶',Tags:['🕶','sunglasses','Sunglasses','sunglasses','Sunglasses']},
	{Letter:'🥽',Tags:['🥽','goggles','Goggles','goggles','Goggles']},
	{Letter:'🥼',Tags:['🥼','lab coat','Lab Coat','lab','Lab','coat','Coat']},
	{Letter:'🦺',Tags:['🦺','safety vest','Safety Vest','safety','Safety','vest','Vest']},
	{Letter:'👔',Tags:['👔','necktie','Necktie','necktie','Necktie']},
	{Letter:'👕',Tags:['👕','t-shirt','T-Shirt','t shirt','T Shirt','shirt','Shirt','t shirt','T Shirt']},
	{Letter:'👖',Tags:['👖','jeans','Jeans','jeans','Jeans']},
	{Letter:'🧣',Tags:['🧣','scarf','Scarf','scarf','Scarf']},
	{Letter:'🧤',Tags:['🧤','gloves','Gloves','gloves','Gloves']},
	{Letter:'🧥',Tags:['🧥','coat','Coat','coat','Coat']},
	{Letter:'🧦',Tags:['🧦','socks','Socks','socks','Socks']},
	{Letter:'👗',Tags:['👗','dress','Dress','dress','Dress']},
	{Letter:'👘',Tags:['👘','kimono','Kimono','kimono','Kimono']},
	{Letter:'🥻',Tags:['🥻','sari','Sari','sari','Sari']},
	{Letter:'🩴',Tags:['🩴','thong sandal','Thong Sandal','thong','Thong','sandal','Sandal']},
	{Letter:'🩱',Tags:['🩱','one-piece swimsuit','One-Piece Swimsuit','one piece','One Piece','swimsuit','Swimsuit']},
	{Letter:'🩲',Tags:['🩲','briefs','Briefs','briefs','Briefs']},
	{Letter:'🩳',Tags:['🩳','shorts','Shorts','shorts','Shorts']},
	{Letter:'👙',Tags:['👙','bikini','Bikini','bikini','Bikini']},
	{Letter:'👚',Tags:['👚','woman’s clothes','Woman’S Clothes','woman’s','Woman’S','clothes','Clothes']},
	{Letter:'👛',Tags:['👛','purse','Purse','purse','Purse']},
	{Letter:'👜',Tags:['👜','handbag','Handbag','handbag','Handbag']},
	{Letter:'👝',Tags:['👝','clutch bag','Clutch Bag','clutch','Clutch','bag','Bag']},
	{Letter:'🎒',Tags:['🎒','backpack','Backpack','backpack','Backpack']},
	{Letter:'👞',Tags:['👞','man’s shoe','Man’S Shoe','man’s','Man’S','shoe','Shoe']},
	{Letter:'👟',Tags:['👟','running shoe','Running Shoe','running','Running','shoe','Shoe']},
	{Letter:'🥾',Tags:['🥾','hiking boot','Hiking Boot','hiking','Hiking','boot','Boot']},
	{Letter:'🥿',Tags:['🥿','flat shoe','Flat Shoe','flat','Flat','shoe','Shoe']},
	{Letter:'👠',Tags:['👠','high-heeled shoe','High-Heeled Shoe','high heeled','High Heeled','shoe','Shoe']},
	{Letter:'👡',Tags:['👡','woman’s sandal','Woman’S Sandal','woman’s','Woman’S','sandal','Sandal']},
	{Letter:'🩰',Tags:['🩰','ballet shoes','Ballet Shoes','ballet','Ballet','shoes','Shoes']},
	{Letter:'👢',Tags:['👢','woman’s boot','Woman’S Boot','woman’s','Woman’S','boot','Boot']},
	{Letter:'👑',Tags:['👑','crown','Crown','crown','Crown']},
	{Letter:'👒',Tags:['👒','woman’s hat','Woman’S Hat','woman’s','Woman’S','hat','Hat']},
	{Letter:'🎩',Tags:['🎩','top hat','Top Hat','top','Top','hat','Hat']},
	{Letter:'🎓',Tags:['🎓','graduation cap','Graduation Cap','graduation','Graduation','cap','Cap']},
	{Letter:'🧢',Tags:['🧢','billed cap','Billed Cap','billed','Billed','cap','Cap']},
	{Letter:'⛑',Tags:['⛑','rescue worker’s helmet','Rescue Worker’S Helmet','rescue','Rescue','worker’s','Worker’S','helmet','Helmet']},
	{Letter:'🪖',Tags:['🪖','military helmet','Military Helmet','military','Military','helmet','Helmet']},
	{Letter:'💄',Tags:['💄','lipstick','Lipstick','lipstick','Lipstick']},
	{Letter:'💍',Tags:['💍','ring','Ring','ring','Ring']},
	{Letter:'💼',Tags:['💼','briefcase','Briefcase','briefcase','Briefcase']},
	{Letter:'🐶',Tags:['🐶','dog face','Dog Face','dog','Dog','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🐱',Tags:['🐱','cat face','Cat Face','cat','Cat','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🐭',Tags:['🐭','mouse face','Mouse Face','mouse','Mouse','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🐹',Tags:['🐹','hamster','Hamster','hamster','Hamster','animal','Animal','animals','Animals']},
	{Letter:'🐰',Tags:['🐰','rabbit face','Rabbit Face','rabbit','Rabbit','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🦊',Tags:['🦊','fox','Fox','fox','Fox','animal','Animal','animals','Animals']},
	{Letter:'🐻',Tags:['🐻','bear','Bear','bear','Bear','animal','Animal','animals','Animals']},
	{Letter:'🐼',Tags:['🐼','panda','Panda','panda','Panda','animal','Animal','animals','Animals']},
	{Letter:'🐻‍❄️',Tags:['🐻‍❄️','polar bear','Polar Bear','polar','Polar','bear','Bear','animal','Animal','animals','Animals']},
	{Letter:'🐨',Tags:['🐨','koala','Koala','koala','Koala','animal','Animal','animals','Animals']},
	{Letter:'🐯',Tags:['🐯','tiger face','Tiger Face','tiger','Tiger','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🦁',Tags:['🦁','lion','Lion','lion','Lion','animal','Animal','animals','Animals']},
	{Letter:'🐮',Tags:['🐮','cow face','Cow Face','cow','Cow','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🐷',Tags:['🐷','pig face','Pig Face','pig','Pig','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🐽',Tags:['🐽','pig nose','Pig Nose','pig','Pig','nose','Nose','animal','Animal','animals','Animals']},
	{Letter:'🐸',Tags:['🐸','frog','Frog','frog','Frog','animal','Animal','animals','Animals']},
	{Letter:'🐵',Tags:['🐵','monkey face','Monkey Face','monkey','Monkey','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🙈',Tags:['🙈','see-no-evil monkey','See-No-Evil Monkey','see no evil','See No Evil','monkey','Monkey','animal','Animal','animals','Animals']},
	{Letter:'🙉',Tags:['🙉','hear-no-evil monkey','Hear-No-Evil Monkey','hear no evil','Hear No Evil','monkey','Monkey','animal','Animal','animals','Animals']},
	{Letter:'🙊',Tags:['🙊','speak-no-evil monkey','Speak-No-Evil Monkey','speak no evil','Speak No Evil','monkey','Monkey','animal','Animal','animals','Animals']},
	{Letter:'🐒',Tags:['🐒','monkey','Monkey','monkey','Monkey','animal','Animal','animals','Animals']},
	{Letter:'🐔',Tags:['🐔','chicken','Chicken','chicken','Chicken','animal','Animal','animals','Animals']},
	{Letter:'🐧',Tags:['🐧','penguin','Penguin','penguin','Penguin','animal','Animal','animals','Animals']},
	{Letter:'🐦',Tags:['🐦','bird','Bird','bird','Bird','animal','Animal','animals','Animals']},
	{Letter:'🐤',Tags:['🐤','baby chick','Baby Chick','baby','Baby','chick','Chick','animal','Animal','animals','Animals']},
	{Letter:'🐣',Tags:['🐣','hatching chick','Hatching Chick','hatching','Hatching','chick','Chick','animal','Animal','animals','Animals']},
	{Letter:'🐥',Tags:['🐥','front-facing baby chick','Front-Facing Baby Chick','front facing','Front Facing','baby','Baby','chick','Chick','animal','Animal','animals','Animals']},
	{Letter:'🦆',Tags:['🦆','duck','Duck','duck','Duck','animal','Animal','animals','Animals']},
	{Letter:'🦅',Tags:['🦅','eagle','Eagle','eagle','Eagle','animal','Animal','animals','Animals']},
	{Letter:'🦉',Tags:['🦉','owl','Owl','owl','Owl','animal','Animal','animals','Animals']},
	{Letter:'🦇',Tags:['🦇','bat','Bat','bat','Bat','animal','Animal','animals','Animals']},
	{Letter:'🐺',Tags:['🐺','wolf','Wolf','wolf','Wolf','animal','Animal','animals','Animals']},
	{Letter:'🐗',Tags:['🐗','boar','Boar','boar','Boar','animal','Animal','animals','Animals']},
	{Letter:'🐴',Tags:['🐴','horse face','Horse Face','horse','Horse','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🦄',Tags:['🦄','unicorn','Unicorn','unicorn','Unicorn','animal','Animal','animals','Animals']},
	{Letter:'🐝',Tags:['🐝','honeybee','Honeybee','honeybee','Honeybee','animal','Animal','animals','Animals']},
	{Letter:'🪱',Tags:['🪱','worm','Worm','worm','Worm','animal','Animal','animals','Animals']},
	{Letter:'🐛',Tags:['🐛','bug','Bug','bug','Bug','animal','Animal','animals','Animals']},
	{Letter:'🦋',Tags:['🦋','butterfly','Butterfly','butterfly','Butterfly','animal','Animal','animals','Animals']},
	{Letter:'🐌',Tags:['🐌','snail','Snail','snail','Snail','animal','Animal','animals','Animals']},
	{Letter:'🐞',Tags:['🐞','lady beetle','Lady Beetle','lady','Lady','beetle','Beetle','animal','Animal','animals','Animals']},
	{Letter:'🐜',Tags:['🐜','ant','Ant','ant','Ant','animal','Animal','animals','Animals']},
	{Letter:'🪰',Tags:['🪰','fly','Fly','fly','Fly','animal','Animal','animals','Animals']},
	{Letter:'🪲',Tags:['🪲','beetle','Beetle','beetle','Beetle','animal','Animal','animals','Animals']},
	{Letter:'🪳',Tags:['🪳','cockroach','Cockroach','cockroach','Cockroach','animal','Animal','animals','Animals']},
	{Letter:'🦟',Tags:['🦟','mosquito','Mosquito','mosquito','Mosquito','animal','Animal','animals','Animals']},
	{Letter:'🦗',Tags:['🦗','cricket','Cricket','cricket','Cricket','animal','Animal','animals','Animals']},
	{Letter:'🕷',Tags:['🕷','spider','Spider','spider','Spider','animal','Animal','animals','Animals']},
	{Letter:'🕸',Tags:['🕸','spider web','Spider Web','spider','Spider','web','Web','animal','Animal','animals','Animals']},
	{Letter:'🦂',Tags:['🦂','scorpion','Scorpion','scorpion','Scorpion','animal','Animal','animals','Animals']},
	{Letter:'🐢',Tags:['🐢','turtle','Turtle','turtle','Turtle','animal','Animal','animals','Animals']},
	{Letter:'🐍',Tags:['🐍','snake','Snake','snake','Snake','animal','Animal','animals','Animals']},
	{Letter:'🦎',Tags:['🦎','lizard','Lizard','lizard','Lizard','animal','Animal','animals','Animals']},
	{Letter:'🦖',Tags:['🦖','T-Rex','T-Rex','T Rex','T Rex','animal','Animal','animals','Animals']},
	{Letter:'🦕',Tags:['🦕','sauropod','Sauropod','sauropod','Sauropod','animal','Animal','animals','Animals']},
	{Letter:'🐙',Tags:['🐙','octopus','Octopus','octopus','Octopus','animal','Animal','animals','Animals']},
	{Letter:'🦑',Tags:['🦑','squid','Squid','squid','Squid','animal','Animal','animals','Animals']},
	{Letter:'🦐',Tags:['🦐','shrimp','Shrimp','shrimp','Shrimp','animal','Animal','animals','Animals']},
	{Letter:'🦞',Tags:['🦞','lobster','Lobster','lobster','Lobster','animal','Animal','animals','Animals']},
	{Letter:'🦀',Tags:['🦀','crab','Crab','crab','Crab','animal','Animal','animals','Animals']},
	{Letter:'🐡',Tags:['🐡','blowfish','Blowfish','blowfish','Blowfish','animal','Animal','animals','Animals']},
	{Letter:'🐠',Tags:['🐠','tropical fish','Tropical Fish','tropical','Tropical','fish','Fish','animal','Animal','animals','Animals']},
	{Letter:'🐟',Tags:['🐟','fish','Fish','fish','Fish','animal','Animal','animals','Animals']},
	{Letter:'🐬',Tags:['🐬','dolphin','Dolphin','dolphin','Dolphin','animal','Animal','animals','Animals']},
	{Letter:'🐳',Tags:['🐳','spouting whale','Spouting Whale','spouting','Spouting','whale','Whale','animal','Animal','animals','Animals']},
	{Letter:'🐋',Tags:['🐋','whale','Whale','whale','Whale','animal','Animal','animals','Animals']},
	{Letter:'🦈',Tags:['🦈','shark','Shark','shark','Shark','animal','Animal','animals','Animals']},
	{Letter:'🐊',Tags:['🐊','crocodile','Crocodile','crocodile','Crocodile','animal','Animal','animals','Animals']},
	{Letter:'🐅',Tags:['🐅','tiger','Tiger','tiger','Tiger','animal','Animal','animals','Animals']},
	{Letter:'🐆',Tags:['🐆','leopard','Leopard','leopard','Leopard','animal','Animal','animals','Animals']},
	{Letter:'🦓',Tags:['🦓','zebra','Zebra','zebra','Zebra','animal','Animal','animals','Animals']},
	{Letter:'🦍',Tags:['🦍','gorilla','Gorilla','gorilla','Gorilla','animal','Animal','animals','Animals']},
	{Letter:'🦧',Tags:['🦧','orangutan','Orangutan','orangutan','Orangutan','animal','Animal','animals','Animals']},
	{Letter:'🦣',Tags:['🦣','mammoth','Mammoth','mammoth','Mammoth','animal','Animal','animals','Animals']},
	{Letter:'🐘',Tags:['🐘','elephant','Elephant','elephant','Elephant','animal','Animal','animals','Animals']},
	{Letter:'🦛',Tags:['🦛','hippopotamus','Hippopotamus','hippopotamus','Hippopotamus','animal','Animal','animals','Animals']},
	{Letter:'🦏',Tags:['🦏','rhinoceros','Rhinoceros','rhinoceros','Rhinoceros','animal','Animal','animals','Animals']},
	{Letter:'🐪',Tags:['🐪','camel','Camel','camel','Camel','animal','Animal','animals','Animals']},
	{Letter:'🐫',Tags:['🐫','two-hump camel','Two-Hump Camel','two hump','Two Hump','camel','Camel','animal','Animal','animals','Animals']},
	{Letter:'🦒',Tags:['🦒','giraffe','Giraffe','giraffe','Giraffe','animal','Animal','animals','Animals']},
	{Letter:'🦘',Tags:['🦘','kangaroo','Kangaroo','kangaroo','Kangaroo','animal','Animal','animals','Animals']},
	{Letter:'🦬',Tags:['🦬','bison','Bison','bison','Bison','animal','Animal','animals','Animals']},
	{Letter:'🐃',Tags:['🐃','water buffalo','Water Buffalo','water','Water','buffalo','Buffalo','animal','Animal','animals','Animals']},
	{Letter:'🐂',Tags:['🐂','ox','Ox','ox','Ox','animal','Animal','animals','Animals']},
	{Letter:'🐄',Tags:['🐄','cow','Cow','cow','Cow','animal','Animal','animals','Animals']},
	{Letter:'🐎',Tags:['🐎','horse','Horse','horse','Horse','animal','Animal','animals','Animals']},
	{Letter:'🐖',Tags:['🐖','pig','Pig','pig','Pig','animal','Animal','animals','Animals']},
	{Letter:'🐏',Tags:['🐏','ram','Ram','ram','Ram','animal','Animal','animals','Animals']},
	{Letter:'🐑',Tags:['🐑','ewe','Ewe','ewe','Ewe','animal','Animal','animals','Animals']},
	{Letter:'🦙',Tags:['🦙','llama','Llama','llama','Llama','animal','Animal','animals','Animals']},
	{Letter:'🐐',Tags:['🐐','goat','Goat','goat','Goat','animal','Animal','animals','Animals']},
	{Letter:'🦌',Tags:['🦌','deer','Deer','deer','Deer','animal','Animal','animals','Animals']},
	{Letter:'🐕',Tags:['🐕','dog','Dog','dog','Dog','animal','Animal','animals','Animals']},
	{Letter:'🐩',Tags:['🐩','poodle','Poodle','poodle','Poodle','animal','Animal','animals','Animals']},
	{Letter:'🦮',Tags:['🦮','guide dog','Guide Dog','guide','Guide','dog','Dog','animal','Animal','animals','Animals']},
	{Letter:'🐕‍🦺',Tags:['🐕‍🦺','service dog','Service Dog','service','Service','dog','Dog','animal','Animal','animals','Animals']},
	{Letter:'🐈',Tags:['🐈','cat','Cat','cat','Cat','animal','Animal','animals','Animals']},
	{Letter:'🐈‍⬛',Tags:['🐈‍⬛','black cat','Black Cat','black','Black','cat','Cat','animal','Animal','animals','Animals']},
	{Letter:'🪶',Tags:['🪶','feather','Feather','feather','Feather','animal','Animal','animals','Animals']},
	{Letter:'🐓',Tags:['🐓','rooster','Rooster','rooster','Rooster','animal','Animal','animals','Animals']},
	{Letter:'🦃',Tags:['🦃','turkey','Turkey','turkey','Turkey','animal','Animal','animals','Animals']},
	{Letter:'🦤',Tags:['🦤','dodo','Dodo','dodo','Dodo','animal','Animal','animals','Animals']},
	{Letter:'🦚',Tags:['🦚','peacock','Peacock','peacock','Peacock','animal','Animal','animals','Animals']},
	{Letter:'🦜',Tags:['🦜','parrot','Parrot','parrot','Parrot','animal','Animal','animals','Animals']},
	{Letter:'🦢',Tags:['🦢','swan','Swan','swan','Swan','animal','Animal','animals','Animals']},
	{Letter:'🦩',Tags:['🦩','flamingo','Flamingo','flamingo','Flamingo','animal','Animal','animals','Animals']},
	{Letter:'🕊',Tags:['🕊','dove','Dove','dove','Dove','animal','Animal','animals','Animals']},
	{Letter:'🐇',Tags:['🐇','rabbit','Rabbit','rabbit','Rabbit','animal','Animal','animals','Animals']},
	{Letter:'🦝',Tags:['🦝','raccoon','Raccoon','raccoon','Raccoon','animal','Animal','animals','Animals']},
	{Letter:'🦨',Tags:['🦨','skunk','Skunk','skunk','Skunk','animal','Animal','animals','Animals']},
	{Letter:'🦡',Tags:['🦡','badger','Badger','badger','Badger','animal','Animal','animals','Animals']},
	{Letter:'🦫',Tags:['🦫','beaver','Beaver','beaver','Beaver','animal','Animal','animals','Animals']},
	{Letter:'🦦',Tags:['🦦','otter','Otter','otter','Otter','animal','Animal','animals','Animals']},
	{Letter:'🦥',Tags:['🦥','sloth','Sloth','sloth','Sloth','animal','Animal','animals','Animals']},
	{Letter:'🐁',Tags:['🐁','mouse','Mouse','mouse','Mouse','animal','Animal','animals','Animals']},
	{Letter:'🐀',Tags:['🐀','rat','Rat','rat','Rat','animal','Animal','animals','Animals']},
	{Letter:'🐿',Tags:['🐿','chipmunk','Chipmunk','chipmunk','Chipmunk','animal','Animal','animals','Animals']},
	{Letter:'🦔',Tags:['🦔','hedgehog','Hedgehog','hedgehog','Hedgehog','animal','Animal','animals','Animals']},
	{Letter:'🐾',Tags:['🐾','paw prints','Paw Prints','paw','Paw','prints','Prints','animal','Animal','animals','Animals']},
	{Letter:'🐉',Tags:['🐉','dragon','Dragon','dragon','Dragon','animal','Animal','animals','Animals']},
	{Letter:'🐲',Tags:['🐲','dragon face','Dragon Face','dragon','Dragon','face','Face','animal','Animal','animals','Animals']},
	{Letter:'🌵',Tags:['🌵','cactus','Cactus','cactus','Cactus','nature','Nature']},
	{Letter:'🎄',Tags:['🎄','Christmas tree','Christmas Tree','Christmas','Christmas','tree','Tree','nature','Nature']},
	{Letter:'🌲',Tags:['🌲','evergreen tree','Evergreen Tree','evergreen','Evergreen','tree','Tree','nature','Nature']},
	{Letter:'🌳',Tags:['🌳','deciduous tree','Deciduous Tree','deciduous','Deciduous','tree','Tree','nature','Nature']},
	{Letter:'🌴',Tags:['🌴','palm tree','Palm Tree','palm','Palm','tree','Tree','nature','Nature']},
	{Letter:'🪵',Tags:['🪵','wood','Wood','wood','Wood','nature','Nature']},
	{Letter:'🌱',Tags:['🌱','seedling','Seedling','seedling','Seedling','nature','Nature']},
	{Letter:'🌿',Tags:['🌿','herb','Herb','herb','Herb','nature','Nature']},
	{Letter:'☘️',Tags:['☘️','shamrock','Shamrock','shamrock','Shamrock','nature','Nature']},
	{Letter:'🍀',Tags:['🍀','four leaf clover','Four Leaf Clover','four','Four','leaf','Leaf','clover','Clover','nature','Nature']},
	{Letter:'🎍',Tags:['🎍','pine decoration','Pine Decoration','pine','Pine','decoration','Decoration','nature','Nature']},
	{Letter:'🪴',Tags:['🪴','potted plant','Potted Plant','potted','Potted','plant','Plant','nature','Nature']},
	{Letter:'🎋',Tags:['🎋','tanabata tree','Tanabata Tree','tanabata','Tanabata','tree','Tree','nature','Nature']},
	{Letter:'🍃',Tags:['🍃','leaf fluttering in wind','Leaf Fluttering In Wind','leaf','Leaf','fluttering','Fluttering','wind','Wind','nature','Nature']},
	{Letter:'🍂',Tags:['🍂','fallen leaf','Fallen Leaf','fallen','Fallen','leaf','Leaf','nature','Nature']},
	{Letter:'🍁',Tags:['🍁','maple leaf','Maple Leaf','maple','Maple','leaf','Leaf','nature','Nature']},
	{Letter:'🍄',Tags:['🍄','mushroom','Mushroom','mushroom','Mushroom','nature','Nature']},
	{Letter:'🐚',Tags:['🐚','spiral shell','Spiral Shell','spiral','Spiral','shell','Shell','nature','Nature']},
	{Letter:'🪨',Tags:['🪨','rock','Rock','rock','Rock','nature','Nature']},
	{Letter:'🌾',Tags:['🌾','sheaf of rice','Sheaf Of Rice','sheaf','Sheaf','rice','Rice','nature','Nature']},
	{Letter:'💐',Tags:['💐','bouquet','Bouquet','bouquet','Bouquet','nature','Nature']},
	{Letter:'🌷',Tags:['🌷','tulip','Tulip','tulip','Tulip','nature','Nature']},
	{Letter:'🌹',Tags:['🌹','rose','Rose','rose','Rose','nature','Nature']},
	{Letter:'🥀',Tags:['🥀','wilted flower','Wilted Flower','wilted','Wilted','flower','Flower','nature','Nature']},
	{Letter:'🌺',Tags:['🌺','hibiscus','Hibiscus','hibiscus','Hibiscus','nature','Nature']},
	{Letter:'🌸',Tags:['🌸','cherry blossom','Cherry Blossom','cherry','Cherry','blossom','Blossom','nature','Nature']},
	{Letter:'🌼',Tags:['🌼','blossom','Blossom','blossom','Blossom','nature','Nature']},
	{Letter:'🌻',Tags:['🌻','sunflower','Sunflower','sunflower','Sunflower','nature','Nature']},
	{Letter:'🌞',Tags:['🌞','sun with face','Sun With Face','sun','Sun','face','Face','nature','Nature']},
	{Letter:'🌝',Tags:['🌝','full moon face','Full Moon Face','full','Full','moon','Moon','face','Face','nature','Nature']},
	{Letter:'🌛',Tags:['🌛','first quarter moon face','First Quarter Moon Face','first','First','quarter','Quarter','moon','Moon','face','Face','nature','Nature']},
	{Letter:'🌜',Tags:['🌜','last quarter moon face','Last Quarter Moon Face','last','Last','quarter','Quarter','moon','Moon','face','Face','nature','Nature']},
	{Letter:'🌚',Tags:['🌚','new moon face','New Moon Face','new','New','moon','Moon','face','Face','nature','Nature']},
	{Letter:'🌕',Tags:['🌕','full moon','Full Moon','full','Full','moon','Moon','nature','Nature']},
	{Letter:'🌖',Tags:['🌖','waning gibbous moon','Waning Gibbous Moon','waning','Waning','gibbous','Gibbous','moon','Moon','nature','Nature']},
	{Letter:'🌗',Tags:['🌗','last quarter moon','Last Quarter Moon','last','Last','quarter','Quarter','moon','Moon','nature','Nature']},
	{Letter:'🌘',Tags:['🌘','waning crescent moon','Waning Crescent Moon','waning','Waning','crescent','Crescent','moon','Moon','nature','Nature']},
	{Letter:'🌑',Tags:['🌑','new moon','New Moon','new','New','moon','Moon','nature','Nature']},
	{Letter:'🌒',Tags:['🌒','waxing crescent moon','Waxing Crescent Moon','waxing','Waxing','crescent','Crescent','moon','Moon','nature','Nature']},
	{Letter:'🌓',Tags:['🌓','first quarter moon','First Quarter Moon','first','First','quarter','Quarter','moon','Moon','nature','Nature']},
	{Letter:'🌔',Tags:['🌔','waxing gibbous moon','Waxing Gibbous Moon','waxing','Waxing','gibbous','Gibbous','moon','Moon','nature','Nature']},
	{Letter:'🌙',Tags:['🌙','crescent moon','Crescent Moon','crescent','Crescent','moon','Moon','nature','Nature']},
	{Letter:'🌎',Tags:['🌎','globe showing Americas','Globe Showing Americas','globe','Globe','showing','Showing','Americas','Americas','nature','Nature']},
	{Letter:'🌍',Tags:['🌍','globe showing Europe-Africa','Globe Showing Europe-Africa','globe','Globe','showing','Showing','Europe Africa','Europe Africa','nature','Nature']},
	{Letter:'🌏',Tags:['🌏','globe showing Asia-Australia','Globe Showing Asia-Australia','globe','Globe','showing','Showing','Asia Australia','Asia Australia','nature','Nature']},
	{Letter:'🪐',Tags:['🪐','ringed planet','Ringed Planet','ringed','Ringed','planet','Planet','nature','Nature']},
	{Letter:'💫',Tags:['💫','dizzy','Dizzy','dizzy','Dizzy']},
	{Letter:'⭐️',Tags:['⭐️','star','Star','star','Star']},
	{Letter:'🌟',Tags:['🌟','glowing star','Glowing Star','glowing','Glowing','star','Star']},
	{Letter:'✨',Tags:['✨','sparkles','Sparkles','sparkles','Sparkles']},
	{Letter:'⚡️',Tags:['⚡️','high voltage','High Voltage','high','High','voltage','Voltage']},
	{Letter:'☄️',Tags:['☄️','comet','Comet','comet','Comet']},
	{Letter:'💥',Tags:['💥','collision','Collision','collision','Collision']},
	{Letter:'🔥',Tags:['🔥','fire','Fire','fire','Fire']},
	{Letter:'🌪',Tags:['🌪','tornado','Tornado','tornado','Tornado']},
	{Letter:'🌈',Tags:['🌈','rainbow','Rainbow','rainbow','Rainbow','pride','Pride']},
	{Letter:'☀️',Tags:['☀️','sun','Sun','sun','Sun']},
	{Letter:'🌤',Tags:['🌤','sun behind small cloud','Sun Behind Small Cloud','sun','Sun','behind','Behind','small','Small','cloud','Cloud']},
	{Letter:'⛅️',Tags:['⛅️','sun behind cloud','Sun Behind Cloud','sun','Sun','behind','Behind','cloud','Cloud']},
	{Letter:'🌥',Tags:['🌥','sun behind large cloud','Sun Behind Large Cloud','sun','Sun','behind','Behind','large','Large','cloud','Cloud']},
	{Letter:'☁️',Tags:['☁️','cloud','Cloud','cloud','Cloud']},
	{Letter:'🌦',Tags:['🌦','sun behind rain cloud','Sun Behind Rain Cloud','sun','Sun','behind','Behind','rain','Rain','cloud','Cloud']},
	{Letter:'🌧',Tags:['🌧','cloud with rain','Cloud With Rain','cloud','Cloud','rain','Rain']},
	{Letter:'⛈',Tags:['⛈','cloud with lightning and rain','Cloud With Lightning And Rain','cloud','Cloud','lightning','Lightning','and','And','rain','Rain']},
	{Letter:'🌩',Tags:['🌩','cloud with lightning','Cloud With Lightning','cloud','Cloud','lightning','Lightning']},
	{Letter:'🌨',Tags:['🌨','cloud with snow','Cloud With Snow','cloud','Cloud','snow','Snow']},
	{Letter:'❄️',Tags:['❄️','snowflake','Snowflake','snowflake','Snowflake']},
	{Letter:'☃️',Tags:['☃️','snowman','Snowman','snowman','Snowman']},
	{Letter:'⛄️',Tags:['⛄️','snowman without snow','Snowman Without Snow','snowman','Snowman','without','Without','snow','Snow']},
	{Letter:'🌬',Tags:['🌬','wind face','Wind Face','wind','Wind','face','Face']},
	{Letter:'💨',Tags:['💨','dashing away','Dashing Away','dashing','Dashing','away','Away']},
	{Letter:'💧',Tags:['💧','droplet','Droplet','droplet','Droplet']},
	{Letter:'💦',Tags:['💦','sweat droplets','Sweat Droplets','sweat','Sweat','droplets','Droplets']},
	{Letter:'☔️',Tags:['☔️','umbrella with rain drops','Umbrella With Rain Drops','umbrella','Umbrella','rain','Rain','drops','Drops']},
	{Letter:'☂️',Tags:['☂️','umbrella','Umbrella','umbrella','Umbrella']},
	{Letter:'🌊',Tags:['🌊','water wave','Water Wave','water','Water','wave','Wave']},
	{Letter:'🌫',Tags:['🌫','fog','Fog','fog','Fog']},
	{Letter:'🍏',Tags:['🍏','green apple','Green Apple','green','Green','apple','Apple','food','Food']},
	{Letter:'🍎',Tags:['🍎','red apple','Red Apple','red','Red','apple','Apple','food','Food']},
	{Letter:'🍐',Tags:['🍐','pear','Pear','pear','Pear','food','Food']},
	{Letter:'🍊',Tags:['🍊','tangerine','Tangerine','tangerine','Tangerine','food','Food']},
	{Letter:'🍋',Tags:['🍋','lemon','Lemon','lemon','Lemon','food','Food']},
	{Letter:'🍌',Tags:['🍌','banana','Banana','banana','Banana','food','Food']},
	{Letter:'🍉',Tags:['🍉','watermelon','Watermelon','watermelon','Watermelon','food','Food']},
	{Letter:'🍇',Tags:['🍇','grapes','Grapes','grapes','Grapes','food','Food']},
	{Letter:'🍓',Tags:['🍓','strawberry','Strawberry','strawberry','Strawberry','food','Food']},
	{Letter:'🫐',Tags:['🫐','blueberries','Blueberries','blueberries','Blueberries','food','Food']},
	{Letter:'🍈',Tags:['🍈','melon','Melon','melon','Melon','food','Food']},
	{Letter:'🍒',Tags:['🍒','cherries','Cherries','cherries','Cherries','food','Food']},
	{Letter:'🍑',Tags:['🍑','peach','Peach','peach','Peach','food','Food']},
	{Letter:'🥭',Tags:['🥭','mango','Mango','mango','Mango','food','Food']},
	{Letter:'🍍',Tags:['🍍','pineapple','Pineapple','pineapple','Pineapple','food','Food']},
	{Letter:'🥥',Tags:['🥥','coconut','Coconut','coconut','Coconut','food','Food']},
	{Letter:'🥝',Tags:['🥝','kiwi fruit','Kiwi Fruit','kiwi','Kiwi','fruit','Fruit','food','Food']},
	{Letter:'🍅',Tags:['🍅','tomato','Tomato','tomato','Tomato','food','Food']},
	{Letter:'🍆',Tags:['🍆','eggplant','Eggplant','eggplant','Eggplant','food','Food']},
	{Letter:'🥑',Tags:['🥑','avocado','Avocado','avocado','Avocado','food','Food']},
	{Letter:'🥦',Tags:['🥦','broccoli','Broccoli','broccoli','Broccoli','food','Food']},
	{Letter:'🥬',Tags:['🥬','leafy green','Leafy Green','leafy','Leafy','green','Green','food','Food']},
	{Letter:'🥒',Tags:['🥒','cucumber','Cucumber','cucumber','Cucumber','food','Food']},
	{Letter:'🌶',Tags:['🌶','hot pepper','Hot Pepper','hot','Hot','pepper','Pepper','food','Food']},
	{Letter:'🫑',Tags:['🫑','bell pepper','Bell Pepper','bell','Bell','pepper','Pepper','food','Food']},
	{Letter:'🌽',Tags:['🌽','ear of corn','Ear Of Corn','ear','Ear','corn','Corn','food','Food']},
	{Letter:'🥕',Tags:['🥕','carrot','Carrot','carrot','Carrot','food','Food']},
	{Letter:'🫒',Tags:['🫒','olive','Olive','olive','Olive','food','Food']},
	{Letter:'🧄',Tags:['🧄','garlic','Garlic','garlic','Garlic','food','Food']},
	{Letter:'🧅',Tags:['🧅','onion','Onion','onion','Onion','food','Food']},
	{Letter:'🥔',Tags:['🥔','potato','Potato','potato','Potato','food','Food']},
	{Letter:'🍠',Tags:['🍠','roasted sweet potato','Roasted Sweet Potato','roasted','Roasted','sweet','Sweet','potato','Potato','food','Food']},
	{Letter:'🥐',Tags:['🥐','croissant','Croissant','croissant','Croissant','food','Food']},
	{Letter:'🥯',Tags:['🥯','bagel','Bagel','bagel','Bagel','food','Food']},
	{Letter:'🍞',Tags:['🍞','bread','Bread','bread','Bread','food','Food']},
	{Letter:'🥖',Tags:['🥖','baguette bread','Baguette Bread','baguette','Baguette','bread','Bread','food','Food']},
	{Letter:'🥨',Tags:['🥨','pretzel','Pretzel','pretzel','Pretzel','food','Food']},
	{Letter:'🧀',Tags:['🧀','cheese wedge','Cheese Wedge','cheese','Cheese','wedge','Wedge','food','Food']},
	{Letter:'🥚',Tags:['🥚','egg','Egg','egg','Egg','food','Food']},
	{Letter:'🍳',Tags:['🍳','cooking','Cooking','cooking','Cooking','food','Food']},
	{Letter:'🧈',Tags:['🧈','butter','Butter','butter','Butter','food','Food']},
	{Letter:'🥞',Tags:['🥞','pancakes','Pancakes','pancakes','Pancakes','food','Food']},
	{Letter:'🧇',Tags:['🧇','waffle','Waffle','waffle','Waffle','food','Food']},
	{Letter:'🥓',Tags:['🥓','bacon','Bacon','bacon','Bacon','food','Food']},
	{Letter:'🥩',Tags:['🥩','cut of meat','Cut Of Meat','cut','Cut','meat','Meat','food','Food']},
	{Letter:'🍗',Tags:['🍗','poultry leg','Poultry Leg','poultry','Poultry','leg','Leg','food','Food']},
	{Letter:'🍖',Tags:['🍖','meat on bone','Meat On Bone','meat','Meat','bone','Bone','food','Food']},
	{Letter:'🦴',Tags:['🦴','bone','Bone','bone','Bone','food','Food']},
	{Letter:'🌭',Tags:['🌭','hot dog','Hot Dog','hot','Hot','dog','Dog','food','Food']},
	{Letter:'🍔',Tags:['🍔','hamburger','Hamburger','hamburger','Hamburger','burger','Burger','cheeseburger','Cheeseburger','food','Food']},
	{Letter:'🍟',Tags:['🍟','french fries','French Fries','french','French','fries','Fries','food','Food']},
	{Letter:'🍕',Tags:['🍕','pizza','Pizza','pizza','Pizza','food','Food']},
	{Letter:'🫓',Tags:['🫓','flatbread','Flatbread','flatbread','Flatbread','food','Food']},
	{Letter:'🥪',Tags:['🥪','sandwich','Sandwich','sandwich','Sandwich','food','Food']},
	{Letter:'🥙',Tags:['🥙','stuffed flatbread','Stuffed Flatbread','stuffed','Stuffed','flatbread','Flatbread','food','Food']},
	{Letter:'🧆',Tags:['🧆','falafel','Falafel','falafel','Falafel','food','Food']},
	{Letter:'🌮',Tags:['🌮','taco','Taco','taco','Taco','food','Food']},
	{Letter:'🌯',Tags:['🌯','burrito','Burrito','burrito','Burrito','food','Food']},
	{Letter:'🫔',Tags:['🫔','tamale','Tamale','tamale','Tamale','food','Food']},
	{Letter:'🥗',Tags:['🥗','green salad','Green Salad','green','Green','salad','Salad','food','Food']},
	{Letter:'🥘',Tags:['🥘','shallow pan of food','Shallow Pan Of Food','shallow','Shallow','pan','Pan','food','Food']},
	{Letter:'🫕',Tags:['🫕','fondue','Fondue','fondue','Fondue','food','Food']},
	{Letter:'🥫',Tags:['🥫','canned food','Canned Food','canned','Canned','food','Food']},
	{Letter:'🍝',Tags:['🍝','spaghetti','Spaghetti','spaghetti','Spaghetti','food','Food']},
	{Letter:'🍜',Tags:['🍜','steaming bowl','Steaming Bowl','steaming','Steaming','bowl','Bowl','food','Food']},
	{Letter:'🍲',Tags:['🍲','pot of food','Pot Of Food','pot','Pot','food','Food']},
	{Letter:'🍛',Tags:['🍛','curry rice','Curry Rice','curry','Curry','rice','Rice','food','Food']},
	{Letter:'🍣',Tags:['🍣','sushi','Sushi','sushi','Sushi','food','Food']},
	{Letter:'🍱',Tags:['🍱','bento box','Bento Box','bento','Bento','box','Box','food','Food']},
	{Letter:'🥟',Tags:['🥟','dumpling','Dumpling','dumpling','Dumpling','food','Food']},
	{Letter:'🦪',Tags:['🦪','oyster','Oyster','oyster','Oyster','food','Food']},
	{Letter:'🍤',Tags:['🍤','fried shrimp','Fried Shrimp','fried','Fried','shrimp','Shrimp','food','Food']},
	{Letter:'🍙',Tags:['🍙','rice ball','Rice Ball','rice','Rice','ball','Ball','food','Food']},
	{Letter:'🍚',Tags:['🍚','cooked rice','Cooked Rice','cooked','Cooked','rice','Rice','food','Food']},
	{Letter:'🍘',Tags:['🍘','rice cracker','Rice Cracker','rice','Rice','cracker','Cracker','food','Food']},
	{Letter:'🍥',Tags:['🍥','fish cake with swirl','Fish Cake With Swirl','fish','Fish','cake','Cake','food','Food','swirl','Swirl']},
	{Letter:'🥠',Tags:['🥠','fortune cookie','Fortune Cookie','fortune','Fortune','cookie','Cookie','food','Food']},
	{Letter:'🥮',Tags:['🥮','moon cake','Moon Cake','moon','Moon','cake','Cake','food','Food']},
	{Letter:'🍢',Tags:['🍢','oden','Oden','oden','Oden','food','Food']},
	{Letter:'🍡',Tags:['🍡','dango','Dango','dango','Dango','food','Food']},
	{Letter:'🍧',Tags:['🍧','shaved ice','Shaved Ice','shaved','Shaved','ice','Ice','food','Food','desert','Desert']},
	{Letter:'🍨',Tags:['🍨','ice cream','Ice Cream','ice','Ice','cream','Cream','food','Food','desert','Desert']},
	{Letter:'🍦',Tags:['🍦','soft ice cream','Soft Ice Cream','soft','Soft','ice','Ice','cream','Cream','food','Food','desert','Desert']},
	{Letter:'🥧',Tags:['🥧','pie','Pie','pie','Pie','food','Food','desert','Desert']},
	{Letter:'🧁',Tags:['🧁','cupcake','Cupcake','cupcake','Cupcake','food','Food','desert','Desert']},
	{Letter:'🍰',Tags:['🍰','shortcake','Shortcake','shortcake','Shortcake','food','Food','desert','Desert']},
	{Letter:'🎂',Tags:['🎂','birthday cake','Birthday Cake','birthday','Birthday','cake','Cake','food','Food','desert','Desert']},
	{Letter:'🍮',Tags:['🍮','custard','Custard','custard','Custard','food','Food','desert','Desert']},
	{Letter:'🍭',Tags:['🍭','lollipop','Lollipop','lollipop','Lollipop','food','Food','desert','Desert']},
	{Letter:'🍬',Tags:['🍬','candy','Candy','candy','Candy','food','Food','desert','Desert']},
	{Letter:'🍫',Tags:['🍫','chocolate bar','Chocolate Bar','chocolate','Chocolate','bar','Bar','food','Food','desert','Desert']},
	{Letter:'🍿',Tags:['🍿','popcorn','Popcorn','popcorn','Popcorn','food','Food','desert','Desert']},
	{Letter:'🍩',Tags:['🍩','doughnut','Doughnut','doughnut','Doughnut','food','Food','desert','Desert']},
	{Letter:'🍪',Tags:['🍪','cookie','Cookie','cookie','Cookie','food','Food','desert','Desert']},
	{Letter:'🌰',Tags:['🌰','chestnut','Chestnut','chestnut','Chestnut','food','Food','desert','Desert']},
	{Letter:'🥜',Tags:['🥜','peanuts','Peanuts','peanuts','Peanuts','food','Food','desert','Desert']},
	{Letter:'🍯',Tags:['🍯','honey pot','Honey Pot','honey','Honey','pot','Pot','food','Food','desert','Desert']},
	{Letter:'🥛',Tags:['🥛','glass of milk','Glass Of Milk','glass','Glass','milk','Milk','drink','Drink']},
	{Letter:'🍼',Tags:['🍼','baby bottle','Baby Bottle','baby','Baby','bottle','Bottle','drink','Drink']},
	{Letter:'🫖',Tags:['🫖','teapot','Teapot','teapot','Teapot','drink','Drink']},
	{Letter:'☕️',Tags:['☕️','hot beverage','Hot Beverage','hot','Hot','beverage','Beverage','drink','Drink']},
	{Letter:'🍵',Tags:['🍵','teacup without handle','Teacup Without Handle','teacup','Teacup','without','Without','handle','Handle','drink','Drink']},
	{Letter:'🧃',Tags:['🧃','beverage box','Beverage Box','beverage','Beverage','box','Box','drink','Drink']},
	{Letter:'🥤',Tags:['🥤','cup with straw','Cup With Straw','cup','Cup','straw','Straw','drink','Drink']},
	{Letter:'🧋',Tags:['🧋','bubble tea','Bubble Tea','bubble','Bubble','tea','Tea','drink','Drink']},
	{Letter:'🍶',Tags:['🍶','sake','Sake','sake','Sake','drink','Drink']},
	{Letter:'🍺',Tags:['🍺','beer mug','Beer Mug','beer','Beer','mug','Mug','drink','Drink']},
	{Letter:'🍻',Tags:['🍻','clinking beer mugs','Clinking Beer Mugs','clinking','Clinking','beer','Beer','mugs','Mugs','drink','Drink']},
	{Letter:'🥂',Tags:['🥂','clinking glasses','Clinking Glasses','clinking','Clinking','glasses','Glasses','champagne','Champagne','drink','Drink','sparkling wine','Sparkling Wine','sparkling','Sparkling','','']},
	{Letter:'🍷',Tags:['🍷','wine glass','Wine Glass','wine','Wine','glass','Glass','drink','Drink']},
	{Letter:'🥃',Tags:['🥃','tumbler glass','Tumbler Glass','tumbler','Tumbler','glass','Glass','drink','Drink']},
	{Letter:'🍸',Tags:['🍸','cocktail glass','Cocktail Glass','cocktail','Cocktail','glass','Glass','drink','Drink']},
	{Letter:'🍹',Tags:['🍹','tropical drink','Tropical Drink','tropical','Tropical','drink','Drink','drink','Drink']},
	{Letter:'🧉',Tags:['🧉','mate','Mate','mate','Mate','drink','Drink']},
	{Letter:'🍾',Tags:['🍾','bottle with popping cork','Bottle With Popping Cork','bottle','Bottle','drink','Drink','popping','Popping','cork','Cork']},
	{Letter:'🧊',Tags:['🧊','ice','Ice','ice','Ice']},
	{Letter:'🥄',Tags:['🥄','spoon','Spoon','spoon','Spoon']},
	{Letter:'🍴',Tags:['🍴','fork and knife','Fork And Knife','fork','Fork','and','And','knife','Knife']},
	{Letter:'🍽',Tags:['🍽','fork and knife with plate','Fork And Knife With Plate','fork','Fork','and','And','knife','Knife','plate','Plate']},
	{Letter:'🥣',Tags:['🥣','bowl with spoon','Bowl With Spoon','bowl','Bowl','spoon','Spoon']},
	{Letter:'🥡',Tags:['🥡','takeout box','Takeout Box','takeout','Takeout','box','Box']},
	{Letter:'🥢',Tags:['🥢','chopsticks','Chopsticks','chopsticks','Chopsticks']},
	{Letter:'🧂',Tags:['🧂','salt','Salt','salt','Salt']},
	{Letter:'⚽️',Tags:['⚽️','soccer ball','Soccer Ball','soccer','Soccer','ball','Ball']},
	{Letter:'🏀',Tags:['🏀','basketball','Basketball','basketball','Basketball']},
	{Letter:'🏈',Tags:['🏈','american football','American Football','american','American','football','Football']},
	{Letter:'⚾️',Tags:['⚾️','baseball','Baseball','baseball','Baseball']},
	{Letter:'🥎',Tags:['🥎','softball','Softball','softball','Softball']},
	{Letter:'🎾',Tags:['🎾','tennis','Tennis','tennis','Tennis']},
	{Letter:'🏐',Tags:['🏐','volleyball','Volleyball','volleyball','Volleyball']},
	{Letter:'🏉',Tags:['🏉','rugby football','Rugby Football','rugby','Rugby','football','Football']},
	{Letter:'🥏',Tags:['🥏','flying disc','Flying Disc','flying','Flying','disc','Disc']},
	{Letter:'🎱',Tags:['🎱','pool 8 ball','Pool 8 Ball','pool','Pool','8','8','ball','Ball']},
	{Letter:'🪀',Tags:['🪀','yo-yo','Yo-Yo','yo yo','Yo Yo']},
	{Letter:'🏓',Tags:['🏓','ping pong','Ping Pong','ping','Ping','pong','Pong']},
	{Letter:'🏸',Tags:['🏸','badminton','Badminton','badminton','Badminton']},
	{Letter:'🏒',Tags:['🏒','ice hockey','Ice Hockey','ice','Ice','hockey','Hockey']},
	{Letter:'🏑',Tags:['🏑','field hockey','Field Hockey','field','Field','hockey','Hockey']},
	{Letter:'🥍',Tags:['🥍','lacrosse','Lacrosse','lacrosse','Lacrosse']},
	{Letter:'🏏',Tags:['🏏','cricket game','Cricket Game','cricket','Cricket','game','Game']},
	{Letter:'🪃',Tags:['🪃','boomerang','Boomerang','boomerang','Boomerang']},
	{Letter:'🥅',Tags:['🥅','goal net','Goal Net','goal','Goal','net','Net']},
	{Letter:'⛳️',Tags:['⛳️','flag in hole','Flag In Hole','flag','Flag','hole','Hole']},
	{Letter:'🪁',Tags:['🪁','kite','Kite','kite','Kite']},
	{Letter:'🏹',Tags:['🏹','bow and arrow','Bow And Arrow','bow','Bow','and','And','arrow','Arrow']},
	{Letter:'🎣',Tags:['🎣','fishing pole','Fishing Pole','fishing','Fishing','pole','Pole']},
	{Letter:'🤿',Tags:['🤿','diving mask','Diving Mask','diving','Diving','mask','Mask']},
	{Letter:'🥊',Tags:['🥊','boxing glove','Boxing Glove','boxing','Boxing','glove','Glove']},
	{Letter:'🥋',Tags:['🥋','martial arts uniform','Martial Arts Uniform','martial','Martial','arts','Arts','uniform','Uniform']},
	{Letter:'🎽',Tags:['🎽','running shirt','Running Shirt','running','Running','shirt','Shirt']},
	{Letter:'🛹',Tags:['🛹','skateboard','Skateboard','skateboard','Skateboard']},
	{Letter:'🛼',Tags:['🛼','roller skate','Roller Skate','roller','Roller','skate','Skate']},
	{Letter:'🛷',Tags:['🛷','sled','Sled','sled','Sled']},
	{Letter:'⛸',Tags:['⛸','ice skate','Ice Skate','ice','Ice','skate','Skate']},
	{Letter:'🥌',Tags:['🥌','curling stone','Curling Stone','curling','Curling','stone','Stone']},
	{Letter:'🎿',Tags:['🎿','skis','Skis','skis','Skis']},
	{Letter:'⛷',Tags:['⛷','skier','Skier','skier','Skier']},
	{Letter:'🏂',Tags:['🏂','snowboarder','Snowboarder','snowboarder','Snowboarder']},
	{Letter:'🪂',Tags:['🪂','parachute','Parachute','parachute','Parachute']},
	{Letter:'🏋️‍♀️',Tags:['🏋️‍♀️','woman lifting weights','Woman Lifting Weights','woman','Woman','lifting','Lifting','weights','Weights']},
	{Letter:'🏋️',Tags:['🏋️','person lifting weights','Person Lifting Weights','person','Person','lifting','Lifting','weights','Weights']},
	{Letter:'🏋️‍♂️',Tags:['🏋️‍♂️','man lifting weights','Man Lifting Weights','man','Man','lifting','Lifting','weights','Weights']},
	{Letter:'🤼‍♀️',Tags:['🤼‍♀️','women wrestling','Women Wrestling','women','Women','wrestling','Wrestling']},
	{Letter:'🤼',Tags:['🤼','people wrestling','People Wrestling','people','People','wrestling','Wrestling']},
	{Letter:'🤼‍♂️',Tags:['🤼‍♂️','men wrestling','Men Wrestling','men','Men','wrestling','Wrestling']},
	{Letter:'🤸‍♀️',Tags:['🤸‍♀️','woman cartwheeling','Woman Cartwheeling','woman','Woman','cartwheeling','Cartwheeling']},
	{Letter:'🤸',Tags:['🤸','person cartwheeling','Person Cartwheeling','person','Person','cartwheeling','Cartwheeling']},
	{Letter:'🤸‍♂️',Tags:['🤸‍♂️','man cartwheeling','Man Cartwheeling','man','Man','cartwheeling','Cartwheeling']},
	{Letter:'⛹️‍♀️',Tags:['⛹️‍♀️','woman bouncing ball','Woman Bouncing Ball','woman','Woman','bouncing','Bouncing','ball','Ball']},
	{Letter:'⛹️',Tags:['⛹️','person bouncing ball','Person Bouncing Ball','person','Person','bouncing','Bouncing','ball','Ball']},
	{Letter:'⛹️‍♂️',Tags:['⛹️‍♂️','man bouncing ball','Man Bouncing Ball','man','Man','bouncing','Bouncing','ball','Ball']},
	{Letter:'🤺',Tags:['🤺','person fencing','Person Fencing','person','Person','fencing','Fencing']},
	{Letter:'🤾‍♀️',Tags:['🤾‍♀️','woman playing handball','Woman Playing Handball','woman','Woman','playing','Playing','handball','Handball']},
	{Letter:'🤾',Tags:['🤾','person playing handball','Person Playing Handball','person','Person','playing','Playing','handball','Handball']},
	{Letter:'🤾‍♂️',Tags:['🤾‍♂️','man playing handball','Man Playing Handball','man','Man','playing','Playing','handball','Handball']},
	{Letter:'🏌️‍♀️',Tags:['🏌️‍♀️','woman golfing','Woman Golfing','woman','Woman','golfing','Golfing']},
	{Letter:'🏌️',Tags:['🏌️','person golfing','Person Golfing','person','Person','golfing','Golfing']},
	{Letter:'🏌️‍♂️',Tags:['🏌️‍♂️','man golfing','Man Golfing','man','Man','golfing','Golfing']},
	{Letter:'🏇',Tags:['🏇','horse racing','Horse Racing','horse','Horse','racing','Racing']},
	{Letter:'🧘‍♀️',Tags:['🧘‍♀️','woman in lotus position','Woman In Lotus Position','woman','Woman','lotus','Lotus','position','Position']},
	{Letter:'🧘',Tags:['🧘','person in lotus position','Person In Lotus Position','person','Person','lotus','Lotus','position','Position']},
	{Letter:'🧘‍♂️',Tags:['🧘‍♂️','man in lotus position','Man In Lotus Position','man','Man','lotus','Lotus','position','Position']},
	{Letter:'🏄‍♀️',Tags:['🏄‍♀️','woman surfing','Woman Surfing','woman','Woman','surfing','Surfing','surf','Surf']},
	{Letter:'🏄',Tags:['🏄','person surfing','Person Surfing','person','Person','surfing','Surfing','surf','Surf']},
	{Letter:'🏄‍♂️',Tags:['🏄‍♂️','man surfing','Man Surfing','man','Man','surfing','Surfing','surf','Surf']},
	{Letter:'🏊‍♀️',Tags:['🏊‍♀️','woman swimming','Woman Swimming','woman','Woman','swimming','Swimming','swim','Swim']},
	{Letter:'🏊',Tags:['🏊','person swimming','Person Swimming','person','Person','swimming','Swimming','swim','Swim']},
	{Letter:'🏊‍♂️',Tags:['🏊‍♂️','man swimming','Man Swimming','man','Man','swimming','Swimming','swim','Swim']},
	{Letter:'🤽‍♀️',Tags:['🤽‍♀️','woman playing water polo','Woman Playing Water Polo','woman','Woman','playing','Playing','water','Water','polo','Polo']},
	{Letter:'🤽',Tags:['🤽','person playing water polo','Person Playing Water Polo','person','Person','playing','Playing','water','Water','polo','Polo']},
	{Letter:'🤽‍♂️',Tags:['🤽‍♂️','man playing water polo','Man Playing Water Polo','man','Man','playing','Playing','water','Water','polo','Polo']},
	{Letter:'🚣‍♀️',Tags:['🚣‍♀️','woman rowing boat','Woman Rowing Boat','woman','Woman','rowing','Rowing','boat','Boat']},
	{Letter:'🚣',Tags:['🚣','person rowing boat','Person Rowing Boat','person','Person','rowing','Rowing','boat','Boat']},
	{Letter:'🚣‍♂️',Tags:['🚣‍♂️','man rowing boat','Man Rowing Boat','man','Man','rowing','Rowing','boat','Boat']},
	{Letter:'🧗‍♀️',Tags:['🧗‍♀️','woman climbing','Woman Climbing','woman','Woman','climbing','Climbing']},
	{Letter:'🧗',Tags:['🧗','person climbing','Person Climbing','person','Person','climbing','Climbing']},
	{Letter:'🧗‍♂️',Tags:['🧗‍♂️','man climbing','Man Climbing','man','Man','climbing','Climbing']},
	{Letter:'🚵‍♀️',Tags:['🚵‍♀️','woman mountain biking','Woman Mountain Biking','woman','Woman','mountain','Mountain','biking','Biking']},
	{Letter:'🚵',Tags:['🚵','person mountain biking','Person Mountain Biking','person','Person','mountain','Mountain','biking','Biking']},
	{Letter:'🚵‍♂️',Tags:['🚵‍♂️','man mountain biking','Man Mountain Biking','man','Man','mountain','Mountain','biking','Biking']},
	{Letter:'🚴‍♀️',Tags:['🚴‍♀️','woman biking','Woman Biking','woman','Woman','biking','Biking']},
	{Letter:'🚴',Tags:['🚴','person biking','Person Biking','person','Person','biking','Biking']},
	{Letter:'🚴‍♂️',Tags:['🚴‍♂️','man biking','Man Biking','man','Man','biking','Biking']},
	{Letter:'🏆',Tags:['🏆','trophy','Trophy','trophy','Trophy']},
	{Letter:'🥇',Tags:['🥇','1st place medal','1St Place Medal','1st','1St','place','Place','medal','Medal']},
	{Letter:'🥈',Tags:['🥈','2nd place medal','2Nd Place Medal','2nd','2Nd','place','Place','medal','Medal']},
	{Letter:'🥉',Tags:['🥉','3rd place medal','3Rd Place Medal','3rd','3Rd','place','Place','medal','Medal']},
	{Letter:'🏅',Tags:['🏅','sports medal','Sports Medal','sports','Sports','medal','Medal']},
	{Letter:'🎖',Tags:['🎖','military medal','Military Medal','military','Military','medal','Medal']},
	{Letter:'🏵',Tags:['🏵','rosette','Rosette','rosette','Rosette']},
	{Letter:'🎗',Tags:['🎗','reminder ribbon','Reminder Ribbon','reminder','Reminder','ribbon','Ribbon']},
	{Letter:'🎫',Tags:['🎫','ticket','Ticket','ticket','Ticket']},
	{Letter:'🎟',Tags:['🎟','admission tickets','Admission Tickets','admission','Admission','tickets','Tickets']},
	{Letter:'🎪',Tags:['🎪','circus tent','Circus Tent','circus','Circus','tent','Tent']},
	{Letter:'🤹',Tags:['🤹','person juggling','Person Juggling','person','Person','juggling','Juggling']},
	{Letter:'🤹‍♂️',Tags:['🤹‍♂️','man juggling','Man Juggling','man','Man','juggling','Juggling']},
	{Letter:'🤹‍♀️',Tags:['🤹‍♀️','woman juggling','Woman Juggling','woman','Woman','juggling','Juggling']},
	{Letter:'🎭',Tags:['🎭','performing arts','Performing Arts','performing','Performing','arts','Arts']},
	{Letter:'🩰',Tags:['🩰','ballet shoes','Ballet Shoes','ballet','Ballet','shoes','Shoes']},
	{Letter:'🎨',Tags:['🎨','artist palette','Artist Palette','artist','Artist','palette','Palette','color','Color','paint','Paint','painting','Painting']},
	{Letter:'🎬',Tags:['🎬','clapper board','Clapper Board','clapper','Clapper','board','Board','video','Video','movie','Movie']},
	{Letter:'🎤',Tags:['🎤','microphone','Microphone','microphone','Microphone','instrument','Instrument']},
	{Letter:'🎧',Tags:['🎧','headphone','Headphone','headphone','Headphone']},
	{Letter:'🎼',Tags:['🎼','musical score','Musical Score','musical','Musical','score','Score','instrument','Instrument']},
	{Letter:'🎹',Tags:['🎹','musical keyboard','Musical Keyboard','musical','Musical','keyboard','Keyboard','instrument','Instrument']},
	{Letter:'🥁',Tags:['🥁','drum','Drum','drum','Drum','instrument','Instrument']},
	{Letter:'🪘',Tags:['🪘','long drum','Long Drum','long','Long','drum','Drum','instrument','Instrument']},
	{Letter:'🎷',Tags:['🎷','saxophone','Saxophone','saxophone','Saxophone','instrument','Instrument']},
	{Letter:'🎺',Tags:['🎺','trumpet','Trumpet','trumpet','Trumpet','instrument','Instrument']},
	{Letter:'🪗',Tags:['🪗','accordion','Accordion','accordion','Accordion','instrument','Instrument']},
	{Letter:'🎸',Tags:['🎸','guitar','Guitar','guitar','Guitar','instrument','Instrument']},
	{Letter:'🪕',Tags:['🪕','banjo','Banjo','banjo','Banjo','instrument','Instrument']},
	{Letter:'🎻',Tags:['🎻','violin','Violin','violin','Violin','instrument','Instrument']},
	{Letter:'🎲',Tags:['🎲','game die','Game Die','game','Game','dice','Dice']},
	{Letter:'♟',Tags:['♟','chess pawn','Chess Pawn','chess','Chess','pawn','Pawn']},
	{Letter:'🎯',Tags:['🎯','direct hit','Direct Hit','direct','Direct','hit','Hit']},
	{Letter:'🎳',Tags:['🎳','bowling','Bowling','bowling','Bowling']},
	{Letter:'🎮',Tags:['🎮','video game','Video Game','video','Video','game','Game','gaming','Gaming','controller','Controller','gamecontroller','Gamecontroller']},
	{Letter:'🎰',Tags:['🎰','slot machine','Slot Machine','slot','Slot','machine','Machine']},
	{Letter:'🧩',Tags:['🧩','puzzle piece','Puzzle Piece','puzzle','Puzzle','piece','Piece']},
	{Letter:'🚗',Tags:['🚗','automobile','Automobile','automobile','Automobile','car','Car']},
	{Letter:'🚕',Tags:['🚕','taxi','Taxi','taxi','Taxi','car','Car']},
	{Letter:'🚙',Tags:['🚙','sport utility vehicle','Sport Utility Vehicle','sport','Sport','utility','Utility','vehicle','Vehicle']},
	{Letter:'🚌',Tags:['🚌','bus','Bus','bus','Bus','car','Car']},
	{Letter:'🚎',Tags:['🚎','trolleybus','Trolleybus','trolleybus','Trolleybus','bus','Bus','car','Car']},
	{Letter:'🏎',Tags:['🏎','racing car','Racing Car','racing','Racing','car','Car']},
	{Letter:'🚓',Tags:['🚓','police car','Police Car','police','Police','car','Car']},
	{Letter:'🚑',Tags:['🚑','ambulance','Ambulance','ambulance','Ambulance','car','Car']},
	{Letter:'🚒',Tags:['🚒','fire engine','Fire Engine','fire','Fire','engine','Engine','car','Car']},
	{Letter:'🚐',Tags:['🚐','minibus','Minibus','minibus','Minibus','bus','Bus','car','Car']},
	{Letter:'🛻',Tags:['🛻','pickup truck','Pickup Truck','pickup','Pickup','truck','Truck','car','Car']},
	{Letter:'🚚',Tags:['🚚','delivery truck','Delivery Truck','delivery','Delivery','truck','Truck','car','Car']},
	{Letter:'🚛',Tags:['🚛','articulated lorry','Articulated Lorry','articulated','Articulated','lorry','Lorry','truck','Truck','car','Car']},
	{Letter:'🚜',Tags:['🚜','tractor','Tractor','tractor','Tractor','car','Car']},
	{Letter:'🦯',Tags:['🦯','white cane','White Cane','white','White','cane','Cane']},
	{Letter:'🦽',Tags:['🦽','manual wheelchair','Manual Wheelchair','manual','Manual','wheelchair','Wheelchair']},
	{Letter:'🦼',Tags:['🦼','motorized wheelchair','Motorized Wheelchair','motorized','Motorized','wheelchair','Wheelchair']},
	{Letter:'🛴',Tags:['🛴','kick scooter','Kick Scooter','kick','Kick','scooter','Scooter']},
	{Letter:'🚲',Tags:['🚲','bicycle','Bicycle','bicycle','Bicycle','bike','Bike']},
	{Letter:'🛵',Tags:['🛵','motor scooter','Motor Scooter','motor','Motor','scooter','Scooter','motorcycle','Motorcycle']},
	{Letter:'🏍',Tags:['🏍','motorcycle','Motorcycle','motorcycle','Motorcycle']},
	{Letter:'🛺',Tags:['🛺','auto rickshaw','Auto Rickshaw','auto','Auto','rickshaw','Rickshaw','car','Car']},
	{Letter:'🚨',Tags:['🚨','police car light','Police Car Light','police','Police','light','Light']},
	{Letter:'🚔',Tags:['🚔','oncoming police car','Oncoming Police Car','oncoming','Oncoming','police','Police','car','Car']},
	{Letter:'🚍',Tags:['🚍','oncoming bus','Oncoming Bus','oncoming','Oncoming','bus','Bus','car','Car']},
	{Letter:'🚘',Tags:['🚘','oncoming automobile','Oncoming Automobile','oncoming','Oncoming','automobile','Automobile','car','Car']},
	{Letter:'🚖',Tags:['🚖','oncoming taxi','Oncoming Taxi','oncoming','Oncoming','taxi','Taxi','car','Car','automobile','Automobile']},
	{Letter:'🚡',Tags:['🚡','aerial tramway','Aerial Tramway','aerial','Aerial','tramway','Tramway','railway','Railway','train','Train']},
	{Letter:'🚠',Tags:['🚠','mountain cableway','Mountain Cableway','mountain','Mountain','cableway','Cableway','railway','Railway','train','Train']},
	{Letter:'🚟',Tags:['🚟','suspension railway','Suspension Railway','suspension','Suspension','railway','Railway','train','Train']},
	{Letter:'🚃',Tags:['🚃','railway car','Railway Car','railway','Railway','train','Train']},
	{Letter:'🚋',Tags:['🚋','tram car','Tram Car','tram','Tram','train','Train']},
	{Letter:'🚞',Tags:['🚞','mountain railway','Mountain Railway','mountain','Mountain','railway','Railway','train','Train']},
	{Letter:'🚝',Tags:['🚝','monorail','Monorail','monorail','Monorail','train','Train']},
	{Letter:'🚄',Tags:['🚄','high-speed train','High-Speed Train','high speed','High Speed','train','Train']},
	{Letter:'🚅',Tags:['🚅','bullet train','Bullet Train','bullet','Bullet','train','Train']},
	{Letter:'🚈',Tags:['🚈','light rail','Light Rail','light','Light','rail','Rail','train','Train']},
	{Letter:'🚂',Tags:['🚂','locomotive','Locomotive','locomotive','Locomotive','train','Train']},
	{Letter:'🚆',Tags:['🚆','train','Train','train','Train']},
	{Letter:'🚇',Tags:['🚇','metro','Metro','metro','Metro','train','Train']},
	{Letter:'🚊',Tags:['🚊','tram','Tram','tram','Tram','train','Train']},
	{Letter:'🚉',Tags:['🚉','station','Station','station','Station','train','Train']},
	{Letter:'✈️',Tags:['✈️','airplane','Airplane','airplane','Airplane','plane','Plane']},
	{Letter:'🛫',Tags:['🛫','airplane departure','Airplane Departure','airplane','Airplane','departure','Departure','plane','Plane']},
	{Letter:'🛬',Tags:['🛬','airplane arrival','Airplane Arrival','airplane','Airplane','arrival','Arrival','plane','Plane']},
	{Letter:'🛩',Tags:['🛩','small airplane','Small Airplane','small','Small','airplane','Airplane','plane','Plane']},
	{Letter:'💺',Tags:['💺','seat','Seat','seat','Seat']},
	{Letter:'🛰',Tags:['🛰','satellite','Satellite','satellite','Satellite']},
	{Letter:'🚀',Tags:['🚀','rocket','Rocket','rocket','Rocket','flying','Flying','fly','Fly']},
	{Letter:'🛸',Tags:['🛸','flying saucer','Flying Saucer','flying','Flying','saucer','Saucer','fly','Fly']},
	{Letter:'🚁',Tags:['🚁','helicopter','Helicopter','helicopter','Helicopter','flying','Flying','fly','Fly']},
	{Letter:'🛶',Tags:['🛶','canoe','Canoe','canoe','Canoe','ship','Ship','boat','Boat']},
	{Letter:'⛵️',Tags:['⛵️','sailboat','Sailboat','sailboat','Sailboat','ship','Ship','boat','Boat']},
	{Letter:'🚤',Tags:['🚤','speedboat','Speedboat','speedboat','Speedboat','ship','Ship','boat','Boat']},
	{Letter:'🛥',Tags:['🛥','motor boat','Motor Boat','motor','Motor','boat','Boat','ship','Ship']},
	{Letter:'🛳',Tags:['🛳','passenger ship','Passenger Ship','passenger','Passenger','ship','Ship','boat','Boat']},
	{Letter:'⛴',Tags:['⛴','ferry','Ferry','ferry','Ferry','ship','Ship','boat','Boat']},
	{Letter:'🚢',Tags:['🚢','ship','Ship','ship','Ship','boat','Boat']},
	{Letter:'⚓️',Tags:['⚓️','anchor','Anchor','anchor','Anchor']},
	{Letter:'🪝',Tags:['🪝','hook','Hook','hook','Hook']},
	{Letter:'⛽️',Tags:['⛽️','fuel pump','Fuel Pump','fuel','Fuel','pump','Pump']},
	{Letter:'🚧',Tags:['🚧','construction','Construction','construction','Construction']},
	{Letter:'🚦',Tags:['🚦','vertical traffic light','Vertical Traffic Light','vertical','Vertical','traffic','Traffic','light','Light','traffic light','Traffic Light']},
	{Letter:'🚥',Tags:['🚥','horizontal traffic light','Horizontal Traffic Light','horizontal','Horizontal','traffic','Traffic','light','Light','traffic light','Traffic Light']},
	{Letter:'🚏',Tags:['🚏','bus stop','Bus Stop','bus','Bus','stop','Stop']},
	{Letter:'🗺',Tags:['🗺','world map','World Map','world','World','map','Map']},
	{Letter:'🗿',Tags:['🗿','moai','Moai','moai','Moai']},
	{Letter:'🗽',Tags:['🗽','Statue of Liberty','Statue Of Liberty','Statue','Statue','Liberty','Liberty']},
	{Letter:'🗼',Tags:['🗼','Tokyo tower','Tokyo Tower','Tokyo','Tokyo','tower','Tower']},
	{Letter:'🏰',Tags:['🏰','castle','Castle','castle','Castle']},
	{Letter:'🏯',Tags:['🏯','Japanese castle','Japanese Castle','Japanese','Japanese','castle','Castle']},
	{Letter:'🏟',Tags:['🏟','stadium','Stadium','stadium','Stadium']},
	{Letter:'🎡',Tags:['🎡','ferris wheel','Ferris Wheel','ferris','Ferris','wheel','Wheel']},
	{Letter:'🎢',Tags:['🎢','roller coaster','Roller Coaster','roller','Roller','coaster','Coaster']},
	{Letter:'🎠',Tags:['🎠','carousel horse','Carousel Horse','carousel','Carousel','horse','Horse']},
	{Letter:'⛲️',Tags:['⛲️','fountain','Fountain','fountain','Fountain']},
	{Letter:'⛱',Tags:['⛱','umbrella on ground','Umbrella On Ground','umbrella','Umbrella']},
	{Letter:'🏖',Tags:['🏖','beach with umbrella','Beach With Umbrella','beach','Beach','umbrella','Umbrella']},
	{Letter:'🏝',Tags:['🏝','desert island','Desert Island','desert','Desert','island','Island','palm','Palm','beach','Beach']},
	{Letter:'🏜',Tags:['🏜','desert','Desert','desert','Desert']},
	{Letter:'🌋',Tags:['🌋','volcano','Volcano','volcano','Volcano','mountain','Mountain']},
	{Letter:'⛰',Tags:['⛰','mountain','Mountain','mountain','Mountain']},
	{Letter:'🏔',Tags:['🏔','snow-capped mountain','Snow-Capped Mountain','snow capped','Snow Capped','mountain','Mountain']},
	{Letter:'🗻',Tags:['🗻','mount fuji','Mount Fuji','mount','Mount','fuji','Fuji','mountain','Mountain']},
	{Letter:'🏕',Tags:['🏕','camping','Camping','camping','Camping']},
	{Letter:'⛺️',Tags:['⛺️','tent','Tent','tent','Tent']},
	{Letter:'🛖',Tags:['🛖','hut','Hut','hut','Hut']},
	{Letter:'🏠',Tags:['🏠','house','House','house','House']},
	{Letter:'🏡',Tags:['🏡','house with garden','House With Garden','house','House','garden','Garden']},
	{Letter:'🏘',Tags:['🏘','houses','Houses','houses','Houses','house','House']},
	{Letter:'🏚',Tags:['🏚','derelict house','Derelict House','derelict','Derelict','house','House']},
	{Letter:'🏗',Tags:['🏗','building construction','Building Construction','building','Building','construction','Construction']},
	{Letter:'🏭',Tags:['🏭','factory','Factory','factory','Factory']},
	{Letter:'🏢',Tags:['🏢','office building','Office Building','office','Office','building','Building']},
	{Letter:'🏬',Tags:['🏬','department store','Department Store','department','Department','store','Store']},
	{Letter:'🏣',Tags:['🏣','Japanese post office','Japanese Post Office','Japanese','Japanese','post','Post','office','Office']},
	{Letter:'🏤',Tags:['🏤','post office','Post Office','post','Post','office','Office']},
	{Letter:'🏥',Tags:['🏥','hospital','Hospital','hospital','Hospital']},
	{Letter:'🏦',Tags:['🏦','bank','Bank','bank','Bank']},
	{Letter:'🏨',Tags:['🏨','hotel','Hotel','hotel','Hotel']},
	{Letter:'🏪',Tags:['🏪','convenience store','Convenience Store','convenience','Convenience','store','Store']},
	{Letter:'🏫',Tags:['🏫','school','School','school','School']},
	{Letter:'🏩',Tags:['🏩','love hotel','Love Hotel','love','Love','hotel','Hotel']},
	{Letter:'💒',Tags:['💒','wedding','Wedding','wedding','Wedding','love','Love']},
	{Letter:'🏛',Tags:['🏛','classical building','Classical Building','classical','Classical','building','Building']},
	{Letter:'⛪️',Tags:['⛪️','church','Church','church','Church']},
	{Letter:'🕌',Tags:['🕌','mosque','Mosque','mosque','Mosque']},
	{Letter:'🕍',Tags:['🕍','synagogue','Synagogue','synagogue','Synagogue']},
	{Letter:'🛕',Tags:['🛕','hindu temple','Hindu Temple','hindu','Hindu','temple','Temple']},
	{Letter:'🕋',Tags:['🕋','kaaba','Kaaba','kaaba','Kaaba']},
	{Letter:'⛩',Tags:['⛩','shinto shrine','Shinto Shrine','shinto','Shinto','shrine','Shrine']},
	{Letter:'🛤',Tags:['🛤','railway track','Railway Track','railway','Railway','track','Track']},
	{Letter:'🛣',Tags:['🛣','motorway','Motorway','motorway','Motorway']},
	{Letter:'🗾',Tags:['🗾','map of Japan','Map Of Japan','map','Map','Japan','Japan']},
	{Letter:'🎑',Tags:['🎑','moon viewing ceremony','Moon Viewing Ceremony','moon','Moon','viewing','Viewing','ceremony','Ceremony']},
	{Letter:'🏞',Tags:['🏞','national park','National Park','national','National','park','Park','hill','Hill','hills','Hills','mountain','Mountain','mountains','Mountains','','']},
	{Letter:'🌅',Tags:['🌅','sunrise','Sunrise','sunrise','Sunrise']},
	{Letter:'🌄',Tags:['🌄','sunrise over mountains','Sunrise Over Mountains','sunrise','Sunrise','mountains','Mountains','mountain','Mountain']},
	{Letter:'🌠',Tags:['🌠','shooting star','Shooting Star','shooting','Shooting','star','Star']},
	{Letter:'🎇',Tags:['🎇','sparkler','Sparkler','sparkler','Sparkler','fireworks','Fireworks','new year','New Year']},
	{Letter:'🎆',Tags:['🎆','fireworks','Fireworks','fireworks','Fireworks','new year','New Year']},
	{Letter:'🌇',Tags:['🌇','sunset','Sunset','sunset','Sunset','skyline','Skyline','city','City']},
	{Letter:'🌆',Tags:['🌆','cityscape at dusk','Cityscape At Dusk','cityscape','Cityscape','at','At','dusk','Dusk','skyline','Skyline','city','City']},
	{Letter:'🏙',Tags:['🏙','cityscape','Cityscape','cityscape','Cityscape','skyline','Skyline','city','City']},
	{Letter:'🌃',Tags:['🌃','night with stars','Night With Stars','night','Night','stars','Stars','skyline','Skyline','city','City']},
	{Letter:'🌌',Tags:['🌌','milky way','Milky Way','milky','Milky','way','Way']},
	{Letter:'🌉',Tags:['🌉','bridge at night','Bridge At Night','bridge','Bridge','at','At','night','Night']},
	{Letter:'🌁',Tags:['🌁','foggy','Foggy','foggy','Foggy']},
	{Letter:'⌚️',Tags:['⌚️','watch','Watch','watch','Watch']},
	{Letter:'📱',Tags:['📱','mobile phone','Mobile Phone','mobile','Mobile','phone','Phone']},
	{Letter:'📲',Tags:['📲','mobile phone with arrow','Mobile Phone With Arrow','mobile','Mobile','phone','Phone','arrow','Arrow']},
	{Letter:'💻',Tags:['💻','laptop','Laptop','laptop','Laptop']},
	{Letter:'⌨️',Tags:['⌨️','keyboard','Keyboard','keyboard','Keyboard']},
	{Letter:'🖥',Tags:['🖥','desktop computer','Desktop Computer','desktop','Desktop','computer','Computer']},
	{Letter:'🖨',Tags:['🖨','printer','Printer','printer','Printer']},
	{Letter:'🖱',Tags:['🖱','computer mouse','Computer Mouse','computer','Computer','mouse','Mouse']},
	{Letter:'🖲',Tags:['🖲','trackball','Trackball','trackball','Trackball']},
	{Letter:'🕹',Tags:['🕹','joystick','Joystick','joystick','Joystick']},
	{Letter:'🗜',Tags:['🗜','clamp','Clamp','clamp','Clamp']},
	{Letter:'💽',Tags:['💽','computer disk','Computer Disk','computer','Computer','disk','Disk']},
	{Letter:'💾',Tags:['💾','floppy disk','Floppy Disk','floppy','Floppy','disk','Disk']},
	{Letter:'💿',Tags:['💿','optical disk','Optical Disk','optical','Optical','disk','Disk']},
	{Letter:'📀',Tags:['📀','dvd','Dvd','dvd','Dvd']},
	{Letter:'📼',Tags:['📼','videocassette','Videocassette','videocassette','Videocassette']},
	{Letter:'📷',Tags:['📷','camera','Camera','camera','Camera']},
	{Letter:'📸',Tags:['📸','camera with flash','Camera With Flash','camera','Camera','flash','Flash']},
	{Letter:'📹',Tags:['📹','video camera','Video Camera','video','Video','camera','Camera']},
	{Letter:'🎥',Tags:['🎥','movie camera','Movie Camera','movie','Movie','camera','Camera']},
	{Letter:'📽',Tags:['📽','film projector','Film Projector','film','Film','projector','Projector']},
	{Letter:'🎞',Tags:['🎞','film frames','Film Frames','film','Film','frames','Frames']},
	{Letter:'📞',Tags:['📞','telephone receiver','Telephone Receiver','telephone','Telephone','receiver','Receiver']},
	{Letter:'☎️',Tags:['☎️','telephone','Telephone','telephone','Telephone']},
	{Letter:'📟',Tags:['📟','pager','Pager','pager','Pager']},
	{Letter:'📠',Tags:['📠','fax machine','Fax Machine','fax','Fax','machine','Machine']},
	{Letter:'📺',Tags:['📺','television','Television','television','Television']},
	{Letter:'📻',Tags:['📻','radio','Radio','radio','Radio']},
	{Letter:'🎙',Tags:['🎙','studio microphone','Studio Microphone','studio','Studio','microphone','Microphone']},
	{Letter:'🎚',Tags:['🎚','level slider','Level Slider','level','Level','slider','Slider']},
	{Letter:'🎛',Tags:['🎛','control knobs','Control Knobs','control','Control','knobs','Knobs']},
	{Letter:'🧭',Tags:['🧭','compass','Compass','compass','Compass']},
	{Letter:'⏱',Tags:['⏱','stopwatch','Stopwatch','stopwatch','Stopwatch']},
	{Letter:'⏲',Tags:['⏲','timer clock','Timer Clock','timer','Timer','clock','Clock']},
	{Letter:'⏰',Tags:['⏰','alarm clock','Alarm Clock','alarm','Alarm','clock','Clock']},
	{Letter:'🕰',Tags:['🕰','mantelpiece clock','Mantelpiece Clock','mantelpiece','Mantelpiece','clock','Clock']},
	{Letter:'⌛️',Tags:['⌛️','hourglass done','Hourglass Done','hourglass','Hourglass','done','Done']},
	{Letter:'⏳',Tags:['⏳','hourglass not done','Hourglass Not Done','hourglass','Hourglass','not','Not','done','Done']},
	{Letter:'📡',Tags:['📡','satellite antenna','Satellite Antenna','satellite','Satellite','antenna','Antenna']},
	{Letter:'🔋',Tags:['🔋','battery','Battery','battery','Battery']},
	{Letter:'🔌',Tags:['🔌','electric plug','Electric Plug','electric','Electric','plug','Plug']},
	{Letter:'💡',Tags:['💡','light bulb','Light Bulb','light','Light','bulb','Bulb']},
	{Letter:'🔦',Tags:['🔦','flashlight','Flashlight','flashlight','Flashlight']},
	{Letter:'🕯',Tags:['🕯','candle','Candle','candle','Candle']},
	{Letter:'🪔',Tags:['🪔','diya lamp','Diya Lamp','diya','Diya','lamp','Lamp']},
	{Letter:'🧯',Tags:['🧯','fire extinguisher','Fire Extinguisher','fire','Fire','extinguisher','Extinguisher']},
	{Letter:'🛢',Tags:['🛢','oil drum','Oil Drum','oil','Oil','drum','Drum']},
	{Letter:'💸',Tags:['💸','money with wings','Money With Wings','money','Money','wings','Wings']},
	{Letter:'💵',Tags:['💵','dollar banknote','Dollar Banknote','dollar','Dollar','banknote','Banknote']},
	{Letter:'💴',Tags:['💴','yen banknote','Yen Banknote','yen','Yen','banknote','Banknote']},
	{Letter:'💶',Tags:['💶','euro banknote','Euro Banknote','euro','Euro','banknote','Banknote']},
	{Letter:'💷',Tags:['💷','pound banknote','Pound Banknote','pound','Pound','banknote','Banknote']},
	{Letter:'🪙',Tags:['🪙','coin','Coin','coin','Coin']},
	{Letter:'💰',Tags:['💰','money bag','Money Bag','money','Money','bag','Bag']},
	{Letter:'💳',Tags:['💳','credit card','Credit Card','credit','Credit','card','Card']},
	{Letter:'💎',Tags:['💎','gem stone','Gem Stone','gem','Gem','stone','Stone']},
	{Letter:'⚖️',Tags:['⚖️','balance scale','Balance Scale','balance','Balance','scale','Scale']},
	{Letter:'🪜',Tags:['🪜','ladder','Ladder','ladder','Ladder']},
	{Letter:'🧰',Tags:['🧰','toolbox','Toolbox','toolbox','Toolbox']},
	{Letter:'🪛',Tags:['🪛','screwdriver','Screwdriver','screwdriver','Screwdriver']},
	{Letter:'🔧',Tags:['🔧','wrench','Wrench','wrench','Wrench']},
	{Letter:'🔨',Tags:['🔨','hammer','Hammer','hammer','Hammer']},
	{Letter:'⚒',Tags:['⚒','hammer and pick','Hammer And Pick','hammer','Hammer','and','And','pick','Pick']},
	{Letter:'🛠',Tags:['🛠','hammer and wrench','Hammer And Wrench','hammer','Hammer','and','And','wrench','Wrench']},
	{Letter:'⛏',Tags:['⛏','pick','Pick','pick','Pick']},
	{Letter:'🪚',Tags:['🪚','carpentry saw','Carpentry Saw','carpentry','Carpentry','saw','Saw']},
	{Letter:'🔩',Tags:['🔩','nut and bolt','Nut And Bolt','nut','Nut','and','And','bolt','Bolt']},
	{Letter:'⚙️',Tags:['⚙️','gear','Gear','gear','Gear']},
	{Letter:'🪤',Tags:['🪤','mouse trap','Mouse Trap','mouse','Mouse','trap','Trap']},
	{Letter:'🧱',Tags:['🧱','brick','Brick','brick','Brick']},
	{Letter:'⛓',Tags:['⛓','chains','Chains','chains','Chains']},
	{Letter:'🧲',Tags:['🧲','magnet','Magnet','magnet','Magnet']},
	{Letter:'🔫',Tags:['🔫','pistol','Pistol','pistol','Pistol']},
	{Letter:'💣',Tags:['💣','bomb','Bomb','bomb','Bomb']},
	{Letter:'🧨',Tags:['🧨','firecracker','Firecracker','firecracker','Firecracker']},
	{Letter:'🪓',Tags:['🪓','axe','Axe','axe','Axe']},
	{Letter:'🔪',Tags:['🔪','kitchen knife','Kitchen Knife','kitchen','Kitchen','knife','Knife']},
	{Letter:'🗡',Tags:['🗡','dagger','Dagger','dagger','Dagger']},
	{Letter:'⚔️',Tags:['⚔️','crossed swords','Crossed Swords','crossed','Crossed','swords','Swords']},
	{Letter:'🛡',Tags:['🛡','shield','Shield','shield','Shield']},
	{Letter:'🚬',Tags:['🚬','cigarette','Cigarette','cigarette','Cigarette']},
	{Letter:'⚰️',Tags:['⚰️','coffin','Coffin','coffin','Coffin']},
	{Letter:'🪦',Tags:['🪦','headstone','Headstone','headstone','Headstone']},
	{Letter:'⚱️',Tags:['⚱️','funeral urn','Funeral Urn','funeral','Funeral','urn','Urn']},
	{Letter:'🏺',Tags:['🏺','amphora','Amphora','amphora','Amphora']},
	{Letter:'🔮',Tags:['🔮','crystal ball','Crystal Ball','crystal','Crystal','ball','Ball']},
	{Letter:'📿',Tags:['📿','prayer beads','Prayer Beads','prayer','Prayer','beads','Beads']},
	{Letter:'🧿',Tags:['🧿','nazar amulet','Nazar Amulet','nazar','Nazar','amulet','Amulet']},
	{Letter:'💈',Tags:['💈','barber pole','Barber Pole','barber','Barber','pole','Pole']},
	{Letter:'⚗️',Tags:['⚗️','alembic','Alembic','alembic','Alembic']},
	{Letter:'🔭',Tags:['🔭','telescope','Telescope','telescope','Telescope','zoom','Zoom']},
	{Letter:'🔬',Tags:['🔬','microscope','Microscope','microscope','Microscope','zoom','Zoom']},
	{Letter:'🕳',Tags:['🕳','hole','Hole','hole','Hole']},
	{Letter:'🩹',Tags:['🩹','adhesive bandage','Adhesive Bandage','adhesive','Adhesive','bandage','Bandage']},
	{Letter:'🩺',Tags:['🩺','stethoscope','Stethoscope','stethoscope','Stethoscope']},
	{Letter:'💊',Tags:['💊','pill','Pill','pill','Pill']},
	{Letter:'💉',Tags:['💉','syringe','Syringe','syringe','Syringe']},
	{Letter:'🩸',Tags:['🩸','drop of blood','Drop Of Blood','drop','Drop','blood','Blood']},
	{Letter:'🧬',Tags:['🧬','dna','Dna','dna','Dna']},
	{Letter:'🦠',Tags:['🦠','microbe','Microbe','microbe','Microbe']},
	{Letter:'🧫',Tags:['🧫','petri dish','Petri Dish','petri','Petri','dish','Dish']},
	{Letter:'🧪',Tags:['🧪','test tube','Test Tube','test','Test','tube','Tube']},
	{Letter:'🌡',Tags:['🌡','thermometer','Thermometer','thermometer','Thermometer']},
	{Letter:'🧹',Tags:['🧹','broom','Broom','broom','Broom']},
	{Letter:'🪠',Tags:['🪠','plunger','Plunger','plunger','Plunger']},
	{Letter:'🧺',Tags:['🧺','basket','Basket','basket','Basket']},
	{Letter:'🧻',Tags:['🧻','roll of paper','Roll Of Paper','roll','Roll','paper','Paper']},
	{Letter:'🚽',Tags:['🚽','toilet','Toilet','toilet','Toilet','wc','Wc']},
	{Letter:'🚰',Tags:['🚰','potable water','Potable Water','potable','Potable','water','Water']},
	{Letter:'🚿',Tags:['🚿','shower','Shower','shower','Shower']},
	{Letter:'🛁',Tags:['🛁','bathtub','Bathtub','bathtub','Bathtub']},
	{Letter:'🛀',Tags:['🛀','person taking bath','Person Taking Bath','person','Person','bath','Bath']},
	{Letter:'🧼',Tags:['🧼','soap','Soap','soap','Soap']},
	{Letter:'🪥',Tags:['🪥','toothbrush','Toothbrush','toothbrush','Toothbrush']},
	{Letter:'🪒',Tags:['🪒','razor','Razor','razor','Razor']},
	{Letter:'🧽',Tags:['🧽','sponge','Sponge','sponge','Sponge']},
	{Letter:'🪣',Tags:['🪣','bucket','Bucket','bucket','Bucket']},
	{Letter:'🧴',Tags:['🧴','lotion bottle','Lotion Bottle','lotion','Lotion','bottle','Bottle']},
	{Letter:'🛎',Tags:['🛎','bellhop bell','Bellhop Bell','bellhop','Bellhop','bell','Bell']},
	{Letter:'🔑',Tags:['🔑','key','Key','key','Key']},
	{Letter:'🗝',Tags:['🗝','old key','Old Key','old','Old','key','Key']},
	{Letter:'🚪',Tags:['🚪','door','Door','door','Door']},
	{Letter:'🪑',Tags:['🪑','chair','Chair','chair','Chair']},
	{Letter:'🛋',Tags:['🛋','couch and lamp','Couch And Lamp','couch','Couch','and','And','lamp','Lamp']},
	{Letter:'🛏',Tags:['🛏','bed','Bed','bed','Bed']},
	{Letter:'🛌',Tags:['🛌','person in bed','Person In Bed','person','Person','bed','Bed']},
	{Letter:'🧸',Tags:['🧸','teddy bear','Teddy Bear','teddy','Teddy','bear','Bear']},
	{Letter:'🪆',Tags:['🪆','nesting dolls','Nesting Dolls','nesting','Nesting','dolls','Dolls']},
	{Letter:'🖼',Tags:['🖼','framed picture','Framed Picture','framed','Framed','picture','Picture']},
	{Letter:'🪞',Tags:['🪞','mirror','Mirror','mirror','Mirror']},
	{Letter:'🪟',Tags:['🪟','window','Window','window','Window']},
	{Letter:'🛍',Tags:['🛍','shopping bags','Shopping Bags','shopping','Shopping','bags','Bags','bag','Bag']},
	{Letter:'🛒',Tags:['🛒','shopping cart','Shopping Cart','shopping','Shopping','cart','Cart']},
	{Letter:'🎁',Tags:['🎁','wrapped gift','Wrapped Gift','wrapped','Wrapped','gift','Gift','surprise','Surprise']},
	{Letter:'🎈',Tags:['🎈','balloon','Balloon','balloon','Balloon']},
	{Letter:'🎏',Tags:['🎏','carp streamer','Carp Streamer','carp','Carp','streamer','Streamer']},
	{Letter:'🎀',Tags:['🎀','ribbon','Ribbon','ribbon','Ribbon']},
	{Letter:'🪄',Tags:['🪄','magic wand','Magic Wand','magic','Magic','wand','Wand']},
	{Letter:'🪅',Tags:['🪅','piñata','Piñata','piñata','Piñata']},
	{Letter:'🎊',Tags:['🎊','confetti ball','Confetti Ball','confetti','Confetti','ball','Ball']},
	{Letter:'🎉',Tags:['🎉','party popper','Party Popper','party','Party','popper','Popper']},
	{Letter:'🎎',Tags:['🎎','Japanese dolls','Japanese Dolls','Japanese','Japanese','dolls','Dolls']},
	{Letter:'🏮',Tags:['🏮','red paper lantern','Red Paper Lantern','red','Red','paper','Paper','lantern','Lantern']},
	{Letter:'🎐',Tags:['🎐','wind chime','Wind Chime','wind','Wind','chime','Chime']},
	{Letter:'🧧',Tags:['🧧','red envelope','Red Envelope','red','Red','envelope','Envelope']},
	{Letter:'✉️',Tags:['✉️','envelope','Envelope','envelope','Envelope','e mail','E Mail','email','Email','mail','Mail']},
	{Letter:'📩',Tags:['📩','envelope with arrow','Envelope With Arrow','envelope','Envelope','arrow','Arrow','e mail','E Mail','email','Email','mail','Mail','','']},
	{Letter:'📨',Tags:['📨','incoming envelope','Incoming Envelope','incoming','Incoming','envelope','Envelope','e mail','E Mail','email','Email','mail','Mail']},
	{Letter:'📧',Tags:['📧','e-mail','E-Mail','e mail','E Mail','email','Email','mail','Mail']},
	{Letter:'💌',Tags:['💌','love letter','Love Letter','love','Love','letter','Letter']},
	{Letter:'📥',Tags:['📥','inbox tray','Inbox Tray','inbox','Inbox','tray','Tray']},
	{Letter:'📤',Tags:['📤','outbox tray','Outbox Tray','outbox','Outbox','tray','Tray']},
	{Letter:'📦',Tags:['📦','package','Package','package','Package','pack','Pack','delivery','Delivery','deliver','Deliver']},
	{Letter:'🏷',Tags:['🏷','label','Label','label','Label']},
	{Letter:'🪧',Tags:['🪧','placard','Placard','placard','Placard']},
	{Letter:'📪',Tags:['📪','closed mailbox with lowered flag','Closed Mailbox With Lowered Flag','closed','Closed','mailbox','Mailbox','lowered','Lowered','flag','Flag']},
	{Letter:'📫',Tags:['📫','closed mailbox with raised flag','Closed Mailbox With Raised Flag','closed','Closed','mailbox','Mailbox','raised','Raised','flag','Flag']},
	{Letter:'📬',Tags:['📬','open mailbox with raised flag','Open Mailbox With Raised Flag','open','Open','mailbox','Mailbox','raised','Raised','flag','Flag']},
	{Letter:'📭',Tags:['📭','open mailbox with lowered flag','Open Mailbox With Lowered Flag','open','Open','mailbox','Mailbox','lowered','Lowered','flag','Flag']},
	{Letter:'📮',Tags:['📮','postbox','Postbox','postbox','Postbox']},
	{Letter:'📯',Tags:['📯','postal horn','Postal Horn','postal','Postal','horn','Horn']},
	{Letter:'📜',Tags:['📜','scroll','Scroll','scroll','Scroll']},
	{Letter:'📃',Tags:['📃','page with curl','Page With Curl','page','Page','curl','Curl']},
	{Letter:'📄',Tags:['📄','page facing up','Page Facing Up','page','Page','facing','Facing','up','Up']},
	{Letter:'📑',Tags:['📑','bookmark tabs','Bookmark Tabs','bookmark','Bookmark','tabs','Tabs']},
	{Letter:'🧾',Tags:['🧾','receipt','Receipt','receipt','Receipt']},
	{Letter:'📊',Tags:['📊','bar chart','Bar Chart','bar','Bar','chart','Chart']},
	{Letter:'📈',Tags:['📈','chart increasing','Chart Increasing','chart','Chart','increasing','Increasing']},
	{Letter:'📉',Tags:['📉','chart decreasing','Chart Decreasing','chart','Chart','decreasing','Decreasing']},
	{Letter:'🗒',Tags:['🗒','spiral notepad','Spiral Notepad','spiral','Spiral','notepad','Notepad','note','Note']},
	{Letter:'🗓',Tags:['🗓','spiral calendar','Spiral Calendar','spiral','Spiral','calendar','Calendar']},
	{Letter:'📆',Tags:['📆','tear-off calendar','Tear-Off Calendar','tear off','Tear Off','calendar','Calendar']},
	{Letter:'📅',Tags:['📅','calendar','Calendar','calendar','Calendar']},
	{Letter:'🗑',Tags:['🗑','wastebasket','Wastebasket','wastebasket','Wastebasket','trash','Trash','bin','Bin','waste','Waste']},
	{Letter:'📇',Tags:['📇','card index','Card Index','card','Card','index','Index']},
	{Letter:'🗃',Tags:['🗃','card file box','Card File Box','card','Card','file','File','box','Box']},
	{Letter:'🗳',Tags:['🗳','ballot box with ballot','Ballot Box With Ballot','ballot','Ballot','box','Box','ballot','Ballot']},
	{Letter:'🗄',Tags:['🗄','file cabinet','File Cabinet','file','File','cabinet','Cabinet']},
	{Letter:'📋',Tags:['📋','clipboard','Clipboard','clipboard','Clipboard']},
	{Letter:'📁',Tags:['📁','file folder','File Folder','file','File','folder','Folder']},
	{Letter:'📂',Tags:['📂','open file folder','Open File Folder','open','Open','file','File','folder','Folder']},
	{Letter:'🗂',Tags:['🗂','card index dividers','Card Index Dividers','card','Card','index','Index','dividers','Dividers','folder','Folder']},
	{Letter:'🗞',Tags:['🗞','rolled-up newspaper','Rolled-Up Newspaper','rolled up','Rolled Up','newspaper','Newspaper','news','News']},
	{Letter:'📰',Tags:['📰','newspaper','Newspaper','newspaper','Newspaper','news','News']},
	{Letter:'📓',Tags:['📓','notebook','Notebook','notebook','Notebook','book','Book']},
	{Letter:'📔',Tags:['📔','notebook with decorative cover','Notebook With Decorative Cover','notebook','Notebook','book','Book','decorative','Decorative','cover','Cover']},
	{Letter:'📒',Tags:['📒','ledger','Ledger','ledger','Ledger','book','Book']},
	{Letter:'📕',Tags:['📕','closed book','Closed Book','closed','Closed','book','Book']},
	{Letter:'📗',Tags:['📗','green book','Green Book','green','Green','book','Book']},
	{Letter:'📘',Tags:['📘','blue book','Blue Book','blue','Blue','book','Book']},
	{Letter:'📙',Tags:['📙','orange book','Orange Book','orange','Orange','book','Book']},
	{Letter:'📚',Tags:['📚','books','Books','books','Books','book','Book']},
	{Letter:'📖',Tags:['📖','open book','Open Book','open','Open','book','Book']},
	{Letter:'🔖',Tags:['🔖','bookmark','Bookmark','bookmark','Bookmark']},
	{Letter:'🧷',Tags:['🧷','safety pin','Safety Pin','safety','Safety','pin','Pin']},
	{Letter:'🔗',Tags:['🔗','link','Link','link','Link']},
	{Letter:'📎',Tags:['📎','paperclip','Paperclip','paperclip','Paperclip','paperclips','Paperclips']},
	{Letter:'🖇',Tags:['🖇','linked paperclips','Linked Paperclips','linked','Linked','paperclips','Paperclips','paperclip','Paperclip']},
	{Letter:'📐',Tags:['📐','triangular ruler','Triangular Ruler','triangular','Triangular','ruler','Ruler']},
	{Letter:'📏',Tags:['📏','straight ruler','Straight Ruler','straight','Straight','ruler','Ruler']},
	{Letter:'🧮',Tags:['🧮','abacus','Abacus','abacus','Abacus']},
	{Letter:'📌',Tags:['📌','pushpin','Pushpin','pushpin','Pushpin','pin','Pin']},
	{Letter:'📍',Tags:['📍','round pushpin','Round Pushpin','round','Round','pushpin','Pushpin','pin','Pin']},
	{Letter:'✂️',Tags:['✂️','scissors','Scissors','scissors','Scissors','scissor','Scissor']},
	{Letter:'🖊',Tags:['🖊','pen','Pen','pen','Pen','pencil','Pencil']},
	{Letter:'🖋',Tags:['🖋','fountain pen','Fountain Pen','fountain','Fountain','pen','Pen','pencil','Pencil']},
	{Letter:'✒️',Tags:['✒️','black nib','Black Nib','black','Black','nib','Nib','pencil','Pencil','pen','Pen']},
	{Letter:'🖌',Tags:['🖌','paintbrush','Paintbrush','paintbrush','Paintbrush','pencil','Pencil','pen','Pen']},
	{Letter:'🖍',Tags:['🖍','crayon','Crayon','crayon','Crayon','pencil','Pencil','pen','Pen']},
	{Letter:'📝',Tags:['📝','memo','Memo','memo','Memo','pencil','Pencil','pen','Pen']},
	{Letter:'✏️',Tags:['✏️','pencil','Pencil','pencil','Pencil','pen','Pen']},
	{Letter:'🔍',Tags:['🔍','magnifying glass tilted left','Magnifying Glass Tilted Left','magnifying','Magnifying','glass','Glass','tilted','Tilted','left','Left','magnifier','Magnifier','search','Search','searching','Searching']},
	{Letter:'🔎',Tags:['🔎','magnifying glass tilted right','Magnifying Glass Tilted Right','magnifying','Magnifying','glass','Glass','tilted','Tilted','right','Right','magnifier','Magnifier','search','Search','searching','Searching']},
	{Letter:'🔏',Tags:['🔏','locked with pen','Locked With Pen','locked','Locked','lock','Lock','pen','Pen','locker','Locker']},
	{Letter:'🔐',Tags:['🔐','locked with key','Locked With Key','locked','Locked','lock','Lock','key','Key','locker','Locker']},
	{Letter:'🔒',Tags:['🔒','locked','Locked','locked','Locked','lock','Lock','locker','Locker']},
	{Letter:'🔓',Tags:['🔓','unlocked','Unlocked','unlocked','Unlocked','lock','Lock','locked','Locked','locker','Locker']},
	{Letter:'❤️',Tags:['❤️','red heart','Red Heart','red','Red','heart','Heart','love','Love']},
	{Letter:'🧡',Tags:['🧡','orange heart','Orange Heart','orange','Orange','heart','Heart','love','Love']},
	{Letter:'💛',Tags:['💛','yellow heart','Yellow Heart','yellow','Yellow','heart','Heart','love','Love']},
	{Letter:'💚',Tags:['💚','green heart','Green Heart','green','Green','heart','Heart','love','Love']},
	{Letter:'💙',Tags:['💙','blue heart','Blue Heart','blue','Blue','heart','Heart','love','Love']},
	{Letter:'💜',Tags:['💜','purple heart','Purple Heart','purple','Purple','heart','Heart','love','Love']},
	{Letter:'🖤',Tags:['🖤','black heart','Black Heart','black','Black','heart','Heart','love','Love']},
	{Letter:'🤍',Tags:['🤍','white heart','White Heart','white','White','heart','Heart','love','Love']},
	{Letter:'🤎',Tags:['🤎','brown heart','Brown Heart','brown','Brown','heart','Heart','love','Love']},
	{Letter:'💔',Tags:['💔','broken heart','Broken Heart','broken','Broken','heart','Heart','love','Love']},
	{Letter:'❣️',Tags:['❣️','heart exclamation','Heart Exclamation','heart','Heart','exclamation','Exclamation','love','Love']},
	{Letter:'💕',Tags:['💕','two hearts','Two Hearts','two','Two','hearts','Hearts','love','Love']},
	{Letter:'💞',Tags:['💞','revolving hearts','Revolving Hearts','revolving','Revolving','hearts','Hearts','love','Love']},
	{Letter:'💓',Tags:['💓','beating heart','Beating Heart','beating','Beating','heart','Heart','love','Love']},
	{Letter:'💗',Tags:['💗','growing heart','Growing Heart','growing','Growing','heart','Heart','love','Love']},
	{Letter:'💖',Tags:['💖','sparkling heart','Sparkling Heart','sparkling','Sparkling','heart','Heart','love','Love']},
	{Letter:'💘',Tags:['💘','heart with arrow','Heart With Arrow','heart','Heart','arrow','Arrow','love','Love']},
	{Letter:'💝',Tags:['💝','heart with ribbon','Heart With Ribbon','heart','Heart','ribbon','Ribbon','love','Love']},
	{Letter:'💟',Tags:['💟','heart decoration','Heart Decoration','heart','Heart','decoration','Decoration','love','Love']},
	{Letter:'☮️',Tags:['☮️','peace symbol','Peace Symbol','peace','Peace','symbol','Symbol']},
	{Letter:'✝️',Tags:['✝️','latin cross','Latin Cross','latin','Latin','cross','Cross']},
	{Letter:'☪️',Tags:['☪️','star and crescent','Star And Crescent','star','Star','and','And','crescent','Crescent']},
	{Letter:'🕉',Tags:['🕉','om','Om','om','Om']},
	{Letter:'☸️',Tags:['☸️','wheel of dharma','Wheel Of Dharma','wheel','Wheel','dharma','Dharma']},
	{Letter:'✡️',Tags:['✡️','star of David','Star Of David','star','Star','David','David']},
	{Letter:'🔯',Tags:['🔯','dotted six-pointed star','Dotted Six-Pointed Star','dotted','Dotted','six pointed','Six Pointed','star','Star']},
	{Letter:'🕎',Tags:['🕎','menorah','Menorah','menorah','Menorah']},
	{Letter:'☯️',Tags:['☯️','yin yang','Yin Yang','yin','Yin','yang','Yang']},
	{Letter:'☦️',Tags:['☦️','orthodox cross','Orthodox Cross','orthodox','Orthodox','cross','Cross']},
	{Letter:'🛐',Tags:['🛐','place of worship','Place Of Worship','place','Place','worship','Worship']},
	{Letter:'⛎',Tags:['⛎','Ophiuchus','Ophiuchus','Ophiuchus','Ophiuchus']},
	{Letter:'♈️',Tags:['♈️','Aries','Aries','Aries','Aries']},
	{Letter:'♉️',Tags:['♉️','Taurus','Taurus','Taurus','Taurus']},
	{Letter:'♊️',Tags:['♊️','Gemini','Gemini','Gemini','Gemini']},
	{Letter:'♋️',Tags:['♋️','Cancer','Cancer','Cancer','Cancer']},
	{Letter:'♌️',Tags:['♌️','Leo','Leo','Leo','Leo']},
	{Letter:'♍️',Tags:['♍️','Virgo','Virgo','Virgo','Virgo']},
	{Letter:'♎️',Tags:['♎️','Libra','Libra','Libra','Libra']},
	{Letter:'♏️',Tags:['♏️','Scorpio','Scorpio','Scorpio','Scorpio']},
	{Letter:'♐️',Tags:['♐️','Sagittarius','Sagittarius','Sagittarius','Sagittarius']},
	{Letter:'♑️',Tags:['♑️','Capricorn','Capricorn','Capricorn','Capricorn']},
	{Letter:'♒️',Tags:['♒️','Aquarius','Aquarius','Aquarius','Aquarius']},
	{Letter:'♓️',Tags:['♓️','Pisces','Pisces','Pisces','Pisces']},
	{Letter:'🆔',Tags:['🆔','ID button','Id Button','ID','Id','button','Button']},
	{Letter:'⚛️',Tags:['⚛️','atom symbol','Atom Symbol','atom','Atom','symbol','Symbol']},
	{Letter:'🉑',Tags:['🉑','Japanese “acceptable” button','Japanese “Acceptable” Button','Japanese','Japanese','acceptable','Acceptable','button','Button']},
	{Letter:'☢️',Tags:['☢️','radioactive','Radioactive','radioactive','Radioactive']},
	{Letter:'☣️',Tags:['☣️','biohazard','Biohazard','biohazard','Biohazard']},
	{Letter:'📴',Tags:['📴','mobile phone off','Mobile Phone Off','mobile','Mobile','phone','Phone','off','Off']},
	{Letter:'📳',Tags:['📳','vibration mode','Vibration Mode','vibration','Vibration','mode','Mode']},
	{Letter:'🈶',Tags:['🈶','Japanese “not free of charge” button','Japanese “Not Free Of Charge” Button','Japanese','Japanese','not','Not','free','Free','charge','Charge','button','Button','','']},
	{Letter:'🈚️',Tags:['🈚️','Japanese “free of charge” button','Japanese “Free Of Charge” Button','Japanese','Japanese','free','Free','charge','Charge','button','Button']},
	{Letter:'🈸',Tags:['🈸','Japanese “application” button','Japanese “Application” Button','Japanese','Japanese','application','Application','button','Button']},
	{Letter:'🈺',Tags:['🈺','Japanese “open for business” button','Japanese “Open For Business” Button','Japanese','Japanese','open','Open','for','For','business','Business','button','Button']},
	{Letter:'🈷️',Tags:['🈷️','Japanese “monthly amount” button','Japanese “Monthly Amount” Button','Japanese','Japanese','monthly','Monthly','amount','Amount','button','Button']},
	{Letter:'✴️',Tags:['✴️','eight-pointed star','Eight-Pointed Star','eight pointed','Eight Pointed','star','Star']},
	{Letter:'🆚',Tags:['🆚','VS button','Vs Button','VS','Vs','button','Button']},
	{Letter:'💮',Tags:['💮','white flower','White Flower','white','White','flower','Flower']},
	{Letter:'🉐',Tags:['🉐','Japanese “bargain” button','Japanese “Bargain” Button','Japanese','Japanese','bargain','Bargain','button','Button']},
	{Letter:'㊙️',Tags:['㊙️','Japanese “secret” button','Japanese “Secret” Button','Japanese','Japanese','secret','Secret','button','Button']},
	{Letter:'㊗️',Tags:['㊗️','Japanese “congratulations” button','Japanese “Congratulations” Button','Japanese','Japanese','congratulations','Congratulations','button','Button']},
	{Letter:'🈴',Tags:['🈴','Japanese “passing grade” button','Japanese “Passing Grade” Button','Japanese','Japanese','passing','Passing','grade','Grade','button','Button']},
	{Letter:'🈵',Tags:['🈵','Japanese “no vacancy” button','Japanese “No Vacancy” Button','Japanese','Japanese','no','No','vacancy','Vacancy','button','Button']},
	{Letter:'🈹',Tags:['🈹','Japanese “discount” button','Japanese “Discount” Button','Japanese','Japanese','discount','Discount','button','Button']},
	{Letter:'🈲',Tags:['🈲','Japanese “prohibited” button','Japanese “Prohibited” Button','Japanese','Japanese','prohibited','Prohibited','button','Button']},
	{Letter:'🅰️',Tags:['🅰️','A button (blood type)','A Button (Blood Type)','button','Button']},
	{Letter:'🅱️',Tags:['🅱️','B button (blood type)','B Button (Blood Type)','B','B','button','Button']},
	{Letter:'🆎',Tags:['🆎','AB button (blood type)','Ab Button (Blood Type)','AB','Ab','button','Button']},
	{Letter:'🆑',Tags:['🆑','CL button','Cl Button','CL','Cl','button','Button']},
	{Letter:'🅾️',Tags:['🅾️','O button (blood type)','O Button (Blood Type)','O','O','button','Button']},
	{Letter:'🆘',Tags:['🆘','SOS button','Sos Button','SOS','Sos','button','Button']},
	{Letter:'❌',Tags:['❌','cross mark','Cross Mark','cross','Cross','mark','Mark','no','No']},
	{Letter:'⭕️',Tags:['⭕️','hollow red circle','Hollow Red Circle','hollow','Hollow','red','Red','circle','Circle','no','No']},
	{Letter:'🛑',Tags:['🛑','stop sign','Stop Sign','stop','Stop','sign','Sign','no','No']},
	{Letter:'⛔️',Tags:['⛔️','no entry','No Entry','no','No','entry','Entry']},
	{Letter:'📛',Tags:['📛','name badge','Name Badge','name','Name','badge','Badge']},
	{Letter:'🚫',Tags:['🚫','prohibited','Prohibited','prohibited','Prohibited','no','No']},
	{Letter:'💯',Tags:['💯','hundred points','Hundred Points','hundred','Hundred','points','Points']},
	{Letter:'💢',Tags:['💢','anger symbol','Anger Symbol','anger','Anger','symbol','Symbol']},
	{Letter:'♨️',Tags:['♨️','hot springs','Hot Springs','hot','Hot','springs','Springs']},
	{Letter:'🚷',Tags:['🚷','no pedestrians','No Pedestrians','no','No','pedestrians','Pedestrians']},
	{Letter:'🚯',Tags:['🚯','no littering','No Littering','no','No','littering','Littering']},
	{Letter:'🚳',Tags:['🚳','no bicycles','No Bicycles','no','No','bicycles','Bicycles']},
	{Letter:'🚱',Tags:['🚱','non-potable water','Non-Potable Water','non potable','Non Potable','water','Water']},
	{Letter:'🔞',Tags:['🔞','no one under eighteen','No One Under Eighteen','no','No','one','One','under','Under','eighteen','Eighteen']},
	{Letter:'📵',Tags:['📵','no mobile phones','No Mobile Phones','no','No','mobile','Mobile','phones','Phones']},
	{Letter:'🚭',Tags:['🚭','no smoking','No Smoking','no','No','smoking','Smoking']},
	{Letter:'❗️',Tags:['❗️','exclamation mark','Exclamation Mark','exclamation','Exclamation','mark','Mark','!','!']},
	{Letter:'❕',Tags:['❕','white exclamation mark','White Exclamation Mark','white','White','exclamation','Exclamation','mark','Mark','!','!']},
	{Letter:'❓',Tags:['❓','question mark','Question Mark','question','Question','mark','Mark','?','?']},
	{Letter:'❔',Tags:['❔','white question mark','White Question Mark','white','White','question','Question','mark','Mark','?','?']},
	{Letter:'‼️',Tags:['‼️','double exclamation mark','Double Exclamation Mark','double','Double','exclamation','Exclamation','mark','Mark','!!','!!']},
	{Letter:'⁉️',Tags:['⁉️','exclamation question mark','Exclamation Question Mark','exclamation','Exclamation','question','Question','mark','Mark','!?','!?']},
	{Letter:'🔅',Tags:['🔅','dim button','Dim Button','dim','Dim','button','Button']},
	{Letter:'🔆',Tags:['🔆','bright button','Bright Button','bright','Bright','button','Button']},
	{Letter:'〽️',Tags:['〽️','part alternation mark','Part Alternation Mark','part','Part','alternation','Alternation','mark','Mark']},
	{Letter:'⚠️',Tags:['⚠️','warning','Warning','warning','Warning','attention','Attention']},
	{Letter:'🚸',Tags:['🚸','children crossing','Children Crossing','children','Children','crossing','Crossing']},
	{Letter:'🔱',Tags:['🔱','trident emblem','Trident Emblem','trident','Trident','emblem','Emblem']},
	{Letter:'⚜️',Tags:['⚜️','fleur-de-lis','Fleur-De-Lis','fleur de lis','Fleur De Lis']},
	{Letter:'🔰',Tags:['🔰','Japanese symbol for beginner','Japanese Symbol For Beginner','Japanese','Japanese','symbol','Symbol','for','For','beginner','Beginner']},
	{Letter:'♻️',Tags:['♻️','recycling symbol','Recycling Symbol','recycling','Recycling','symbol','Symbol']},
	{Letter:'✅',Tags:['✅','check mark button','Check Mark Button','check','Check','mark','Mark','button','Button','yes','Yes']},
	{Letter:'🈯️',Tags:['🈯️','Japanese “reserved” button','Japanese “Reserved” Button','Japanese','Japanese','reserved','Reserved','button','Button']},
	{Letter:'💹',Tags:['💹','chart increasing with yen','Chart Increasing With Yen','chart','Chart','increasing','Increasing','yen','Yen']},
	{Letter:'❇️',Tags:['❇️','sparkle','Sparkle','sparkle','Sparkle']},
	{Letter:'✳️',Tags:['✳️','eight-spoked asterisk','Eight-Spoked Asterisk','eight spoked','Eight Spoked','asterisk','Asterisk']},
	{Letter:'❎',Tags:['❎','cross mark button','Cross Mark Button','cross','Cross','mark','Mark','button','Button','no','No']},
	{Letter:'🌐',Tags:['🌐','globe with meridians','Globe With Meridians','globe','Globe','meridians','Meridians']},
	{Letter:'💠',Tags:['💠','diamond with a dot','Diamond With A Dot','diamond','Diamond','dot','Dot']},
	{Letter:'Ⓜ️',Tags:['Ⓜ️','circled M','Circled M','circled','Circled','M','M']},
	{Letter:'🌀',Tags:['🌀','cyclone','Cyclone','cyclone','Cyclone']},
	{Letter:'💤',Tags:['💤','zzz','Zzz','zzz','Zzz','tired','Tired','sleep','Sleep']},
	{Letter:'🏧',Tags:['🏧','ATM sign','Atm Sign','ATM','Atm','sign','Sign']},
	{Letter:'🚾',Tags:['🚾','water closet','Water Closet','water','Water','closet','Closet']},
	{Letter:'♿️',Tags:['♿️','wheelchair symbol','Wheelchair Symbol','wheelchair','Wheelchair','symbol','Symbol']},
	{Letter:'🅿️',Tags:['🅿️','P button','P Button','P','P','button','Button']},
	{Letter:'🛗',Tags:['🛗','elevator','Elevator','elevator','Elevator']},
	{Letter:'🈳',Tags:['🈳','Japanese “vacancy” button','Japanese “Vacancy” Button','Japanese','Japanese','vacancy','Vacancy','button','Button']},
	{Letter:'🈂️',Tags:['🈂️','Japanese “service charge” button','Japanese “Service Charge” Button','Japanese','Japanese','service','Service','charge','Charge','button','Button']},
	{Letter:'🛂',Tags:['🛂','passport control','Passport Control','passport','Passport','control','Control']},
	{Letter:'🛃',Tags:['🛃','customs','Customs','customs','Customs']},
	{Letter:'🛄',Tags:['🛄','baggage claim','Baggage Claim','baggage','Baggage','claim','Claim']},
	{Letter:'🛅',Tags:['🛅','left luggage','Left Luggage','left','Left','luggage','Luggage']},
	{Letter:'🚹',Tags:['🚹','men’s room','Men’S Room','men’s','Men’S','room','Room']},
	{Letter:'🚺',Tags:['🚺','women’s room','Women’S Room','women’s','Women’S','room','Room']},
	{Letter:'🚼',Tags:['🚼','baby symbol','Baby Symbol','baby','Baby','symbol','Symbol']},
	{Letter:'⚧',Tags:['⚧','transgender symbol','Transgender Symbol','transgender','Transgender','symbol','Symbol']},
	{Letter:'🚻',Tags:['🚻','restroom','Restroom','restroom','Restroom']},
	{Letter:'🚮',Tags:['🚮','litter in bin sign','Litter In Bin Sign','litter','Litter','bin','Bin','sign','Sign']},
	{Letter:'🎦',Tags:['🎦','cinema','Cinema','cinema','Cinema']},
	{Letter:'📶',Tags:['📶','antenna bars','Antenna Bars','antenna','Antenna','bars','Bars']},
	{Letter:'🈁',Tags:['🈁','Japanese “here” button','Japanese “Here” Button','Japanese','Japanese','here','Here','button','Button']},
	{Letter:'🔣',Tags:['🔣','input symbols','Input Symbols','input','Input','symbols','Symbols']},
	{Letter:'ℹ️',Tags:['ℹ️','information','Information','information','Information']},
	{Letter:'🔤',Tags:['🔤','input latin letters','Input Latin Letters','input','Input','latin','Latin','letters','Letters']},
	{Letter:'🔡',Tags:['🔡','input latin lowercase','Input Latin Lowercase','input','Input','latin','Latin','lowercase','Lowercase']},
	{Letter:'🔠',Tags:['🔠','input latin uppercase','Input Latin Uppercase','input','Input','latin','Latin','uppercase','Uppercase']},
	{Letter:'🆖',Tags:['🆖','NG button','Ng Button','NG','Ng','button','Button']},
	{Letter:'🆗',Tags:['🆗','OK button','Ok Button','OK','Ok','button','Button']},
	{Letter:'🆙',Tags:['🆙','UP! button','Up! Button','UP!','Up!','button','Button']},
	{Letter:'🆒',Tags:['🆒','COOL button','Cool Button','COOL','Cool','button','Button']},
	{Letter:'🆕',Tags:['🆕','NEW button','New Button','NEW','New','button','Button']},
	{Letter:'🆓',Tags:['🆓','FREE button','Free Button','FREE','Free','button','Button']},
	{Letter:'0️⃣',Tags:['0️⃣','keycap: 0','Keycap: 0','keycap','Keycap','0','0']},
	{Letter:'1️⃣',Tags:['1️⃣','keycap: 1','Keycap: 1','keycap','Keycap','1','1']},
	{Letter:'2️⃣',Tags:['2️⃣','keycap: 2','Keycap: 2','keycap','Keycap','2','2']},
	{Letter:'3️⃣',Tags:['3️⃣','keycap: 3','Keycap: 3','keycap','Keycap','3','3']},
	{Letter:'4️⃣',Tags:['4️⃣','keycap: 4','Keycap: 4','keycap','Keycap','4','4']},
	{Letter:'5️⃣',Tags:['5️⃣','keycap: 5','Keycap: 5','keycap','Keycap','5','5']},
	{Letter:'6️⃣',Tags:['6️⃣','keycap: 6','Keycap: 6','keycap','Keycap','6','6']},
	{Letter:'7️⃣',Tags:['7️⃣','keycap: 7','Keycap: 7','keycap','Keycap','7','7']},
	{Letter:'8️⃣',Tags:['8️⃣','keycap: 8','Keycap: 8','keycap','Keycap','8','8']},
	{Letter:'9️⃣',Tags:['9️⃣','keycap: 9','Keycap: 9','keycap','Keycap','9','9']},
	{Letter:'🔟',Tags:['🔟','keycap: 10','Keycap: 10','keycap','Keycap','10','10']},
	{Letter:'🔢',Tags:['🔢','input numbers','Input Numbers','input','Input','numbers','Numbers']},
	{Letter:'#️⃣',Tags:['#️⃣','#','#','#','#']},
	{Letter:'*️⃣',Tags:['*️⃣','#','#','#','#']},
	{Letter:'⏏️',Tags:['⏏️','eject button','Eject Button','eject','Eject','button','Button']},
	{Letter:'▶️',Tags:['▶️','play button','Play Button','play','Play','button','Button']},
	{Letter:'⏸',Tags:['⏸','pause button','Pause Button','pause','Pause','button','Button']},
	{Letter:'⏯',Tags:['⏯','play or pause button','Play Or Pause Button','play','Play','pause','Pause','button','Button']},
	{Letter:'⏹',Tags:['⏹','stop button','Stop Button','stop','Stop','button','Button']},
	{Letter:'⏺',Tags:['⏺','record button','Record Button','record','Record','button','Button']},
	{Letter:'⏭',Tags:['⏭','next track button','Next Track Button','next','Next','track','Track','button','Button']},
	{Letter:'⏮',Tags:['⏮','last track button','Last Track Button','last','Last','track','Track','button','Button']},
	{Letter:'⏩',Tags:['⏩','fast-forward button','Fast-Forward Button','fast forward','Fast Forward','button','Button']},
	{Letter:'⏪',Tags:['⏪','fast reverse button','Fast Reverse Button','fast','Fast','reverse','Reverse','button','Button']},
	{Letter:'⏫',Tags:['⏫','fast up button','Fast Up Button','fast','Fast','up','Up','button','Button']},
	{Letter:'⏬',Tags:['⏬','fast down button','Fast Down Button','fast','Fast','down','Down','button','Button']},
	{Letter:'◀️',Tags:['◀️','reverse button','Reverse Button','reverse','Reverse','button','Button']},
	{Letter:'🔼',Tags:['🔼','upwards button','Upwards Button','upwards','Upwards','button','Button']},
	{Letter:'🔽',Tags:['🔽','downwards button','Downwards Button','downwards','Downwards','button','Button']},
	{Letter:'➡️',Tags:['➡️','right arrow','Right Arrow','right','Right','arrow','Arrow']},
	{Letter:'⬅️',Tags:['⬅️','left arrow','Left Arrow','left','Left','arrow','Arrow']},
	{Letter:'⬆️',Tags:['⬆️','up arrow','Up Arrow','up','Up','arrow','Arrow']},
	{Letter:'⬇️',Tags:['⬇️','down arrow','Down Arrow','down','Down','arrow','Arrow']},
	{Letter:'↗️',Tags:['↗️','up-right arrow','Up-Right Arrow','up right','Up Right','arrow','Arrow','up right','Up Right']},
	{Letter:'↘️',Tags:['↘️','down-right arrow','Down-Right Arrow','down right','Down Right','arrow','Arrow','down right','Down Right']},
	{Letter:'↙️',Tags:['↙️','down-left arrow','Down-Left Arrow','down left','Down Left','arrow','Arrow','down left','Down Left']},
	{Letter:'↖️',Tags:['↖️','up-left arrow','Up-Left Arrow','up left','Up Left','arrow','Arrow','up left','Up Left']},
	{Letter:'↕️',Tags:['↕️','up-down arrow','Up-Down Arrow','up down','Up Down','arrow','Arrow']},
	{Letter:'↔️',Tags:['↔️','left-right arrow','Left-Right Arrow','left right','Left Right','arrow','Arrow']},
	{Letter:'↪️',Tags:['↪️','left arrow curving right','Left Arrow Curving Right','left','Left','arrow','Arrow','curving','Curving','right','Right']},
	{Letter:'↩️',Tags:['↩️','right arrow curving left','Right Arrow Curving Left','right','Right','arrow','Arrow','curving','Curving','left','Left']},
	{Letter:'⤴️',Tags:['⤴️','right arrow curving up','Right Arrow Curving Up','right','Right','arrow','Arrow','curving','Curving','up','Up']},
	{Letter:'⤵️',Tags:['⤵️','right arrow curving down','Right Arrow Curving Down','right','Right','arrow','Arrow','curving','Curving','down','Down']},
	{Letter:'🔀',Tags:['🔀','shuffle tracks button','Shuffle Tracks Button','shuffle','Shuffle','tracks','Tracks','button','Button']},
	{Letter:'🔁',Tags:['🔁','repeat button','Repeat Button','repeat','Repeat','button','Button']},
	{Letter:'🔂',Tags:['🔂','repeat single button','Repeat Single Button','repeat','Repeat','single','Single','button','Button']},
	{Letter:'🔄',Tags:['🔄','counterclockwise arrows button','Counterclockwise Arrows Button','counterclockwise','Counterclockwise','arrows','Arrows','button','Button']},
	{Letter:'🔃',Tags:['🔃','clockwise vertical arrows','Clockwise Vertical Arrows','clockwise','Clockwise','vertical','Vertical','arrows','Arrows']},
	{Letter:'🎵',Tags:['🎵','musical note','Musical Note','musical','Musical','note','Note']},
	{Letter:'🎶',Tags:['🎶','musical notes','Musical Notes','musical','Musical','notes','Notes']},
	{Letter:'➕',Tags:['➕','plus','Plus','plus','Plus','+','+']},
	{Letter:'➖',Tags:['➖','minus','Minus','minus','Minus',' ',' ']},
	{Letter:'➗',Tags:['➗','divide','Divide','divide','Divide']},
	{Letter:'✖️',Tags:['✖️','multiply','Multiply','multiply','Multiply','x','X','*','*']},
	{Letter:'♾',Tags:['♾','infinity','Infinity','infinity','Infinity']},
	{Letter:'💲',Tags:['💲','heavy dollar sign','Heavy Dollar Sign','heavy','Heavy','dollar','Dollar','sign','Sign']},
	{Letter:'💱',Tags:['💱','currency exchange','Currency Exchange','currency','Currency','exchange','Exchange']},
	{Letter:'™️',Tags:['™️','trade mark','Trade Mark','trade','Trade','mark','Mark']},
	{Letter:'©️',Tags:['©️','copyright','Copyright','copyright','Copyright']},
	{Letter:'®️',Tags:['®️','registered','Registered','registered','Registered']},
	{Letter:'〰️',Tags:['〰️','wavy dash','Wavy Dash','wavy','Wavy','dash','Dash']},
	{Letter:'➰',Tags:['➰','curly loop','Curly Loop','curly','Curly','loop','Loop']},
	{Letter:'➿',Tags:['➿','double curly loop','Double Curly Loop','double','Double','curly','Curly','loop','Loop']},
	{Letter:'🔚',Tags:['🔚','END arrow','End Arrow','END','End','arrow','Arrow']},
	{Letter:'🔙',Tags:['🔙','BACK arrow','Back Arrow','BACK','Back','arrow','Arrow']},
	{Letter:'🔛',Tags:['🔛','ON! arrow','On! Arrow','ON!','On!','arrow','Arrow']},
	{Letter:'🔝',Tags:['🔝','TOP arrow','Top Arrow','TOP','Top','arrow','Arrow']},
	{Letter:'🔜',Tags:['🔜','SOON arrow','Soon Arrow','SOON','Soon','arrow','Arrow']},
	{Letter:'✔️',Tags:['✔️','check mark','Check Mark','check','Check','mark','Mark']},
	{Letter:'☑️',Tags:['☑️','check box with check','Check Box With Check','check','Check','box','Box','check','Check']},
	{Letter:'🔘',Tags:['🔘','radio button','Radio Button','radio','Radio','button','Button']},
	{Letter:'🔴',Tags:['🔴','red circle','Red Circle','red','Red','circle','Circle']},
	{Letter:'🟠',Tags:['🟠','orange circle','Orange Circle','orange','Orange','circle','Circle']},
	{Letter:'🟡',Tags:['🟡','yellow circle','Yellow Circle','yellow','Yellow','circle','Circle']},
	{Letter:'🟢',Tags:['🟢','green circle','Green Circle','green','Green','circle','Circle']},
	{Letter:'🔵',Tags:['🔵','blue circle','Blue Circle','blue','Blue','circle','Circle']},
	{Letter:'🟣',Tags:['🟣','purple circle','Purple Circle','purple','Purple','circle','Circle']},
	{Letter:'⚫️',Tags:['⚫️','black circle','Black Circle','black','Black','circle','Circle']},
	{Letter:'⚪️',Tags:['⚪️','white circle','White Circle','white','White','circle','Circle']},
	{Letter:'🟤',Tags:['🟤','brown circle','Brown Circle','brown','Brown','circle','Circle']},
	{Letter:'🔺',Tags:['🔺','red triangle pointed up','Red Triangle Pointed Up','red','Red','triangle','Triangle','pointed','Pointed','up','Up']},
	{Letter:'🔻',Tags:['🔻','red triangle pointed down','Red Triangle Pointed Down','red','Red','triangle','Triangle','pointed','Pointed','down','Down']},
	{Letter:'🔸',Tags:['🔸','small orange diamond','Small Orange Diamond','small','Small','orange','Orange','diamond','Diamond']},
	{Letter:'🔹',Tags:['🔹','small blue diamond','Small Blue Diamond','small','Small','blue','Blue','diamond','Diamond']},
	{Letter:'🔶',Tags:['🔶','large orange diamond','Large Orange Diamond','large','Large','orange','Orange','diamond','Diamond']},
	{Letter:'🔷',Tags:['🔷','large blue diamond','Large Blue Diamond','large','Large','blue','Blue','diamond','Diamond']},
	{Letter:'🔳',Tags:['🔳','white square button','White Square Button','white','White','square','Square','button','Button']},
	{Letter:'🔲',Tags:['🔲','black square button','Black Square Button','black','Black','square','Square','button','Button']},
	{Letter:'▪️',Tags:['▪️','black small square','Black Small Square','black','Black','small','Small','square','Square']},
	{Letter:'▫️',Tags:['▫️','white small square','White Small Square','white','White','small','Small','square','Square']},
	{Letter:'◾️',Tags:['◾️','black medium-small square','Black Medium-Small Square','black','Black','medium small','Medium Small','square','Square']},
	{Letter:'◽️',Tags:['◽️','white medium-small square','White Medium-Small Square','white','White','medium small','Medium Small','square','Square']},
	{Letter:'◼️',Tags:['◼️','black medium square','Black Medium Square','black','Black','medium','Medium','square','Square']},
	{Letter:'◻️',Tags:['◻️','white medium square','White Medium Square','white','White','medium','Medium','square','Square']},
	{Letter:'🟥',Tags:['🟥','red square','Red Square','red','Red','square','Square']},
	{Letter:'🟧',Tags:['🟧','orange square','Orange Square','orange','Orange','square','Square']},
	{Letter:'🟨',Tags:['🟨','yellow square','Yellow Square','yellow','Yellow','square','Square']},
	{Letter:'🟩',Tags:['🟩','green square','Green Square','green','Green','square','Square']},
	{Letter:'🟦',Tags:['🟦','blue square','Blue Square','blue','Blue','square','Square']},
	{Letter:'🟪',Tags:['🟪','purple square','Purple Square','purple','Purple','square','Square']},
	{Letter:'⬛️',Tags:['⬛️','black large square','Black Large Square','black','Black','large','Large','square','Square']},
	{Letter:'⬜️',Tags:['⬜️','white large square','White Large Square','white','White','large','Large','square','Square']},
	{Letter:'🟫',Tags:['🟫','brown square','Brown Square','brown','Brown','square','Square']},
	{Letter:'🔈',Tags:['🔈','speaker low volume','Speaker Low Volume','speaker','Speaker','low','Low','volume','Volume']},
	{Letter:'🔇',Tags:['🔇','muted speaker','Muted Speaker','muted','Muted','speaker','Speaker']},
	{Letter:'🔉',Tags:['🔉','speaker medium volume','Speaker Medium Volume','speaker','Speaker','medium','Medium','volume','Volume']},
	{Letter:'🔊',Tags:['🔊','speaker high volume','Speaker High Volume','speaker','Speaker','high','High','volume','Volume']},
	{Letter:'🔔',Tags:['🔔','bell','Bell','bell','Bell']},
	{Letter:'🔕',Tags:['🔕','bell with slash','Bell With Slash','bell','Bell']},
	{Letter:'📣',Tags:['📣','megaphone','Megaphone','megaphone','Megaphone']},
	{Letter:'📢',Tags:['📢','loudspeaker','Loudspeaker','loudspeaker','Loudspeaker']},
	{Letter:'👁‍🗨',Tags:['👁‍🗨','eye in speech bubble','Eye In Speech Bubble','eye','Eye','speech','Speech','bubble','Bubble']},
	{Letter:'💬',Tags:['💬','speech balloon','Speech Balloon','speech','Speech','balloon','Balloon']},
	{Letter:'💭',Tags:['💭','thought balloon','Thought Balloon','thought','Thought','balloon','Balloon']},
	{Letter:'🗯',Tags:['🗯','right anger bubble','Right Anger Bubble','right','Right','anger','Anger','bubble','Bubble']},
	{Letter:'♠️',Tags:['♠️','spade suit','Spade Suit','spade','Spade','suit','Suit']},
	{Letter:'♣️',Tags:['♣️','club suit','Club Suit','club','Club','suit','Suit']},
	{Letter:'♥️',Tags:['♥️','heart suit','Heart Suit','heart','Heart','suit','Suit']},
	{Letter:'♦️',Tags:['♦️','diamond suit','Diamond Suit','diamond','Diamond','suit','Suit']},
	{Letter:'🃏',Tags:['🃏','joker','Joker','joker','Joker']},
	{Letter:'🎴',Tags:['🎴','flower playing cards','Flower Playing Cards','flower','Flower','playing','Playing','cards','Cards']},
	{Letter:'🀄️',Tags:['🀄️','mahjong red dragon','Mahjong Red Dragon','mahjong','Mahjong','red','Red','dragon','Dragon']},
	{Letter:'🕐',Tags:['🕐','one o’clock','One O’Clock','one','One','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕑',Tags:['🕑','two o’clock','Two O’Clock','two','Two','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕒',Tags:['🕒','three o’clock','Three O’Clock','three','Three','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕓',Tags:['🕓','four o’clock','Four O’Clock','four','Four','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕔',Tags:['🕔','five o’clock','Five O’Clock','five','Five','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕕',Tags:['🕕','six o’clock','Six O’Clock','six','Six','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕖',Tags:['🕖','seven o’clock','Seven O’Clock','seven','Seven','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕗',Tags:['🕗','eight o’clock','Eight O’Clock','eight','Eight','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕘',Tags:['🕘','nine o’clock','Nine O’Clock','nine','Nine','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕙',Tags:['🕙','ten o’clock','Ten O’Clock','ten','Ten','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕚',Tags:['🕚','eleven o’clock','Eleven O’Clock','eleven','Eleven','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕛',Tags:['🕛','twelve o’clock','Twelve O’Clock','twelve','Twelve','o’clock','O’Clock','clock','Clock']},
	{Letter:'🕜',Tags:['🕜','one-thirty','One-Thirty','one thirty','One Thirty','clock','Clock']},
	{Letter:'🕝',Tags:['🕝','two-thirty','Two-Thirty','two thirty','Two Thirty','clock','Clock']},
	{Letter:'🕞',Tags:['🕞','three-thirty','Three-Thirty','three thirty','Three Thirty','clock','Clock']},
	{Letter:'🕟',Tags:['🕟','four-thirty','Four-Thirty','four thirty','Four Thirty','clock','Clock']},
	{Letter:'🕠',Tags:['🕠','five-thirty','Five-Thirty','five thirty','Five Thirty','clock','Clock']},
	{Letter:'🕡',Tags:['🕡','six-thirty','Six-Thirty','six thirty','Six Thirty','clock','Clock']},
	{Letter:'🕢',Tags:['🕢','seven-thirty','Seven-Thirty','seven thirty','Seven Thirty','clock','Clock']},
	{Letter:'🕣',Tags:['🕣','eight-thirty','Eight-Thirty','eight thirty','Eight Thirty','clock','Clock']},
	{Letter:'🕤',Tags:['🕤','nine-thirty','Nine-Thirty','nine thirty','Nine Thirty','clock','Clock']},
	{Letter:'🕥',Tags:['🕥','ten-thirty','Ten-Thirty','ten thirty','Ten Thirty','clock','Clock']},
	{Letter:'🕦',Tags:['🕦','eleven-thirty','Eleven-Thirty','eleven thirty','Eleven Thirty','clock','Clock']},
	{Letter:'🕧',Tags:['🕧','twelve-thirty','Twelve-Thirty','twelve thirty','Twelve Thirty','clock','Clock']},
	{Letter:'🏁',Tags:['🏁','chequered flag','Chequered Flag','chequered','Chequered','flag','Flag']},
	{Letter:'🚩',Tags:['🚩','triangular flag','Triangular Flag','triangular','Triangular','flag','Flag']},
	{Letter:'🎌',Tags:['🎌','crossed flags','Crossed Flags','crossed','Crossed','flags','Flags','flag','Flag']},
	{Letter:'🏴',Tags:['🏴','black flag','Black Flag','black','Black','flag','Flag']},
	{Letter:'🏳️',Tags:['🏳️','white flag','White Flag','white','White','flag','Flag']},
	{Letter:'🏳',Tags:['🏳','white flag','White Flag','white','White','flag','Flag']},
	{Letter:'🏳️‍🌈',Tags:['🏳️‍🌈','rainbow flag','Rainbow Flag','rainbow','Rainbow','flag','Flag']},
	{Letter:'🏳‍🌈',Tags:['🏳‍🌈','rainbow flag','Rainbow Flag','rainbow','Rainbow','flag','Flag']},
	{Letter:'🏳️‍⚧️',Tags:['🏳️‍⚧️','transgender flag','Transgender Flag','transgender','Transgender','flag','Flag']},
	{Letter:'🏳‍⚧️',Tags:['🏳‍⚧️','transgender flag','Transgender Flag','transgender','Transgender','flag','Flag']},
	{Letter:'🏳️‍⚧',Tags:['🏳️‍⚧','transgender flag','Transgender Flag','transgender','Transgender','flag','Flag']},
	{Letter:'🏳‍⚧',Tags:['🏳‍⚧','transgender flag','Transgender Flag','transgender','Transgender','flag','Flag']},
	{Letter:'🏴‍☠️',Tags:['🏴‍☠️','pirate flag','Pirate Flag','pirate','Pirate','flag','Flag']},
	{Letter:'🏴‍☠',Tags:['🏴‍☠','pirate flag','Pirate Flag','pirate','Pirate','flag','Flag']},
	{Letter:'🇦🇨',Tags:['🇦🇨','flag Ascension Island','Flag Ascension Island','flag','Flag','Ascension','Ascension','Island','Island']},
	{Letter:'🇦🇩',Tags:['🇦🇩','flag Andorra','Flag Andorra','flag','Flag','Andorra','Andorra']},
	{Letter:'🇦🇪',Tags:['🇦🇪','flag United Arab Emirates','Flag United Arab Emirates','flag','Flag','United','United','Arab','Arab','Emirates','Emirates']},
	{Letter:'🇦🇫',Tags:['🇦🇫','flag Afghanistan','Flag Afghanistan','flag','Flag','Afghanistan','Afghanistan']},
	{Letter:'🇦🇬',Tags:['🇦🇬','flag Antigua & Barbuda','Flag Antigua & Barbuda','flag','Flag','Antigua','Antigua','&','&','Barbuda','Barbuda']},
	{Letter:'🇦🇮',Tags:['🇦🇮','flag Anguilla','Flag Anguilla','flag','Flag','Anguilla','Anguilla']},
	{Letter:'🇦🇱',Tags:['🇦🇱','flag Albania','Flag Albania','flag','Flag','Albania','Albania']},
	{Letter:'🇦🇲',Tags:['🇦🇲','flag Armenia','Flag Armenia','flag','Flag','Armenia','Armenia']},
	{Letter:'🇦🇴',Tags:['🇦🇴','flag Angola','Flag Angola','flag','Flag','Angola','Angola']},
	{Letter:'🇦🇶',Tags:['🇦🇶','flag Antarctica','Flag Antarctica','flag','Flag','Antarctica','Antarctica']},
	{Letter:'🇦🇷',Tags:['🇦🇷','flag Argentina','Flag Argentina','flag','Flag','Argentina','Argentina']},
	{Letter:'🇦🇸',Tags:['🇦🇸','flag American Samoa','Flag American Samoa','flag','Flag','American','American','Samoa','Samoa']},
	{Letter:'🇦🇹',Tags:['🇦🇹','flag Austria','Flag Austria','flag','Flag','Austria','Austria']},
	{Letter:'🇦🇺',Tags:['🇦🇺','flag Australia','Flag Australia','flag','Flag','Australia','Australia']},
	{Letter:'🇦🇼',Tags:['🇦🇼','flag Aruba','Flag Aruba','flag','Flag','Aruba','Aruba']},
	{Letter:'🇦🇽',Tags:['🇦🇽','flag Åland Islands','Flag Åland Islands','flag','Flag','Åland','Åland','Islands','Islands']},
	{Letter:'🇦🇿',Tags:['🇦🇿','flag Azerbaijan','Flag Azerbaijan','flag','Flag','Azerbaijan','Azerbaijan']},
	{Letter:'🇧🇦',Tags:['🇧🇦','flag Bosnia & Herzegovina','Flag Bosnia & Herzegovina','flag','Flag','Bosnia','Bosnia','&','&','Herzegovina','Herzegovina']},
	{Letter:'🇧🇧',Tags:['🇧🇧','flag Barbados','Flag Barbados','flag','Flag','Barbados','Barbados']},
	{Letter:'🇧🇩',Tags:['🇧🇩','flag Bangladesh','Flag Bangladesh','flag','Flag','Bangladesh','Bangladesh']},
	{Letter:'🇧🇪',Tags:['🇧🇪','flag Belgium','Flag Belgium','flag','Flag','Belgium','Belgium']},
	{Letter:'🇧🇫',Tags:['🇧🇫','flag Burkina Faso','Flag Burkina Faso','flag','Flag','Burkina','Burkina','Faso','Faso']},
	{Letter:'🇧🇬',Tags:['🇧🇬','flag Bulgaria','Flag Bulgaria','flag','Flag','Bulgaria','Bulgaria']},
	{Letter:'🇧🇭',Tags:['🇧🇭','flag Bahrain','Flag Bahrain','flag','Flag','Bahrain','Bahrain']},
	{Letter:'🇧🇮',Tags:['🇧🇮','flag Burundi','Flag Burundi','flag','Flag','Burundi','Burundi']},
	{Letter:'🇧🇯',Tags:['🇧🇯','flag Benin','Flag Benin','flag','Flag','Benin','Benin']},
	{Letter:'🇧🇱',Tags:['🇧🇱','flag St. Barthélemy','Flag St. Barthélemy','flag','Flag','St.','St.','Barthélemy','Barthélemy']},
	{Letter:'🇧🇲',Tags:['🇧🇲','flag Bermuda','Flag Bermuda','flag','Flag','Bermuda','Bermuda']},
	{Letter:'🇧🇳',Tags:['🇧🇳','flag Brunei','Flag Brunei','flag','Flag','Brunei','Brunei']},
	{Letter:'🇧🇴',Tags:['🇧🇴','flag Bolivia','Flag Bolivia','flag','Flag','Bolivia','Bolivia']},
	{Letter:'🇧🇶',Tags:['🇧🇶','flag Caribbean Netherlands','Flag Caribbean Netherlands','flag','Flag','Caribbean','Caribbean','Netherlands','Netherlands']},
	{Letter:'🇧🇷',Tags:['🇧🇷','flag Brazil','Flag Brazil','flag','Flag','Brazil','Brazil']},
	{Letter:'🇧🇸',Tags:['🇧🇸','flag Bahamas','Flag Bahamas','flag','Flag','Bahamas','Bahamas']},
	{Letter:'🇧🇹',Tags:['🇧🇹','flag Bhutan','Flag Bhutan','flag','Flag','Bhutan','Bhutan']},
	{Letter:'🇧🇻',Tags:['🇧🇻','flag Bouvet Island','Flag Bouvet Island','flag','Flag','Bouvet','Bouvet','Island','Island']},
	{Letter:'🇧🇼',Tags:['🇧🇼','flag Botswana','Flag Botswana','flag','Flag','Botswana','Botswana']},
	{Letter:'🇧🇾',Tags:['🇧🇾','flag Belarus','Flag Belarus','flag','Flag','Belarus','Belarus']},
	{Letter:'🇧🇿',Tags:['🇧🇿','flag Belize','Flag Belize','flag','Flag','Belize','Belize']},
	{Letter:'🇨🇦',Tags:['🇨🇦','flag Canada','Flag Canada','flag','Flag','Canada','Canada']},
	{Letter:'🇨🇨',Tags:['🇨🇨','flag Cocos (Keeling) Islands','Flag Cocos (Keeling) Islands','flag','Flag','Cocos','Cocos','(Keeling)','(Keeling)','Islands','Islands']},
	{Letter:'🇨🇩',Tags:['🇨🇩','flag Congo   Kinshasa','Flag Congo   Kinshasa','flag','Flag','Congo','Congo',' ',' ','Kinshasa','Kinshasa']},
	{Letter:'🇨🇫',Tags:['🇨🇫','flag Central African Republic','Flag Central African Republic','flag','Flag','Central','Central','African','African','Republic','Republic']},
	{Letter:'🇨🇬',Tags:['🇨🇬','flag Congo   Brazzaville','Flag Congo   Brazzaville','flag','Flag','Congo','Congo',' ',' ','Brazzaville','Brazzaville']},
	{Letter:'🇨🇭',Tags:['🇨🇭','flag Switzerland','Flag Switzerland','flag','Flag','Switzerland','Switzerland']},
	{Letter:'🇨🇮',Tags:['🇨🇮','flag Côte d’Ivoire','Flag Côte D’Ivoire','flag','Flag','Côte','Côte','d’Ivoire','D’Ivoire']},
	{Letter:'🇨🇰',Tags:['🇨🇰','flag Cook Islands','Flag Cook Islands','flag','Flag','Cook','Cook','Islands','Islands']},
	{Letter:'🇨🇱',Tags:['🇨🇱','flag Chile','Flag Chile','flag','Flag','Chile','Chile']},
	{Letter:'🇨🇲',Tags:['🇨🇲','flag Cameroon','Flag Cameroon','flag','Flag','Cameroon','Cameroon']},
	{Letter:'🇨🇳',Tags:['🇨🇳','flag China','Flag China','flag','Flag','China','China']},
	{Letter:'🇨🇴',Tags:['🇨🇴','flag Colombia','Flag Colombia','flag','Flag','Colombia','Colombia']},
	{Letter:'🇨🇵',Tags:['🇨🇵','flag Clipperton Island','Flag Clipperton Island','flag','Flag','Clipperton','Clipperton','Island','Island']},
	{Letter:'🇨🇷',Tags:['🇨🇷','flag Costa Rica','Flag Costa Rica','flag','Flag','Costa','Costa','Rica','Rica']},
	{Letter:'🇨🇺',Tags:['🇨🇺','flag Cuba','Flag Cuba','flag','Flag','Cuba','Cuba']},
	{Letter:'🇨🇻',Tags:['🇨🇻','flag Cape Verde','Flag Cape Verde','flag','Flag','Cape','Cape','Verde','Verde']},
	{Letter:'🇨🇼',Tags:['🇨🇼','flag Curaçao','Flag Curaçao','flag','Flag','Curaçao','Curaçao']},
	{Letter:'🇨🇽',Tags:['🇨🇽','flag Christmas Island','Flag Christmas Island','flag','Flag','Christmas','Christmas','Island','Island']},
	{Letter:'🇨🇾',Tags:['🇨🇾','flag Cyprus','Flag Cyprus','flag','Flag','Cyprus','Cyprus']},
	{Letter:'🇨🇿',Tags:['🇨🇿','flag Czechia','Flag Czechia','flag','Flag','Czechia','Czechia']},
	{Letter:'🇩🇪',Tags:['🇩🇪','flag Germany','Flag Germany','flag','Flag','Germany','Germany']},
	{Letter:'🇩🇬',Tags:['🇩🇬','flag Diego Garcia','Flag Diego Garcia','flag','Flag','Diego','Diego','Garcia','Garcia']},
	{Letter:'🇩🇯',Tags:['🇩🇯','flag Djibouti','Flag Djibouti','flag','Flag','Djibouti','Djibouti']},
	{Letter:'🇩🇰',Tags:['🇩🇰','flag Denmark','Flag Denmark','flag','Flag','Denmark','Denmark']},
	{Letter:'🇩🇲',Tags:['🇩🇲','flag Dominica','Flag Dominica','flag','Flag','Dominica','Dominica']},
	{Letter:'🇩🇴',Tags:['🇩🇴','flag Dominican Republic','Flag Dominican Republic','flag','Flag','Dominican','Dominican','Republic','Republic']},
	{Letter:'🇩🇿',Tags:['🇩🇿','flag Algeria','Flag Algeria','flag','Flag','Algeria','Algeria']},
	{Letter:'🇪🇦',Tags:['🇪🇦','flag Ceuta & Melilla','Flag Ceuta & Melilla','flag','Flag','Ceuta','Ceuta','&','&','Melilla','Melilla']},
	{Letter:'🇪🇨',Tags:['🇪🇨','flag Ecuador','Flag Ecuador','flag','Flag','Ecuador','Ecuador']},
	{Letter:'🇪🇪',Tags:['🇪🇪','flag Estonia','Flag Estonia','flag','Flag','Estonia','Estonia']},
	{Letter:'🇪🇬',Tags:['🇪🇬','flag Egypt','Flag Egypt','flag','Flag','Egypt','Egypt']},
	{Letter:'🇪🇭',Tags:['🇪🇭','flag Western Sahara','Flag Western Sahara','flag','Flag','Western','Western','Sahara','Sahara']},
	{Letter:'🇪🇷',Tags:['🇪🇷','flag Eritrea','Flag Eritrea','flag','Flag','Eritrea','Eritrea']},
	{Letter:'🇪🇸',Tags:['🇪🇸','flag Spain','Flag Spain','flag','Flag','Spain','Spain']},
	{Letter:'🇪🇹',Tags:['🇪🇹','flag Ethiopia','Flag Ethiopia','flag','Flag','Ethiopia','Ethiopia']},
	{Letter:'🇪🇺',Tags:['🇪🇺','flag European Union','Flag European Union','flag','Flag','European','European','Union','Union']},
	{Letter:'🇫🇮',Tags:['🇫🇮','flag Finland','Flag Finland','flag','Flag','Finland','Finland']},
	{Letter:'🇫🇯',Tags:['🇫🇯','flag Fiji','Flag Fiji','flag','Flag','Fiji','Fiji']},
	{Letter:'🇫🇰',Tags:['🇫🇰','flag Falkland Islands','Flag Falkland Islands','flag','Flag','Falkland','Falkland','Islands','Islands']},
	{Letter:'🇫🇲',Tags:['🇫🇲','flag Micronesia','Flag Micronesia','flag','Flag','Micronesia','Micronesia']},
	{Letter:'🇫🇴',Tags:['🇫🇴','flag Faroe Islands','Flag Faroe Islands','flag','Flag','Faroe','Faroe','Islands','Islands']},
	{Letter:'🇫🇷',Tags:['🇫🇷','flag France','Flag France','flag','Flag','France','France']},
	{Letter:'🇬🇦',Tags:['🇬🇦','flag Gabon','Flag Gabon','flag','Flag','Gabon','Gabon']},
	{Letter:'🇬🇧',Tags:['🇬🇧','flag United Kingdom','Flag United Kingdom','flag','Flag','United','United','Kingdom','Kingdom']},
	{Letter:'🇬🇩',Tags:['🇬🇩','flag Grenada','Flag Grenada','flag','Flag','Grenada','Grenada']},
	{Letter:'🇬🇪',Tags:['🇬🇪','flag Georgia','Flag Georgia','flag','Flag','Georgia','Georgia']},
	{Letter:'🇬🇫',Tags:['🇬🇫','flag French Guiana','Flag French Guiana','flag','Flag','French','French','Guiana','Guiana']},
	{Letter:'🇬🇬',Tags:['🇬🇬','flag Guernsey','Flag Guernsey','flag','Flag','Guernsey','Guernsey']},
	{Letter:'🇬🇭',Tags:['🇬🇭','flag Ghana','Flag Ghana','flag','Flag','Ghana','Ghana']},
	{Letter:'🇬🇮',Tags:['🇬🇮','flag Gibraltar','Flag Gibraltar','flag','Flag','Gibraltar','Gibraltar']},
	{Letter:'🇬🇱',Tags:['🇬🇱','flag Greenland','Flag Greenland','flag','Flag','Greenland','Greenland']},
	{Letter:'🇬🇲',Tags:['🇬🇲','flag Gambia','Flag Gambia','flag','Flag','Gambia','Gambia']},
	{Letter:'🇬🇳',Tags:['🇬🇳','flag Guinea','Flag Guinea','flag','Flag','Guinea','Guinea']},
	{Letter:'🇬🇵',Tags:['🇬🇵','flag Guadeloupe','Flag Guadeloupe','flag','Flag','Guadeloupe','Guadeloupe']},
	{Letter:'🇬🇶',Tags:['🇬🇶','flag Equatorial Guinea','Flag Equatorial Guinea','flag','Flag','Equatorial','Equatorial','Guinea','Guinea']},
	{Letter:'🇬🇷',Tags:['🇬🇷','flag Greece','Flag Greece','flag','Flag','Greece','Greece']},
	{Letter:'🇬🇸',Tags:['🇬🇸','flag South Georgia & South Sandwich Islands','Flag South Georgia & South Sandwich Islands','flag','Flag','South','South','Georgia','Georgia','&','&','South','South','Sandwich','Sandwich','Islands','Islands']},
	{Letter:'🇬🇹',Tags:['🇬🇹','flag Guatemala','Flag Guatemala','flag','Flag','Guatemala','Guatemala']},
	{Letter:'🇬🇺',Tags:['🇬🇺','flag Guam','Flag Guam','flag','Flag','Guam','Guam']},
	{Letter:'🇬🇼',Tags:['🇬🇼','flag Guinea Bissau','Flag Guinea Bissau','flag','Flag','Guinea Bissau','Guinea Bissau']},
	{Letter:'🇬🇾',Tags:['🇬🇾','flag Guyana','Flag Guyana','flag','Flag','Guyana','Guyana']},
	{Letter:'🇭🇰',Tags:['🇭🇰','flag Hong Kong SAR China','Flag Hong Kong Sar China','flag','Flag','Hong','Hong','Kong','Kong','SAR','Sar','China','China']},
	{Letter:'🇭🇲',Tags:['🇭🇲','flag Heard & McDonald Islands','Flag Heard & Mcdonald Islands','flag','Flag','Heard','Heard','&','&','McDonald','Mcdonald','Islands','Islands']},
	{Letter:'🇭🇳',Tags:['🇭🇳','flag Honduras','Flag Honduras','flag','Flag','Honduras','Honduras']},
	{Letter:'🇭🇷',Tags:['🇭🇷','flag Croatia','Flag Croatia','flag','Flag','Croatia','Croatia']},
	{Letter:'🇭🇹',Tags:['🇭🇹','flag Haiti','Flag Haiti','flag','Flag','Haiti','Haiti']},
	{Letter:'🇭🇺',Tags:['🇭🇺','flag Hungary','Flag Hungary','flag','Flag','Hungary','Hungary']},
	{Letter:'🇮🇨',Tags:['🇮🇨','flag Canary Islands','Flag Canary Islands','flag','Flag','Canary','Canary','Islands','Islands']},
	{Letter:'🇮🇩',Tags:['🇮🇩','flag Indonesia','Flag Indonesia','flag','Flag','Indonesia','Indonesia']},
	{Letter:'🇮🇪',Tags:['🇮🇪','flag Ireland','Flag Ireland','flag','Flag','Ireland','Ireland']},
	{Letter:'🇮🇱',Tags:['🇮🇱','flag Israel','Flag Israel','flag','Flag','Israel','Israel']},
	{Letter:'🇮🇲',Tags:['🇮🇲','flag Isle of Man','Flag Isle Of Man','flag','Flag','Isle','Isle','Man','Man']},
	{Letter:'🇮🇳',Tags:['🇮🇳','flag India','Flag India','flag','Flag','India','India']},
	{Letter:'🇮🇴',Tags:['🇮🇴','flag British Indian Ocean Territory','Flag British Indian Ocean Territory','flag','Flag','British','British','Indian','Indian','Ocean','Ocean','Territory','Territory']},
	{Letter:'🇮🇶',Tags:['🇮🇶','flag Iraq','Flag Iraq','flag','Flag','Iraq','Iraq']},
	{Letter:'🇮🇷',Tags:['🇮🇷','flag Iran','Flag Iran','flag','Flag','Iran','Iran']},
	{Letter:'🇮🇸',Tags:['🇮🇸','flag Iceland','Flag Iceland','flag','Flag','Iceland','Iceland']},
	{Letter:'🇮🇹',Tags:['🇮🇹','flag Italy','Flag Italy','flag','Flag','Italy','Italy']},
	{Letter:'🇯🇪',Tags:['🇯🇪','flag Jersey','Flag Jersey','flag','Flag','Jersey','Jersey']},
	{Letter:'🇯🇲',Tags:['🇯🇲','flag Jamaica','Flag Jamaica','flag','Flag','Jamaica','Jamaica']},
	{Letter:'🇯🇴',Tags:['🇯🇴','flag Jordan','Flag Jordan','flag','Flag','Jordan','Jordan']},
	{Letter:'🇯🇵',Tags:['🇯🇵','flag Japan','Flag Japan','flag','Flag','Japan','Japan']},
	{Letter:'🇰🇪',Tags:['🇰🇪','flag Kenya','Flag Kenya','flag','Flag','Kenya','Kenya']},
	{Letter:'🇰🇬',Tags:['🇰🇬','flag Kyrgyzstan','Flag Kyrgyzstan','flag','Flag','Kyrgyzstan','Kyrgyzstan']},
	{Letter:'🇰🇭',Tags:['🇰🇭','flag Cambodia','Flag Cambodia','flag','Flag','Cambodia','Cambodia']},
	{Letter:'🇰🇮',Tags:['🇰🇮','flag Kiribati','Flag Kiribati','flag','Flag','Kiribati','Kiribati']},
	{Letter:'🇰🇲',Tags:['🇰🇲','flag Comoros','Flag Comoros','flag','Flag','Comoros','Comoros']},
	{Letter:'🇰🇳',Tags:['🇰🇳','flag St. Kitts & Nevis','Flag St. Kitts & Nevis','flag','Flag','St.','St.','Kitts','Kitts','&','&','Nevis','Nevis']},
	{Letter:'🇰🇵',Tags:['🇰🇵','flag North Korea','Flag North Korea','flag','Flag','North','North','Korea','Korea']},
	{Letter:'🇰🇷',Tags:['🇰🇷','flag South Korea','Flag South Korea','flag','Flag','South','South','Korea','Korea']},
	{Letter:'🇰🇼',Tags:['🇰🇼','flag Kuwait','Flag Kuwait','flag','Flag','Kuwait','Kuwait']},
	{Letter:'🇰🇾',Tags:['🇰🇾','flag Cayman Islands','Flag Cayman Islands','flag','Flag','Cayman','Cayman','Islands','Islands']},
	{Letter:'🇰🇿',Tags:['🇰🇿','flag Kazakhstan','Flag Kazakhstan','flag','Flag','Kazakhstan','Kazakhstan']},
	{Letter:'🇱🇦',Tags:['🇱🇦','flag Laos','Flag Laos','flag','Flag','Laos','Laos']},
	{Letter:'🇱🇧',Tags:['🇱🇧','flag Lebanon','Flag Lebanon','flag','Flag','Lebanon','Lebanon']},
	{Letter:'🇱🇨',Tags:['🇱🇨','flag St. Lucia','Flag St. Lucia','flag','Flag','St.','St.','Lucia','Lucia']},
	{Letter:'🇱🇮',Tags:['🇱🇮','flag Liechtenstein','Flag Liechtenstein','flag','Flag','Liechtenstein','Liechtenstein']},
	{Letter:'🇱🇰',Tags:['🇱🇰','flag Sri Lanka','Flag Sri Lanka','flag','Flag','Sri','Sri','Lanka','Lanka']},
	{Letter:'🇱🇷',Tags:['🇱🇷','flag Liberia','Flag Liberia','flag','Flag','Liberia','Liberia']},
	{Letter:'🇱🇸',Tags:['🇱🇸','flag Lesotho','Flag Lesotho','flag','Flag','Lesotho','Lesotho']},
	{Letter:'🇱🇹',Tags:['🇱🇹','flag Lithuania','Flag Lithuania','flag','Flag','Lithuania','Lithuania']},
	{Letter:'🇱🇺',Tags:['🇱🇺','flag Luxembourg','Flag Luxembourg','flag','Flag','Luxembourg','Luxembourg']},
	{Letter:'🇱🇻',Tags:['🇱🇻','flag Latvia','Flag Latvia','flag','Flag','Latvia','Latvia']},
	{Letter:'🇱🇾',Tags:['🇱🇾','flag Libya','Flag Libya','flag','Flag','Libya','Libya']},
	{Letter:'🇲🇦',Tags:['🇲🇦','flag Morocco','Flag Morocco','flag','Flag','Morocco','Morocco']},
	{Letter:'🇲🇨',Tags:['🇲🇨','flag Monaco','Flag Monaco','flag','Flag','Monaco','Monaco']},
	{Letter:'🇲🇩',Tags:['🇲🇩','flag Moldova','Flag Moldova','flag','Flag','Moldova','Moldova']},
	{Letter:'🇲🇪',Tags:['🇲🇪','flag Montenegro','Flag Montenegro','flag','Flag','Montenegro','Montenegro']},
	{Letter:'🇲🇫',Tags:['🇲🇫','flag St. Martin','Flag St. Martin','flag','Flag','St.','St.','Martin','Martin']},
	{Letter:'🇲🇬',Tags:['🇲🇬','flag Madagascar','Flag Madagascar','flag','Flag','Madagascar','Madagascar']},
	{Letter:'🇲🇭',Tags:['🇲🇭','flag Marshall Islands','Flag Marshall Islands','flag','Flag','Marshall','Marshall','Islands','Islands']},
	{Letter:'🇲🇰',Tags:['🇲🇰','flag North Macedonia','Flag North Macedonia','flag','Flag','North','North','Macedonia','Macedonia']},
	{Letter:'🇲🇱',Tags:['🇲🇱','flag Mali','Flag Mali','flag','Flag','Mali','Mali']},
	{Letter:'🇲🇲',Tags:['🇲🇲','flag Myanmar (Burma)','Flag Myanmar (Burma)','flag','Flag','Myanmar','Myanmar','(Burma)','(Burma)']},
	{Letter:'🇲🇳',Tags:['🇲🇳','flag Mongolia','Flag Mongolia','flag','Flag','Mongolia','Mongolia']},
	{Letter:'🇲🇴',Tags:['🇲🇴','flag Macao SAR China','Flag Macao Sar China','flag','Flag','Macao','Macao','SAR','Sar','China','China']},
	{Letter:'🇲🇵',Tags:['🇲🇵','flag Northern Mariana Islands','Flag Northern Mariana Islands','flag','Flag','Northern','Northern','Mariana','Mariana','Islands','Islands']},
	{Letter:'🇲🇶',Tags:['🇲🇶','flag Martinique','Flag Martinique','flag','Flag','Martinique','Martinique']},
	{Letter:'🇲🇷',Tags:['🇲🇷','flag Mauritania','Flag Mauritania','flag','Flag','Mauritania','Mauritania']},
	{Letter:'🇲🇸',Tags:['🇲🇸','flag Montserrat','Flag Montserrat','flag','Flag','Montserrat','Montserrat']},
	{Letter:'🇲🇹',Tags:['🇲🇹','flag Malta','Flag Malta','flag','Flag','Malta','Malta']},
	{Letter:'🇲🇺',Tags:['🇲🇺','flag Mauritius','Flag Mauritius','flag','Flag','Mauritius','Mauritius']},
	{Letter:'🇲🇻',Tags:['🇲🇻','flag Maldives','Flag Maldives','flag','Flag','Maldives','Maldives']},
	{Letter:'🇲🇼',Tags:['🇲🇼','flag Malawi','Flag Malawi','flag','Flag','Malawi','Malawi']},
	{Letter:'🇲🇽',Tags:['🇲🇽','flag Mexico','Flag Mexico','flag','Flag','Mexico','Mexico']},
	{Letter:'🇲🇾',Tags:['🇲🇾','flag Malaysia','Flag Malaysia','flag','Flag','Malaysia','Malaysia']},
	{Letter:'🇲🇿',Tags:['🇲🇿','flag Mozambique','Flag Mozambique','flag','Flag','Mozambique','Mozambique']},
	{Letter:'🇳🇦',Tags:['🇳🇦','flag Namibia','Flag Namibia','flag','Flag','Namibia','Namibia']},
	{Letter:'🇳🇨',Tags:['🇳🇨','flag New Caledonia','Flag New Caledonia','flag','Flag','New','New','Caledonia','Caledonia']},
	{Letter:'🇳🇪',Tags:['🇳🇪','flag Niger','Flag Niger','flag','Flag','Niger','Niger']},
	{Letter:'🇳🇫',Tags:['🇳🇫','flag Norfolk Island','Flag Norfolk Island','flag','Flag','Norfolk','Norfolk','Island','Island']},
	{Letter:'🇳🇬',Tags:['🇳🇬','flag Nigeria','Flag Nigeria','flag','Flag','Nigeria','Nigeria']},
	{Letter:'🇳🇮',Tags:['🇳🇮','flag Nicaragua','Flag Nicaragua','flag','Flag','Nicaragua','Nicaragua']},
	{Letter:'🇳🇱',Tags:['🇳🇱','flag Netherlands','Flag Netherlands','flag','Flag','Netherlands','Netherlands']},
	{Letter:'🇳🇴',Tags:['🇳🇴','flag Norway','Flag Norway','flag','Flag','Norway','Norway']},
	{Letter:'🇳🇵',Tags:['🇳🇵','flag Nepal','Flag Nepal','flag','Flag','Nepal','Nepal']},
	{Letter:'🇳🇷',Tags:['🇳🇷','flag Nauru','Flag Nauru','flag','Flag','Nauru','Nauru']},
	{Letter:'🇳🇺',Tags:['🇳🇺','flag Niue','Flag Niue','flag','Flag','Niue','Niue']},
	{Letter:'🇳🇿',Tags:['🇳🇿','flag New Zealand','Flag New Zealand','flag','Flag','New','New','Zealand','Zealand']},
	{Letter:'🇴🇲',Tags:['🇴🇲','flag Oman','Flag Oman','flag','Flag','Oman','Oman']},
	{Letter:'🇵🇦',Tags:['🇵🇦','flag Panama','Flag Panama','flag','Flag','Panama','Panama']},
	{Letter:'🇵🇪',Tags:['🇵🇪','flag Peru','Flag Peru','flag','Flag','Peru','Peru']},
	{Letter:'🇵🇫',Tags:['🇵🇫','flag French Polynesia','Flag French Polynesia','flag','Flag','French','French','Polynesia','Polynesia']},
	{Letter:'🇵🇬',Tags:['🇵🇬','flag Papua New Guinea','Flag Papua New Guinea','flag','Flag','Papua','Papua','New','New','Guinea','Guinea']},
	{Letter:'🇵🇭',Tags:['🇵🇭','flag Philippines','Flag Philippines','flag','Flag','Philippines','Philippines']},
	{Letter:'🇵🇰',Tags:['🇵🇰','flag Pakistan','Flag Pakistan','flag','Flag','Pakistan','Pakistan']},
	{Letter:'🇵🇱',Tags:['🇵🇱','flag Poland','Flag Poland','flag','Flag','Poland','Poland']},
	{Letter:'🇵🇲',Tags:['🇵🇲','flag St. Pierre & Miquelon','Flag St. Pierre & Miquelon','flag','Flag','St.','St.','Pierre','Pierre','&','&','Miquelon','Miquelon']},
	{Letter:'🇵🇳',Tags:['🇵🇳','flag Pitcairn Islands','Flag Pitcairn Islands','flag','Flag','Pitcairn','Pitcairn','Islands','Islands']},
	{Letter:'🇵🇷',Tags:['🇵🇷','flag Puerto Rico','Flag Puerto Rico','flag','Flag','Puerto','Puerto','Rico','Rico']},
	{Letter:'🇵🇸',Tags:['🇵🇸','flag Palestinian Territories','Flag Palestinian Territories','flag','Flag','Palestinian','Palestinian','Territories','Territories']},
	{Letter:'🇵🇹',Tags:['🇵🇹','flag Portugal','Flag Portugal','flag','Flag','Portugal','Portugal']},
	{Letter:'🇵🇼',Tags:['🇵🇼','flag Palau','Flag Palau','flag','Flag','Palau','Palau']},
	{Letter:'🇵🇾',Tags:['🇵🇾','flag Paraguay','Flag Paraguay','flag','Flag','Paraguay','Paraguay']},
	{Letter:'🇶🇦',Tags:['🇶🇦','flag Qatar','Flag Qatar','flag','Flag','Qatar','Qatar']},
	{Letter:'🇷🇪',Tags:['🇷🇪','flag Réunion','Flag Réunion','flag','Flag','Réunion','Réunion']},
	{Letter:'🇷🇴',Tags:['🇷🇴','flag Romania','Flag Romania','flag','Flag','Romania','Romania']},
	{Letter:'🇷🇸',Tags:['🇷🇸','flag Serbia','Flag Serbia','flag','Flag','Serbia','Serbia']},
	{Letter:'🇷🇺',Tags:['🇷🇺','flag Russia','Flag Russia','flag','Flag','Russia','Russia']},
	{Letter:'🇷🇼',Tags:['🇷🇼','flag Rwanda','Flag Rwanda','flag','Flag','Rwanda','Rwanda']},
	{Letter:'🇸🇦',Tags:['🇸🇦','flag Saudi Arabia','Flag Saudi Arabia','flag','Flag','Saudi','Saudi','Arabia','Arabia']},
	{Letter:'🇸🇧',Tags:['🇸🇧','flag Solomon Islands','Flag Solomon Islands','flag','Flag','Solomon','Solomon','Islands','Islands']},
	{Letter:'🇸🇨',Tags:['🇸🇨','flag Seychelles','Flag Seychelles','flag','Flag','Seychelles','Seychelles']},
	{Letter:'🇸🇩',Tags:['🇸🇩','flag Sudan','Flag Sudan','flag','Flag','Sudan','Sudan']},
	{Letter:'🇸🇪',Tags:['🇸🇪','flag Sweden','Flag Sweden','flag','Flag','Sweden','Sweden']},
	{Letter:'🇸🇬',Tags:['🇸🇬','flag Singapore','Flag Singapore','flag','Flag','Singapore','Singapore']},
	{Letter:'🇸🇭',Tags:['🇸🇭','flag St. Helena','Flag St. Helena','flag','Flag','St.','St.','Helena','Helena']},
	{Letter:'🇸🇮',Tags:['🇸🇮','flag Slovenia','Flag Slovenia','flag','Flag','Slovenia','Slovenia']},
	{Letter:'🇸🇯',Tags:['🇸🇯','flag Svalbard & Jan Mayen','Flag Svalbard & Jan Mayen','flag','Flag','Svalbard','Svalbard','&','&','Jan','Jan','Mayen','Mayen']},
	{Letter:'🇸🇰',Tags:['🇸🇰','flag Slovakia','Flag Slovakia','flag','Flag','Slovakia','Slovakia']},
	{Letter:'🇸🇱',Tags:['🇸🇱','flag Sierra Leone','Flag Sierra Leone','flag','Flag','Sierra','Sierra','Leone','Leone']},
	{Letter:'🇸🇲',Tags:['🇸🇲','flag San Marino','Flag San Marino','flag','Flag','San','San','Marino','Marino']},
	{Letter:'🇸🇳',Tags:['🇸🇳','flag Senegal','Flag Senegal','flag','Flag','Senegal','Senegal']},
	{Letter:'🇸🇴',Tags:['🇸🇴','flag Somalia','Flag Somalia','flag','Flag','Somalia','Somalia']},
	{Letter:'🇸🇷',Tags:['🇸🇷','flag Suriname','Flag Suriname','flag','Flag','Suriname','Suriname']},
	{Letter:'🇸🇸',Tags:['🇸🇸','flag South Sudan','Flag South Sudan','flag','Flag','South','South','Sudan','Sudan']},
	{Letter:'🇸🇹',Tags:['🇸🇹','flag São Tomé & Príncipe','Flag São Tomé & Príncipe','flag','Flag','São','São','Tomé','Tomé','&','&','Príncipe','Príncipe']},
	{Letter:'🇸🇻',Tags:['🇸🇻','flag El Salvador','Flag El Salvador','flag','Flag','El','El','Salvador','Salvador']},
	{Letter:'🇸🇽',Tags:['🇸🇽','flag Sint Maarten','Flag Sint Maarten','flag','Flag','Sint','Sint','Maarten','Maarten']},
	{Letter:'🇸🇾',Tags:['🇸🇾','flag Syria','Flag Syria','flag','Flag','Syria','Syria']},
	{Letter:'🇸🇿',Tags:['🇸🇿','flag Eswatini','Flag Eswatini','flag','Flag','Eswatini','Eswatini']},
	{Letter:'🇹🇦',Tags:['🇹🇦','flag Tristan da Cunha','Flag Tristan Da Cunha','flag','Flag','Tristan','Tristan','da','Da','Cunha','Cunha']},
	{Letter:'🇹🇨',Tags:['🇹🇨','flag Turks & Caicos Islands','Flag Turks & Caicos Islands','flag','Flag','Turks','Turks','&','&','Caicos','Caicos','Islands','Islands']},
	{Letter:'🇹🇩',Tags:['🇹🇩','flag Chad','Flag Chad','flag','Flag','Chad','Chad']},
	{Letter:'🇹🇫',Tags:['🇹🇫','flag French Southern Territories','Flag French Southern Territories','flag','Flag','French','French','Southern','Southern','Territories','Territories']},
	{Letter:'🇹🇬',Tags:['🇹🇬','flag Togo','Flag Togo','flag','Flag','Togo','Togo']},
	{Letter:'🇹🇭',Tags:['🇹🇭','flag Thailand','Flag Thailand','flag','Flag','Thailand','Thailand']},
	{Letter:'🇹🇯',Tags:['🇹🇯','flag Tajikistan','Flag Tajikistan','flag','Flag','Tajikistan','Tajikistan']},
	{Letter:'🇹🇰',Tags:['🇹🇰','flag Tokelau','Flag Tokelau','flag','Flag','Tokelau','Tokelau']},
	{Letter:'🇹🇱',Tags:['🇹🇱','flag Timor Leste','Flag Timor Leste','flag','Flag','Timor Leste','Timor Leste']},
	{Letter:'🇹🇲',Tags:['🇹🇲','flag Turkmenistan','Flag Turkmenistan','flag','Flag','Turkmenistan','Turkmenistan']},
	{Letter:'🇹🇳',Tags:['🇹🇳','flag Tunisia','Flag Tunisia','flag','Flag','Tunisia','Tunisia']},
	{Letter:'🇹🇴',Tags:['🇹🇴','flag Tonga','Flag Tonga','flag','Flag','Tonga','Tonga']},
	{Letter:'🇹🇷',Tags:['🇹🇷','flag Turkey','Flag Turkey','flag','Flag','Turkey','Turkey']},
	{Letter:'🇹🇹',Tags:['🇹🇹','flag Trinidad & Tobago','Flag Trinidad & Tobago','flag','Flag','Trinidad','Trinidad','&','&','Tobago','Tobago']},
	{Letter:'🇹🇻',Tags:['🇹🇻','flag Tuvalu','Flag Tuvalu','flag','Flag','Tuvalu','Tuvalu']},
	{Letter:'🇹🇼',Tags:['🇹🇼','flag Taiwan','Flag Taiwan','flag','Flag','Taiwan','Taiwan']},
	{Letter:'🇹🇿',Tags:['🇹🇿','flag Tanzania','Flag Tanzania','flag','Flag','Tanzania','Tanzania']},
	{Letter:'🇺🇦',Tags:['🇺🇦','flag Ukraine','Flag Ukraine','flag','Flag','Ukraine','Ukraine']},
	{Letter:'🇺🇬',Tags:['🇺🇬','flag Uganda','Flag Uganda','flag','Flag','Uganda','Uganda']},
	{Letter:'🇺🇲',Tags:['🇺🇲','flag U.S. Outlying Islands','Flag U.S. Outlying Islands','flag','Flag','U.S.','U.S.','Outlying','Outlying','Islands','Islands']},
	{Letter:'🇺🇳',Tags:['🇺🇳','flag United Nations','Flag United Nations','flag','Flag','United','United','Nations','Nations']},
	{Letter:'🇺🇸',Tags:['🇺🇸','flag United States','Flag United States','flag','Flag','United','United','States','States']},
	{Letter:'🇺🇾',Tags:['🇺🇾','flag Uruguay','Flag Uruguay','flag','Flag','Uruguay','Uruguay']},
	{Letter:'🇺🇿',Tags:['🇺🇿','flag Uzbekistan','Flag Uzbekistan','flag','Flag','Uzbekistan','Uzbekistan']},
	{Letter:'🇻🇦',Tags:['🇻🇦','flag Vatican City','Flag Vatican City','flag','Flag','Vatican','Vatican','City','City']},
	{Letter:'🇻🇨',Tags:['🇻🇨','flag St. Vincent & Grenadines','Flag St. Vincent & Grenadines','flag','Flag','St.','St.','Vincent','Vincent','&','&','Grenadines','Grenadines']},
	{Letter:'🇻🇪',Tags:['🇻🇪','flag Venezuela','Flag Venezuela','flag','Flag','Venezuela','Venezuela']},
	{Letter:'🇻🇬',Tags:['🇻🇬','flag British Virgin Islands','Flag British Virgin Islands','flag','Flag','British','British','Virgin','Virgin','Islands','Islands']},
	{Letter:'🇻🇮',Tags:['🇻🇮','flag U.S. Virgin Islands','Flag U.S. Virgin Islands','flag','Flag','U.S.','U.S.','Virgin','Virgin','Islands','Islands']},
	{Letter:'🇻🇳',Tags:['🇻🇳','flag Vietnam','Flag Vietnam','flag','Flag','Vietnam','Vietnam']},
	{Letter:'🇻🇺',Tags:['🇻🇺','flag Vanuatu','Flag Vanuatu','flag','Flag','Vanuatu','Vanuatu']},
	{Letter:'🇼🇫',Tags:['🇼🇫','flag Wallis & Futuna','Flag Wallis & Futuna','flag','Flag','Wallis','Wallis','&','&','Futuna','Futuna']},
	{Letter:'🇼🇸',Tags:['🇼🇸','flag Samoa','Flag Samoa','flag','Flag','Samoa','Samoa']},
	{Letter:'🇽🇰',Tags:['🇽🇰','flag Kosovo','Flag Kosovo','flag','Flag','Kosovo','Kosovo']},
	{Letter:'🇾🇪',Tags:['🇾🇪','flag Yemen','Flag Yemen','flag','Flag','Yemen','Yemen']},
	{Letter:'🇾🇹',Tags:['🇾🇹','flag Mayotte','Flag Mayotte','flag','Flag','Mayotte','Mayotte']},
	{Letter:'🇿🇦',Tags:['🇿🇦','flag South Africa','Flag South Africa','flag','Flag','South','South','Africa','Africa']},
	{Letter:'🇿🇲',Tags:['🇿🇲','flag Zambia','Flag Zambia','flag','Flag','Zambia','Zambia']},
	{Letter:'🇿🇼',Tags:['🇿🇼','flag Zimbabwe','Flag Zimbabwe','flag','Flag','Zimbabwe','Zimbabwe']},
	{Letter:'🏴󠁧󠁢󠁥󠁮󠁧󠁿',Tags:['🏴󠁧󠁢󠁥󠁮󠁧󠁿','flag England','Flag England','flag','Flag','England','England']},
	{Letter:'🏴󠁧󠁢󠁳󠁣󠁴󠁿',Tags:['🏴󠁧󠁢󠁳󠁣󠁴󠁿','flag Scotland','Flag Scotland','flag','Flag','Scotland','Scotland']},
	{Letter:'🏴󠁧󠁢󠁷󠁬󠁳󠁿',Tags:['🏴󠁧󠁢󠁷󠁬󠁳󠁿','flag Wales','Flag Wales','flag','Flag','Wales','Wales']},	
];
