import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Smileys } from "./Info";
import $ from "jquery";

class EmojisSmileys extends React.Component {
	componentWillMount = () => {
		this.setState({ Emojis: Smileys });
	};

	Message = (Msg) => {
		this.Animate(Msg);
	};

	Animate = (Msg) => {
		$(".copied .delete").stop();
		$(Msg).fadeIn(1000);
		$(Msg).fadeOut(1000);
	};

	updateArray = (Letter) => {
		this.props.Update(Letter);
	};

	constructor(props) {
		super();
		this.state = { Emojis: [] };
	}
	render() {
		return (
			<div className="row">
				<div className="col-12 mb-3">
					<div className="d-flex">
						<div>
							<h3>Smileys</h3>
						</div>
						<div>
							<div className="subline">
								Just click to copy{" "}
								<span role="img" aria-label="peace">
									✌️
								</span>
							</div>
						</div>
					</div>
				</div>
				<div id="EmojisSmileys" className="col-12 mb-5">
					{this.state.Emojis.map((Letter) => {
						return (
							<div className="wrapper" id={Letter} key={Math.random()}>
								{Letter !== "" && (
									<CopyToClipboard text={Letter}>
										<span
											id={Letter + "_Para"}
											// onClick={() => {
											// 	this.Message(".copied");
											// 	this.updateArray(Letter);
											// }}
										>
											{Letter}
										</span>
									</CopyToClipboard>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default EmojisSmileys;
