import React from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCopy } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import Navigation from "./Components/Navigation";

import EmojisPeopleAndFantasy from "./Components/EmojisPeopleAndFantasy";
import EmojisSmileys from "./Components/EmojisSmileys";
import EmojisClothingAndAccessories from "./Components/EmojisClothingAndAccessories";
import EmojisAnimalsAndNature from "./Components/EmojisAnimalsAndNature";
import EmojisActivityAndSports from "./Components/EmojisActivityAndSports";
import EmojisFoodAndDrink from "./Components/EmojisFoodAndDrink";
import EmojisTravelAndPlaces from "./Components/EmojisTravelAndPlaces";
import EmojisObjects from "./Components/EmojisObjects";
import EmojisSymbols from "./Components/EmojisSymbols";
import EmojisFlags from "./Components/EmojisFlags";
import EmojisGesturesAndBodyparts from "./Components/EmojisGesturesAndBodyparts";

// @Hello from Humza

import SidebarDesktop from "./Components/SidebarDesktop";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TagJson } from "./Components/Info";
import InputResults from "./Components/InputResults";
import BoxBookmark from "./Components/BoxBookmark";
import MissingEmoji from "./Components/MissingEmoji";

// import Dialogue from "./Components/Modal.jsx";
const searchLetter = true;

var strArray = [],
	Func;

export default class Main extends React.Component {
	componentWillMount = () => {
		this.onMobile();
	};

	componentDidMount = () => {
		var RouteInput = this.props.Params;

		if (RouteInput !== undefined) {
			//alert(RouteInput)
			this.setState({ search: RouteInput, showSearch: false, initShow: true });

			document.getElementById("deskSearch").focus();

			var resultArray = this.getSearchResults(RouteInput);
			this.setState({ searchResults: resultArray, showSearch: true });

			this.setState({ initShow: true, showTag: true });
		}

		try {
			strArray = this.Cookies();
			//alert(strArray)
			strArray = strArray.split("|");
			strArray = [...new Set(strArray)];

			if (strArray[0] === "" && strArray.length === 1) {
				return;
			} else {
				this.setState({ cookieEmoji: strArray });
				// alert("set")
				this.setToStorage("SavedToSubPage", strArray);
			}
		} catch (ex) {
			//alert(ex)
		}

		this.Animation();
	};

	getSearchResults = (Query) => {
		// alert("gsr: " + Query)
		var resArray = [];
		TagJson.forEach((Emoji) => {
			if (Emoji.Tags.includes(Query)) {
				resArray.push(Emoji.Letter);
			}
		});
		return resArray;
	};

	updateArray = (emoji) => {
		console.log(`in Main above if: ${emoji}`);

		Func = "Add";

		if (strArray[0] === "" && strArray.length === 1) {
			console.log(`in Main if: ${emoji}`);

			strArray[0] = emoji;

			this.state.cookieEmoji.push(emoji);
			this.setState({ cookieEmoji: [...new Set(strArray)] });
			console.log(`in Main if: ${this.state.cookieEmoji}`);

			let wordString = this.state.cookieEmoji.join("|"); // Returns: "1|2|3|4"
			this.setCookie("Stored", wordString, 7);
			this.setToStorage("SavedToSubPage", wordString);

			return;
		} else {
			this.state.cookieEmoji.push(emoji);
			this.setState({ cookieEmoji: [...new Set(this.state.cookieEmoji)] });
			console.log(`in Main else: ${this.state.cookieEmoji}`);

			let wordString = this.state.cookieEmoji.join("|"); // Returns: "1|2|3|4"
			this.setCookie("Stored", wordString, 7);
			//alert("Set")
			this.setToStorage("SavedToSubPage", wordString);
		}
	};

	Navigate = (Value, searchRes) => {
		this.setState({ searchResults: searchRes, showSearch: true });
		this.setState({ initShow: true, showTag: true });

		this.props.Navigate(Value);
	};

	onMobile = () => {
		var App = this;

		if (document.body.clientWidth <= 500) this.setState({ deskView: false });

		window.addEventListener("resize", (event) => {
			if (document.body.clientWidth <= 500) App.setState({ deskView: false });
		});
	};

	switchDeskView = () => {
		this.setState({ deskView: false });
	};

	constructor(props) {
		super(props);
		this.state = {
			Letters: "",
			lastUsed: [],
			search: "",
			Chosen: "",
			showTag: false,
			showSearch: true,
			initShow: false,
			finalLefts: [],
			favEmojis: [],
			cookieEmoji: [],
			searchResults: [],
			Input: "",
			mobileView: false,
			deskView: true,
			Scroll: 0,
		};
	}

	Cookies = () => {
		try {
			if (window.location.href.includes("/search/")) {
				//alert("get")
				var spv = this.getFromStorage("SavedToSubPage");
				//alert(spv)
				var arr = spv.split(",").join("|");
				return arr;
			}

			var Cookie = document.cookie;
			if (Cookie.includes(";")) {
				console.log(`Multiple Cookies :: ${Cookie}`);

				const Emojis = this.selectCookie(Cookie);
				return Emojis;
			} else {
				if (Cookie.length === 0) {
					return Cookie;
				} else {
					console.log(`Single Cookie :: ${Cookie}`);
					var ck = Cookie.split("=");
					return ck[1];
				}
			}
		} catch (ex) {
			return [];
		}
	};

	selectCookie = (Cookies) => {
		console.log(`Selecting Cookies`);
		var FinalCookie;
		const cookiesArray = Cookies.split(";");

		if (window.location.href.includes("search/")) cookiesArray.reverse();

		// nonreverse has same emoji as main but no reorder

		//reverse will reorder

		cookiesArray.forEach((item) => {
			var cookie = item.split("=");
			console.log(`Cookie Item :: ${cookie}`);
			if (cookie[0].includes("Stored")) {
				console.log(`returns final Cookie :: ${cookie[1]}`);
				FinalCookie = cookie[1];
			}
		});

		if (FinalCookie === undefined) return "";
		if (FinalCookie !== undefined) return FinalCookie;
	};

	searchUpdate = (value) => {
		this.setState({ search: value, showSearch: true, initShow: true });
	};

	setCookie(name, value, days) {
		var expires = "",
			date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = " expires=" + date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + "; path=/";
	}

	removeEmoji = (emojiArray, func) => {
		this.setState({ cookieEmoji: emojiArray });
		Func = func;
	};

	Message = (Msg) => {
		$(".copied .delete").stop();
		$(Msg).fadeIn(1000);
		$(Msg).fadeOut(1000);
	};

	Search = (Value) => {
		if (Value === "") this.setState({ showTag: false });
		this.setState({ search: Value });
		var resp = [];
		TagJson.forEach((item) => {
			if (item.Tags.includes(Value)) {
				resp.push(item.Letter);
			}
		});
		//alert(resp)
		if (resp.length > 0) this.searchProceed(resp, Value);
	};

	searchProceed = (searchRes, routeParam) => {
		//alert(searchRes)
		this.Navigate(routeParam, searchRes);
	};

	setToStorage = (name, value) => {
		localStorage.setItem(name, value);
	};
	getFromStorage = (name) => {
		let v = localStorage.getItem(name);
		return v;
	};
	onChange = (val) => {
		this.setState({ Input: val });
	};
	UpdateOrder = (EmojiOrder) => {
		//this.setState({cookieEmoji:EmojiOrder})
	};

	Animation = () => {
		const App = this;
		$(window).scroll(() => {
			var scrollPos = $(window).scrollTop();
			if (scrollPos === 709) App.setState({ Scroll: scrollPos });
		});
	};

	render() {
		return (
			<div>
				<Navigation
					onChange={this.searchUpdate}
					Input={this.state.search}
					Navigate={this.Navigate}
					Params={this.props.Params}
					valueChange={this.onChange}
					FavEmojis={this.state.cookieEmoji}
					UpdateOrder={this.UpdateOrder}
					Move={this.state.Chosen}
					updateRemove={this.removeEmoji}
					updateFunc={Func}
					hideDeskView={this.switchDeskView}
				/>

				{/* space because of header sticky-top*/}
				<div className="container mt-5">&nbsp;</div>

				<div className="container mb-5">
					<div className="row">
						<div className="col-12 col-lg-8 pt-5">
							{this.state.showSearch && (
								<div>
									{searchLetter ? (
										<div className="row">
											{this.state.searchResults.length > 0 && (
												<div>
													<InputResults />

													<div id="EmojiSearchResults" className="col-12">
														{this.state.searchResults.map((Item) => {
															return (
																<CopyToClipboard
																	text={Item}
																	key={Math.random()}
																>
																	<div
																		className="wrapper"
																		id={Item}
																		onClick={() => {
																			this.Message(".copied");
																			//this.updateArray(Item)
																		}}
																	>
																		<span id={Item + "_Para"}> {Item} </span>
																	</div>
																</CopyToClipboard>
															);
														})}
													</div>
													<MissingEmoji />
												</div>
											)}
										</div>
									) : undefined}
								</div>
							)}

							<EmojisSmileys
								FavEmojis={this.state.favEmojis}
								Update={this.updateArray}
							/>

							<BoxBookmark />

							<div>
								<EmojisGesturesAndBodyparts Update={this.updateArray} />
								<EmojisPeopleAndFantasy Update={this.updateArray} />
								<EmojisClothingAndAccessories Update={this.updateArray} />
								<EmojisAnimalsAndNature Update={this.updateArray} />
								<EmojisFoodAndDrink Update={this.updateArray} />
								<EmojisActivityAndSports Update={this.updateArray} />
								<EmojisTravelAndPlaces Update={this.updateArray} />
								<EmojisObjects Update={this.updateArray} />
								<EmojisSymbols Update={this.updateArray} />
								<EmojisFlags Update={this.updateArray} />
							</div>
						</div>

						{this.state.deskView && (
							<SidebarDesktop
								FavEmojis={this.state.cookieEmoji}
								UpdateOrder={this.UpdateOrder}
								Move={this.state.Chosen}
								updateRemove={this.removeEmoji}
								updateFunc={Func}
							/>
						)}
					</div>
				</div>

				<div className="producthunt">
					<a
						href="https://www.producthunt.com/posts/favemoji-copy-paste-emojis?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-favemoji-copy-paste-emojis"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=284013&theme=light"
							alt="👋 FavEmoji – Copy &amp; Paste Emojis - Save Emojis as favorites. Perfect to create ads/text/design. | Product Hunt"
							style={{ width: "250px", height: "54px" }}
							width="250"
							height="54"
						/>
					</a>
				</div>

				<div
					className="copied emojiStatus emojiStatusCopied"
					style={{ display: "none" }}
				>
					<div className="d-flex">
						<div className="emojiStatusSvg">
							<FontAwesomeIcon icon={faCopy} size="1x" />
						</div>
						<div>Emoji copied</div>
					</div>
				</div>

				<div
					className="delete emojiStatus emojiStatusRemoved"
					id="DEL"
					style={{ display: "none" }}
				>
					<div className="d-flex">
						<div className="emojiStatusSvg">
							<FontAwesomeIcon icon={faTimes} size="1x" />
						</div>
						<div>Emoji removed</div>
					</div>
				</div>
			</div>
		);
	}
}
