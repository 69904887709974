import React from "react";
import Navbar from "react-bootstrap/Navbar";
import "./Navigation.css";

const logoFavemoji = require("../img/logoFavemoji.svg");

function NavigationContent() {
	return (
		<div>
			<Navbar className="customNavbar fixed-top" expand="lg">
				<div className="container">
					<Navbar.Brand href="/">
						<span className="waveNoAnimaion" role="img" aria-label="wave">
							👋
						</span>
						<img src={logoFavemoji} alt="FavEmoji" className="ml-2" />
					</Navbar.Brand>
				</div>
			</Navbar>

			{/* space because of header sticky-top*/}
			<div className="container mt-5">&nbsp;</div>
		</div>
	);
}

export default NavigationContent;
