import React from "react";

class MissingEmoji extends React.Component {
	componentDidMount = () => {
		var App = this;
		document
			.getElementById("deskSearch")
			.addEventListener("input", function () {
				var x = document.getElementById("deskSearch").value;
				App.setState({ write: x });
			});
	};

	constructor() {
		super();
		this.state = {
			write: "",
		};
	}

	render() {
		return (
			<div>
				{this.state.write.length >= 0 && (
					<div className="col-12 mb-5 mt-2">
						<a
							href={
								"https://docs.google.com/forms/d/e/1FAIpQLSfcgORulBcJHQ8miszYKdJjWQGVfRgvnfev8Dz8I4Qt_JtSqw/viewform?usp=pp_url&entry.1106582086=" +
								this.state.write
							}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-primary"
						>
							<span role="img" aria-label="pointing" className="mr-2">
								👉
							</span>
							Missing an Emoji?
						</a>
					</div>
				)}
			</div>
		);
	}
}

export default MissingEmoji;
