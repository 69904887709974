import React from "react";
import ButtonCopyUrlShare from "./ButtonCopyUrlShare";

class BoxBookmark extends React.Component {
	render() {
		return (
			<div className="row">
			<div className="col-12 mb-5">
				<div className="bgBlue mb-3">
					<div className="h5">
						<div className="d-flex">
							<div className="mr-2">
								<span role="img" aria-label="show">
									👉
								</span>
							</div>
							<div>
								Don't forget to bookmark{" "}
								<span className="bold-700">FavEmoji.com</span>
							</div>
						</div>
					</div>

					<div className="mb-2">
						And share it with your friends{" "}
						<span role="img" aria-label="friends">
							👫
						</span>
					</div>

					<div>
						<ButtonCopyUrlShare />
					</div>
					<div className="thankYou small">Thank you.</div>
				</div>
			</div>
		</div>
		);
	}
}

export default BoxBookmark;
