import React from "react";

class InputResults extends React.Component {
	componentDidMount = () => {
		var App = this;
		document
			.getElementById("deskSearch")
			.addEventListener("input", function () {
				var x = document.getElementById("deskSearch").value;
				App.setState({ write: x });
			});
	};

	constructor() {
		super();
		this.state = {
			write: "",
		};
	}

	render() {
		return (
			<div>
				{this.state.write.length > 0 && (
					<div className="col-12 searchResults mb-3">
						<h3>
							Search: <span>{this.state.write}</span>
						</h3>
					</div>
				)}

				{this.state.write.length === 0 && (
					<div className="col-12 searchResults mb-3">
						<h3>
							Search:
						</h3>
					</div>
				)}
			</div>
		);
	}
}

export default InputResults;
