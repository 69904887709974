import * as React from "react";
import "../App.css";
import MetaTags from "react-meta-tags";
import Main from "../Main";

interface IPageTestProps {
	metaTitle: string;
	metaDescription: string;
	metaRobots: string;
	metaKeywords: string;
	pageHeadline: string;
	pageTags: string;
	pageDesciption: string;
}

class PageContentEmoji extends React.Component<IPageTestProps> {
	render() {
		return (
			<div>
				<MetaTags>
					{/* informations should be loaded from <Routes> */}
					<title>{this.props.metaTitle}</title>
					<meta name="description" content={this.props.metaDescription} />
					<meta name="robots" content={this.props.metaRobots} />
					<meta name="keywords" content={this.props.metaKeywords} />
				</MetaTags>

				<div className="container mt-5">&nbsp;</div>

				<div className="container mb-5">
					<div className="row">
						<div className="col-12 pt-5">
							{/* informations should be loaded from <Routes> */}
							<h1>headline: {this.props.pageHeadline}</h1>
							<span>tags: {this.props.pageTags}</span>
							<br />
							Here I want to display all Emojis with these "Tags".
							<br />
							If pageTags="apple" show: 🍏, 🍎
							<br />
							<br />
							Multiple Tags are possible like:
							<br />
							If pageTags="apple, tiger" show: 🍏, 🍎, 🐯, 🐅
							<br />
							Use the same Tags from Info.js.
							{this.props.pageDesciption}
							<br />
						</div>
					</div>
				</div>

				<Main />
			</div>
		);
	}
}

export default PageContentEmoji;
