import Codebase from "./Components/Codebase";
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import PageImprint from "./Pages/PageImprint";
import PagePrivacy from "./Pages/PagePrivacy";
import PageContentEmoji from "./Pages/PageContentEmoji";

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Route exact path="/" component={Codebase} />
				<Route
					exact
					path="/Search/:id"
					render={(props) => <Codebase {...props} />}
				/>
				<Route
					exact
					path="/Search"
					render={(props) => <Codebase {...props} />}
				/>
				<Route exact path="/imprint" render={PageImprint} />
				<Route exact path="/privacy" render={PagePrivacy} />

				<Route
					exact
					render={(props) => <PageContentEmoji {...props} />}
					path="/apple-emoji"
					metaTitle="🍎 Apple Emoji ► Copy and Paste ► List of all Emojis 👋 FavEmoji"
					metaDescription="All Emojis available ► Also 🍎 Apple Emoji ► Save your favorite Emojis in your own collection 👋 FavEmoji"
					metaKeywords="Apple Emoji, Emoji, Emoticon"
					metaRobots="noindex, nofollow"
					pageHeadline="🍎 Apple Emoji"
					pageTags="apple" // = show all Emojis that tagged with "apple, tiger"
					pageDesciption="Some Text of Apple. Apple are awesome and tasty."
				/>
				<Route
					exact
					render={(props) => <PageContentEmoji {...props} />}
					path="/tiger-emoji"
					metaTitle="🐯 Tiger Emoji ► Copy and Paste ► List of all Emojis 👋 FavEmoji"
					metaDescription="All Emojis available ► Also 🐯 Tiger Emoji ► Save your favorite Emojis in your own collection 👋 FavEmoji"
					metaKeywords="Tiger Emoji, Emoji, Emoticon"
					metaRobots="noindex, nofollow"
					pageHeadline="🐯 Tiger Emoji"
					pageTags="tiger" // = show all Emojis that tagged with "apple, tiger"
					pageDesciption="Some Text of Tigers. Tigers are awesome."
				/>
			</div>
		</BrowserRouter>
	);
}

export default App;
