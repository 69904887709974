import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./ButtonCopyUrlShare.css";

function ButtonCopyUrlShare() {
	return (
		<CopyToClipboard text="https://FavEmoji.com">
			<Button variant="primary" className="buttonShare customNavbarButton">
				<div className="d-flex">
					<div className="mr-2">
						<FontAwesomeIcon icon={faShareAlt} size="1x" />
					</div>
					<div>Copy URL to share</div>
				</div>
				<div className="urlCopied">
					<FontAwesomeIcon icon={faCheck} size="1x" /> copied
				</div>
			</Button>
		</CopyToClipboard>
	);
}

export default ButtonCopyUrlShare;
