import React from "react";
import Main from "../Main";

class Codebase extends React.Component {

	Navigate = (Value) => {
		this.props.history.push('/search/' + Value)
	}
	
	render() {
		return (
			<Main
				Params={this.props.match.params.id}
				Message={this.Message}
				updateArray={this.updateArray}
				Navigate = {this.Navigate}
			/>
		);
	}

	
}

export default Codebase;
