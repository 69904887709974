import React from "react";
import "./Footer.css";

function Footer() {
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-12 mb-5">
						<a href="/imprint">Imprint</a> · <a href="/privacy">Privacy</a>{" "}
						· Made with ❤️
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
