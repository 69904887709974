import React from "react";
import $ from "jquery";

require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/sortable");

const iconClose = require("../img/iconClose.svg");
var onDel = false;

class SidebarDesktop extends React.Component {
	componentDidMount = () => {
		//recentSort = this.state.Emojis

		if (window.location.href.includes("/search")) {
			this.setState({ favSpaceTop: "14rem" });
		} else {
			this.setState({ favSpaceTop: "3rem" });
		}

		setTimeout(() => {
			this.setState({ Emojis: this.props.FavEmojis });
		}, 1000);

		$(document).ready(function () {
			$("#desksortable").sortable();
			$(".deskdraggable").draggable({
				connectToSortable: "#desksortable",
			});
		});

		eventOrigin.forEach((Origin) => {
			try {
				this.onInsert(Origin);
			} catch (ex) {
				console.log(ex);
			}
		});

		//alert( "sidearEmojis: " + this.state.Emojis)
	};

	componentWillReceiveProps(nextProps) {
		// if (!Update) {
		// 	var newEmoji = nextProps.FavEmojis[nextProps.FavEmojis.length - 1];
		// 	alert(newEmoji)
		// 	this.state.Emojis.push(newEmoji);
		// 	this.setState({ Emojis: [...new Set(this.state.Emojis)] });
		// }
		// if (Update) {
		// 	var neoEmoji = nextProps.FavEmojis[nextProps.FavEmojis.length - 1];
		// 	alert(neoEmoji)
		// 	this.state.Emojis.push(neoEmoji);
		// 	this.setState({ Emojis: [...new Set(this.state.Emojis)] });
		// 	// alert(this.state.Emojis)
		// }
	}

	onInsert = (Container) => {
		var App = this;

		$(document).ready(function () {
			$("#" + Container)
				.children()
				.each(function () {
					let Emoji = $(this).attr("id");
					let Id = `${Emoji}_Para`;

					$("#" + Id).click(function () {
						//alert("The paragraph was clicked.");
						App.Message(".copied");
						App.state.Emojis.push(Emoji);
						App.setState({ Emojis: [...new Set(App.state.Emojis)] });
						let wordString = App.state.Emojis.join("|");
						App.setCookie("Stored", wordString, 7);
						App.setToStorage("SavedToSubPage", wordString);
					});
				});
		});
	};

	Message = (Msg) => {
		this.Animate(Msg);
	};

	Animate = (Msg) => {
		//alert(Msg)
		$(".copied").stop();
		$(".delete").stop();

		$(Msg).fadeIn(1000);
		$(Msg).fadeOut(1000);
	};

	Delete = (Letter) => {
		this.state.Emojis.forEach((elm, index) => {
			if (elm === Letter) {
				this.state.Emojis.splice(index, 1);
				this.setState({ Emojis: this.state.Emojis });

				let wordString = this.state.Emojis.join("|"); // Returns: "1|2|3|4"
				//document.cookie = "Stored=" + wordString;
				//alert("Set")
				this.setCookie("Stored", wordString);
				this.setToStorage("SavedToSubPage", wordString);
			}
		});
	};

	setToStorage = (name, value) => {
		localStorage.setItem(name, value);
	};

	EmojiSort = () => {
		var FinalPoints = [],
			reArrangedEmojis = [],
			EmojiUni = [];

		$("#desksortable")
			.children()
			.each(function () {
				var elemId = $(this).attr("id");
				if (elemId !== undefined) {
					let currentItem = document.getElementById(elemId);
					let rect = currentItem.getBoundingClientRect();
					let currentPara = document.getElementById(elemId + "_Para");
					let Emoji = $(currentPara).text();
					let Obj = { Coords: rect, Emoji: Emoji };
					EmojiUni.push(Obj);
					FinalPoints.push(rect);
				}
			});

		FinalPoints.sort((a, b) => a - b); //var tempSort = [];

		FinalPoints.forEach((sortedPoint) => {
			var returnEmoji = this.EmojiFromCoords(sortedPoint, EmojiUni);
			//alert(returnEmoji)

			reArrangedEmojis.push(returnEmoji);
			this.setState({ Emojis: reArrangedEmojis });
		});

		// alert(reArrangedEmojis)

		let wordString = reArrangedEmojis.join("|"); // Returns: "1|2|3|4"
		this.setCookie("Stored", wordString);
		this.setToStorage("SavedToSubPage", wordString);

		//   alert(JSON.stringify(EmojisCoords))
	};

	EmojiFromCoords = (arrangedPoint, Emj) => {
		var Final;
		Emj.forEach((obj) => {
			if (
				arrangedPoint.left === obj.Coords.left &&
				arrangedPoint.top === obj.Coords.top
			) {
				Final = obj.Emoji;
			}
		});
		return Final;
	};

	setCookie(name, value, days) {
		var expires = "",
			date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = " expires=" + date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + "; path=/";
	}

	Copy = (text) => {
		var dummy = document.createElement("textarea");

		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	};
	constructor(props) {
		super(props);
		this.state = {
			Emojis: [],
			showEmoji: true,
			favSpaceTop: "6rem",
		};
		this.emojiRef = null;
	}

	moveEmojis = () => {
		this.setState({ favSpaceTop: "14rem" });
	};

	render() {
		return (
			<div className="col-12 col-lg-4 mt-5 d-none d-lg-block">
				<div className="sticky-top">
					<div className="favWrapper">
						<div className="row">
							<div className="col-12 mb-3 favmoji">
								<div className="d-flex">
									<div>
										<h3>FavEmojis</h3>
									</div>
									<div>
										<div className="subline">
											are useful{" "}
											<span role="img" aria-label="rocknroll">
												🤘
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div id="desksortable">
									{this.state.Emojis.map((Letter) => {
										return (
											<div
												className="wrapper deskdraggable unique"
												id={Letter}
												ref={(elem) => (this.emojiRef = elem)}
												key={Math.random()}
												onMouseUp={() => {
													if (!onDel) this.EmojiSort();
												}}
												onMouseDown={() => {
													this.Message(".copied");
													this.Copy(Letter);
												}}
											>
												<div>
													<span id={Letter + "_Para"}>{Letter}</span>
												</div>

												<div className="deleted">
													<img
														className="cross"
														src={iconClose}
														value={Letter}
														alt=""
														onClick={() => {
															this.Message(".delete");
															this.Delete(Letter);
														}}
														onMouseOver={() => {
															onDel = true;
															//alert(onDel)
														}}
														onMouseLeave={() => {
															onDel = false;
														}}
													/>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SidebarDesktop;

var eventOrigin = [
	"EmojiSearchResults",
	"EmojisActivity",
	"EmojisAnimal",
	"EmojisSmileys",
	"EmojisAccess",
	"EmojisFlags",
	"EmojisFood",
	"EmojisGesture",
	"EmojisObjects",
	"EmojisFantasy",
	"EmojisSymbols",
	"EmojisTravel",
];
