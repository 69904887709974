import React from "react";
import $ from "jquery";

require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/sortable");

const iconClose = require("../img/iconClose.svg");
var Update = false,
	onDel = false,
	strArray = [];

class SidebarMobile extends React.Component {
	componentWillReceiveProps(nextProps) {
		if (!Update) {
			//alert("sidebar" + nextProps.FavEmojis)
			this.setState({ Emojis: nextProps.FavEmojis });
		}

		if (Update) {
			var newEmoji = nextProps.FavEmojis[nextProps.FavEmojis.length - 1];
			this.state.Emojis.push(newEmoji);
			this.setState({ Emojis: [...new Set(this.state.Emojis)] });
		}
	}

	componentDidMount = () => {
		// alert("Mounts");
		//recentSort = this.state.Emojis

		// if (window.location.href.includes("/search")) {
		// 	this.setState({ favSpaceTop: "14rem" });
		// } else {
		// 	this.setState({ favSpaceTop: "3rem" });
		// }

		// setTimeout (function () {
		// 	alert(App.state.Emojis)
		// },5000)

		this.DraggableSortable();

		try {
			strArray = this.Cookies();
			// alert(strArray);
			strArray = strArray.split("|");
			strArray = [...new Set(strArray)];

			if (strArray[0] === "" && strArray.length === 1) {
				return;
			} else {
				//alert(strArray)
				this.setState({ Emojis: strArray });
				// alert("set")
				this.setToStorage("SavedToSubPage", strArray);
			}
		} catch (ex) {
			//alert(ex)
		}

		//alert(strArray)

		//alert( "sidebarEmojis: " + this.state.Emojis)
	};

	Cookies = () => {
		try {
			if (window.location.href.includes("/search/")) {
				//alert("get")
				var spv = this.getFromStorage("SavedToSubPage");
				//alert(spv)
				var arr = spv.split(",").join("|");
				return arr;
			}

			var Cookie = document.cookie;
			if (Cookie.includes(";")) {
				console.log(`Multiple Cookies :: ${Cookie}`);

				const Emojis = this.selectCookie(Cookie);
				return Emojis;
			} else {
				if (Cookie.length === 0) {
					return Cookie;
				} else {
					console.log(`Single Cookie :: ${Cookie}`);
					var ck = Cookie.split("=");
					return ck[1];
				}
			}
		} catch (ex) {
			return [];
		}
	};
	
	getFromStorage = (name) => {
		let v = localStorage.getItem(name);
		return v;
	};

	selectCookie = (Cookies) => {
		console.log(`Selecting Cookies`);
		var FinalCookie;
		const cookiesArray = Cookies.split(";");

		if (window.location.href.includes("search/")) cookiesArray.reverse();

		// nonreverse has same emoji as main but no reorder

		//reverse will reorder

		cookiesArray.forEach((item) => {
			var cookie = item.split("=");
			console.log(`Cookie Item :: ${cookie}`);
			if (cookie[0].includes("Stored")) {
				console.log(`returns final Cookie :: ${cookie[1]}`);
				FinalCookie = cookie[1];
			}
		});

		if (FinalCookie === undefined) return "";
		if (FinalCookie !== undefined) return FinalCookie;
	};

	Message = (Msg) => {
		this.Animate(Msg);
	};

	Animate = (Msg) => {
		//alert(Msg)
		$(".copied").stop();
		$(".delete").stop();

		$(Msg).fadeIn(1000);
		$(Msg).fadeOut(1000);
	};

	Delete = (Letter) => {
		this.state.Emojis.forEach((elm, index) => {
			if (elm === Letter) {
				this.state.Emojis.splice(index, 1);
				this.setState({ Emojis: this.state.Emojis });

				let wordString = this.state.Emojis.join("|"); // Returns: "1|2|3|4"
				//document.cookie = "Stored=" + wordString;
				//alert("Set")
				this.setCookie("Stored", wordString);
				this.setToStorage("SavedToSubPage", wordString);
			}
		});
	};

	setToStorage = (name, value) => {
		localStorage.setItem(name, value);
	};

	EmojiSort = () => {
		var FinalPoints = [],
			reArrangedEmojis = [],
			EmojiUni = [];

		$("#sortable")
			.children()
			.each(function () {
				var elemId = $(this).attr("id");
				if (elemId !== undefined) {
					let currentItem = document.getElementById(elemId);
					let rect = currentItem.getBoundingClientRect();
					let currentPara = document.getElementById(elemId + "_Para");
					let Emoji = $(currentPara).text();
					let Obj = { Coords: rect, Emoji: Emoji };
					EmojiUni.push(Obj);
					FinalPoints.push(rect);
				}
			});

		FinalPoints.sort((a, b) => a - b);
		//var tempSort = [];

		FinalPoints.forEach((sortedPoint) => {
			var returnEmoji = this.EmojiFromCoords(sortedPoint, EmojiUni);

			reArrangedEmojis.push(returnEmoji);
			this.setState({ Emojis: reArrangedEmojis });
			Update = true;
		});

		let wordString = reArrangedEmojis.join("|"); // Returns: "1|2|3|4"
		this.setCookie("Stored", wordString);
		this.setToStorage("SavedToSubPage", wordString);

		//   alert(JSON.stringify(EmojisCoords))
	};

	EmojiFromCoords = (arrangedPoint, Emj) => {
		var Final;
		Emj.forEach((obj) => {
			if (
				arrangedPoint.left === obj.Coords.left &&
				arrangedPoint.top === obj.Coords.top
			) {
				Final = obj.Emoji;
			}
		});

		return Final;
	};

	setCookie(name, value, days) {
		var expires = "",
			date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = " expires=" + date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + "; path=/";
	}

	Copy = (text) => {
		var dummy = document.createElement("textarea");

		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	};

	moveEmojis = () => {
		this.setState({ favSpaceTop: "14rem" });
	};

	DraggableSortable = () => {
		$(document).ready(function () {
			$("#sortable").sortable();
			$(".draggable").draggable({
				connectToSortable: "#sortable",
			});
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			Emojis: [],
			showEmoji: true,
			favSpaceTop: "6rem",
		};
		this.emojiRef = null;
	}

	render() {
		return (
			<div id="sortable">
				{this.state.Emojis.map((Letter) => {
					return (
						<div
							className="wrapper draggable unique"
							id={Letter}
							ref={(elem) => (this.emojiRef = elem)}
							key={Math.random()}
							onMouseUp={() => {
								//alert("sort")
								if (!onDel) this.EmojiSort();
							}}
							onMouseDown={() => {
								this.Message(".copied");
								this.Copy(Letter);
							}}
						>
							<div>
								<div id={Letter + "_Para"}>{Letter}</div>
							</div>

							<div className="deleted">
								<img
									className="cross"
									src={iconClose}
									value={Letter}
									alt=""
									onClick={() => {
										this.Message(".delete");
										this.Delete(Letter);
									}}
									onMouseOver={() => {
										onDel = true;
										//alert(onDel)
									}}
									onMouseLeave={() => {
										onDel = false;
									}}
								/>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

export default SidebarMobile;
